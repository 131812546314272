import { Button, Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../../components/InputComponents/TextInput";
import { setActiveStep, setLeadData } from "../../../modules/landing.slice";
import { useGetFrontendBoot } from "../../../services/landingService";
import * as yup from "yup";
import { allowFullName, allowOnlyNumbers } from "../../../utils/input";
import OTPVerificationModal from "../components/OtpVerificationModal";
import { useState } from "react";
import { useSendOtpToVerify } from "../../../services/landingService";

const PersonalDetails = () => {
  const dispatch = useDispatch();
  const { sendOtp } = useSendOtpToVerify();
  const {
    frontendBoot: {
      travelsettings: { otp_journey: isOtpJourney },
    },
  } = useGetFrontendBoot();
  const { leadData } = useSelector((state) => state.landing);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const initialValues = {
    insured_name: leadData?.insured_name,
    insured_email: leadData?.insured_email,
    insured_mobile: leadData?.insured_mobile,
  };
  const validationSchema = yup.object().shape({
    insured_name: yup
      .string()
      .required("Name is required")
      .matches(/^\s*[\S]+(\s[\S]+)+\s*$/gms, "Please enter your full name."),
    insured_email: yup
      .string()
      .email("Please enter a valid email")
      .required("Email is required"),
    insured_mobile: yup
      .string()
      .min(10, "Mobile number must be 10 Digits")
      .max(10, "Mobile number must be 10 Digits")
      .matches(/^[6-9]\d{9}$/, "Please enter a valid mobile number")
      .required("Mobile number is required"),
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data) => {
          await dispatch(setLeadData(data));
          isOtpJourney === "Yes" ? sendOtp() : dispatch(setActiveStep(1));
        }}
      >
        {({ values, handleSubmit, errors }) => {
          const isErrorExist =
            !errors.insured_email &&
            !errors.insured_mobile &&
            !errors.insured_name;
          return (
            <>
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} md={12} mt={2}>
                  <Typography
                    variant="h3"
                    fontSize={{ xs: "12px", md: "18px" }}
                    component="p"
                  >
                    Tell us about yourself
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextInput
                    label="Name"
                    name="insured_name"
                    onInput={allowFullName}
                    inputProps={{ maxLength: "30" }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextInput
                    label="Email"
                    name="insured_email"
                    onInput={(e) =>
                      (e.target.value = e.target.value.replace(" ", ""))
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextInput
                    label="Mobile Number"
                    name="insured_mobile"
                    onInput={allowOnlyNumbers}
                    inputProps={{
                      inputMode: "numeric",
                      maxLength: 10,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => {
                      isErrorExist &&
                        isOtpJourney === "Yes" &&
                        setIsOtpModalOpen(true);
                      handleSubmit();
                    }}
                    sx={{
                      borderRadius: "6px",
                      boxShadow: "none",
                      backgroundColor: "var(--primary-color)",
                    }}
                  >
                    Proceed
                  </Button>
                </Grid>
              </Grid>
              <OTPVerificationModal
                mobile={values?.insured_mobile}
                email={values?.insured_email}
                isOtpModalOpen={isOtpModalOpen}
                setIsOtpModalOpen={setIsOtpModalOpen}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default PersonalDetails;
