import { Paper, Box, useMediaQuery } from "@mui/material";
import React from "react";
import StepperComp from "../../components/Stepper/StepperComp";
import { activeStepSelector } from "../../modules/landing.slice";
import { useSelector } from "react-redux";
import MainWrapper from "./components/MainWrapper";
import JourneyDetails from "./forms/JourneyDetails";
import TravelPurpose from "./forms/TravelPurpose";
import PersonalDetails from "./forms/PersonalDetails";
import { useTheme } from "@emotion/react";

const InputPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const activeStep = useSelector(activeStepSelector);

  return (
    <MainWrapper>
      <Paper
        sx={{
          p: 2,
          mt: 3,
          border: isMobile ? "1px solid #E4E4E4" : "2px solid #E4E4E4",
          boxShadow: "none",
          borderRadius: "10px",
        }}
      >
        <Box sx={{ width: { sm: "100%", md: "70%" }, mx: "auto" }}>
          <StepperComp />
        </Box>
        {activeStep === 0 && <PersonalDetails />}
        {activeStep === 1 && <TravelPurpose />}
        {activeStep === 2 && <JourneyDetails />}
      </Paper>
    </MainWrapper>
  );
};

export default InputPage;
