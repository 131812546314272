import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useField, useFormikContext } from "formik";

const TravelEditRadio = ({ name, options = [], ...props }) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const config = {
    ...field,
    ...props,
    fullWidth: true,
    onChange: (e, value) => {
      setFieldValue(name, value);
    },
  };

  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={12} md={5.99}>
        <Typography
          sx={{ fontSize: isTablet ? "16px" : "12px", color: "#0a0938" }}
          variant="body1"
        >
          {props.label}
        </Typography>
      </Grid>
      <Grid sx={{ p: 2 }} item xs={12} md={5.99}>
        <RadioGroup
          {...config}
          sx={{
            flexDirection: "row",
            gap: "20px",
            color: "var(--primary-color)",
          }}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option.code}
              control={
                <Radio
                  sx={{
                    color: "var(--primary-color)",
                    "&.Mui-checked": {
                      color: "var(--primary-color)",
                    },
                  }}
                />
              }
              label={option.display_name}
              sx={{
                mt: "-8px",
                ml: "-8px",
                "& .MuiFormControlLabel-label": {
                  fontSize: "14px",
                  mt: "2px",
                  color: "var(--primary-color)",
                },
              }}
            />
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  );
};
export default TravelEditRadio;
