import { createSlice } from "@reduxjs/toolkit";

const proposalSlice = createSlice({
  name: "proposal",
  initialState: {
    proposalData: {},
    active: 0,
    primary_proposer_id: "",
    members: [],
    p_agree: "N",
    cKycStatus: false,
    cKycUploadStatus: {
      identity_proof: false,
      address_proof: false,
    },
    cKycUploadLoader: {
      identity_proof: false,
      address_proof: false,
      pan_card: false,
    },
    cKycFailCount: 0,
    cKycResponse: {},
    meta_data: {},
  },
  reducers: {
    setProposalData: (state, action) => {
      state.proposalData = {
        ...state.proposalData,
        ...action.payload,
      };
    },
    setActive: (state, action) => {
      state.active = action.payload;
    },
    setPrimaryProposerId: (state, action) => {
      state.primary_proposer_id = action.payload;
    },

    setProposalMembers: (state, action) => {
      state.members = action.payload;
    },
    setP_agree: (state, action) => {
      state.p_agree = action.payload;
    },
    setCKycStatus: (state, action) => {
      state.cKycStatus = action.payload;
    },
    setCKycUploadStatus: (state, action) => {
      state.cKycUploadStatus = { ...state.cKycUploadStatus, ...action.payload };
    },
    setCKycUploadLoader: (state, action) => {
      state.cKycUploadLoader = { ...state.cKycUploadLoader, ...action.payload };
    },
    setCKycFailCount: (state) => {
      state.cKycFailCount = state.cKycFailCount + 1;
    },
    setCKycResponse: (state, { payload }) => {
      state.cKycResponse = payload;
    },
    setMetaData: (state, { payload }) => {
      state.meta_data = payload;
    },
  },
});

export const {
  setProposalData,
  setActive,
  setPrimaryProposerId,
  setProposalMembers,
  setP_agree,
  setCKycStatus,
  setCKycUploadStatus,
  setCKycUploadLoader,
  setCKycFailCount,
  setMetaData,
  setCKycResponse,
} = proposalSlice.actions;

export default proposalSlice.reducer;
