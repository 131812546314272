import { useField } from "formik";
import { TextField } from "@mui/material";

const TextInput = ({ name, ...props }) => {
  const [field, meta] = useField(name);
  const config = {
    ...field,
    ...props,
    fullWidth: true,
  };
  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }
  // console.log("TEXT", { config, meta });
  return (
    <TextField
      {...config}
      inputProps={{
        ...config?.inputProps,
        sx: {
          color: config?.inputProps?.readOnly ? "#000" : "#000",
          textAlign: "left",
          marginBottom: "5px",
        },
      }}
    />
  );
};
export default TextInput;
