import { BookmarkBorderOutlined } from "@mui/icons-material";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useUrlQuery from "../../../hooks/useUrlQuery";

export default function MobileShortListB({ handleClickOpen }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { shortListedQuotes } = useSelector((state) => state.quote);

  return (
    <Box>
      {shortListedQuotes.length > 0 && (
        <Stack
          justifyContent="center"
          alignItems="center"
          style={{
            borderRadius: "15px",
            backgroundColor: "var(--primary-color)",
            position: "relative",
            height: "15px",
            width: "15px",
            marginTop: "-15px",
            top: 10,
            left: 40,
            p: 0.5,
          }}
        >
          <span
            style={{
              fontSize: "10px",
              color: "#fff",
              textAlign: "center",
              marginTop: "1px",
              marginLeft: "1px",
            }}
          >
            {shortListedQuotes.length}
          </span>
        </Stack>
      )}
      <Stack
        onClick={() => {
          if (shortListedQuotes.length > 0) {
            navigate(`/shortlisted-quotes?enquiry_id=${enquiry_id}`);
          } else {
            handleClickOpen();
          }
        }}
        spacing={1}
        alignItems={"center"}
      >
        <BookmarkBorderOutlined />{" "}
        <span
          style={{
            fontSize: isTablet ? "16px" : "12px",
            fontWeight: "500",
            color: "#000000",
            whiteSpace: "nowrap",
          }}
        >
          ShortListed
        </span>
      </Stack>
    </Box>
  );
}
