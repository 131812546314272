import { RadioGroup, FormControlLabel, Radio, InputLabel } from "@mui/material";
import { useField, useFormikContext } from "formik";

const RadioButton = ({ name, options = [], ...props }) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();
  const config = {
    ...field,
    ...props,
    onChange: (e, value) => {
      setFieldValue(name, value);
    },
  };

  return (
    <>
      <InputLabel sx={{ whiteSpace: "pre-wrap" }}>{props.label}</InputLabel>
      <RadioGroup
        {...config}
        sx={{
          flexDirection: "row",
          gap: !props.noGap && "20px",
          color: "var(--primary-color)",
        }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.display_name}
            value={option.code}
            control={
              <Radio
                sx={{
                  color: "var(--primary-color)",
                  "&.Mui-checked": {
                    color: "var(--primary-color)",
                  },
                }}
              />
            }
            label={option.display_name}
            sx={{
              mt: "-8px",
              ml: "-8px",
              "& .MuiFormControlLabel-label": {
                fontSize: "14px",
                mt: "2px",
                color: "var(--primary-color)",
              },
            }}
          />
        ))}
      </RadioGroup>
    </>
  );
};
export default RadioButton;
