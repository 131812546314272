import { IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
const BackButton = ({ onClick }) => {
  return (
    <>
      <IconButton
        sx={{
          borderRadius: 0,
          color: "#111",
          padding: "6px ",
          mt: 2,
        }}
        onClick={onClick}
      >
        <ArrowBackIosNewIcon
          sx={{
            fontSize: "16px",
          }}
        />
      </IconButton>
    </>
  );
};
export default BackButton;
