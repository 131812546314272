import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setQuotesToShare,
  setShareSelectedQuotes,
  showShareQuotesModal,
} from "../../../../modules/quote.slice";
import { toast } from "react-toastify";
import { Close, Reply, Share } from "@mui/icons-material";
import { useGetQuotes } from "../../../../services/quoteService";

export default function SendQuoteB() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl();
  const { shareSelectedQuotes, quotesToShare } = useSelector(
    (state) => state.quote
  );
  const { isAnyQuoteLoading, allQuotes } = useGetQuotes();
  const dispatch = useDispatch();
  return (
    <>
      <Toolbar>
        <Button
          sx={{
            height: "40px",
            textTransform: "capitalize",
            border: isMobile && "none",
          }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          variant="outlined"
        >
          <Stack
            spacing={isMobile ? 0 : 1}
            direction={isMobile ? "column" : "row"}
            alignItems={"center"}
          >
            {isMobile ? (
              <Share sx={{ mb: 0.5 }} />
            ) : (
              <Reply sx={{ transform: "scaleX(-1)" }} />
            )}
            {isMobile ? (
              <span
                style={{
                  fontSize: isTablet ? "16px" : "12px",
                  fontWeight: "500",
                  color: "#000000",
                  whiteSpace: "nowrap",
                }}
              >
                Share
              </span>
            ) : (
              <span>Share Quote</span>
            )}
          </Stack>
        </Button>
        <Menu
          sx={{
            "& > .MuiPaper-root": {
              border: "1px solid",
              borderColor: "primary.main",
            },
          }}
          open={open}
          disableEnforceFocus
          onClose={handleClose}
          anchorEl={anchorEl}
        >
          <MenuItem
            sx={{ fontSize: isMobile ? "16px" : "12px" }}
            onClick={() => {
              handleClose();
              if (isAnyQuoteLoading) {
                toast.info(
                  <div style={{ fontSize: ".8rem" }}>
                    Few Quotes are Loading
                  </div>
                );
                return;
              }
              dispatch(showShareQuotesModal());
              dispatch(setQuotesToShare(allQuotes));
            }}
          >
            Share All Quotes
          </MenuItem>
          <MenuItem
            sx={{
              fontSize: isMobile ? "16px" : "12px",
            }}
            onClick={() => {
              handleClose();
              dispatch(setShareSelectedQuotes(true));
            }}
          >
            Share Selected Quotes
          </MenuItem>
        </Menu>
      </Toolbar>
      {shareSelectedQuotes && (
        <Box
          sx={{
            background: "rgba(0, 0, 0, 0.8)",
            padding: "5px 10px",
            position: "fixed",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            borderRadius: "5px",
            zIndex: 99999,
            fontSize: isTablet ? "18px" : "12px",
            color: "#fff",
          }}
        >
          {quotesToShare.length} Quotes Selected{" "}
          <Button
            size="small"
            sx={{
              fontSize: isTablet ? "16px" : "12px",
              px: 2,
              py: 1,
            }}
            onClick={() => {
              dispatch(setShareSelectedQuotes(false));
              dispatch(showShareQuotesModal());
            }}
          >
            Share
          </Button>
          <Button
            size="small"
            variant="text"
            sx={{
              fontSize: "12px",
              px: 2,
              py: 1,
              color: "white",
            }}
            onClick={() => {
              dispatch(setQuotesToShare([]));
            }}
          >
            Clear
          </Button>
          <IconButton
            size="small"
            sx={{
              color: "white",
            }}
            onClick={() => {
              dispatch(setShareSelectedQuotes(false));
            }}
          >
            <Close fontSize="14px" />
          </IconButton>
        </Box>
      )}
    </>
  );
}
