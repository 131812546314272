import { Card, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

export default function MobileRecommend() {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  return (
    <Card
      sx={{
        borderRadius: "10px 10px 0px 0px",
        p: 0.5,
        pl: 1,
        backgroundColor: "var(--primary-color)",
      }}
    >
      <Typography
        sx={{ mt: 0.5, fontSize: isTablet ? "16px" : "8px" }}
        color={"#FFFFFF"}
        fontWeight="300"
      >
        Hi, Here’s Our Recommend Plan On The Basis Of The Details You Have
        Provided
      </Typography>
    </Card>
  );
}
