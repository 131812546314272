import { ArrowDropDownSharp, ArrowDropUpSharp } from "@mui/icons-material";
import {
  Box,
  Card,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setSortBy } from "../../../../../modules/landing.slice";

export default function SortBy({ subtitle }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClicked = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };
  return (
    <>
      <Card
        id="basic-card"
        onClick={handleClicked}
        sx={{ width: "25%" }}
        disableRipple
        variant="flat"
      >
        <Stack
          alignItems={isMobile ? "flex-start" : "center"}
          direction={isMobile ? "column" : "row"}
          px={1}
          justifyContent="center"
          height="100%"
        >
          <Typography
            sx={{
              fontSize: isMobile ? "9px" : "12px",
              fontWeight: "400",
            }}
            color="#333333"
            variant="h6"
          >
            Sort By :
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography
              sx={{
                fontSize: isMobile ? "10px" : "12px",
                fontWeight: "600",
                textTransform: "capitalize",
              }}
            >
              {subtitle}{" "}
            </Typography>
            {anchorEl ? (
              <ArrowDropUpSharp
                sx={{
                  width: isMobile ? "20px" : "40px",
                  height: "40px",
                  color: "black",
                }}
              />
            ) : (
              <ArrowDropDownSharp
                sx={{
                  width: isMobile ? "20px" : "40px",
                  height: "40px",
                  color: "black",
                }}
              />
            )}
          </Stack>
        </Stack>
      </Card>
      <Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-card",
          }}
          sx={{
            "& .MuiPaper-root": {
              borderTop: "none",
              borderRadius: 0,
              borderBottomRightRadius: "5px",
              width: "146px",
              borderBottomLeftRadius: "5px",
              boxShadow: "4px 4px 0px rgba(103, 128, 254, 0.1)",
              marginLeft: "40px",
              marginTop: "0px",
              " @media screen and (min-width: 1440px) and (max-width: 1500px) ":
                {
                  marginLeft: "49px",
                },
            },
          }}
        >
          <Box sx={{ width: "146px" }}>
            <FilterItem setAnchorEl={setAnchorEl}>
              <Typography
                textTransform={"capitalize"}
                sx={{ fontSize: "15px", margin: "0 auto" }}
                variant="body1"
                gutterBottom
              >
                high to low
              </Typography>
            </FilterItem>
            <FilterItem setAnchorEl={setAnchorEl}>
              <Typography
                textTransform={"capitalize"}
                sx={{ fontSize: "15px", margin: "0 auto" }}
                variant="body1"
                gutterBottom
              >
                low to high
              </Typography>
            </FilterItem>
          </Box>
        </Menu>
      </Box>
    </>
  );
}
const FilterItem = ({ children, setAnchorEl }) => {
  const dispatch = useDispatch();
  return (
    <MenuItem
      onClick={(e) => {
        dispatch(setSortBy(e.target.innerText));
        setAnchorEl(null);
      }}
      name="sort_By"
      sx={{
        ":hover": {
          backgroundColor: "var(--primary-light)",
        },
      }}
    >
      {children}
    </MenuItem>
  );
};
