import styled from "styled-components";

export const InputErrorMessage = styled.h6`
  font-size: 12px;
  margin-left: 10px;
  margin-top: 10px;
  color: #d32f2f;
`;
export const FileUploadStyled = styled.input`
  padding: 17px;
  border: ${(props) =>
    `${props.error ? "1px solid #d32f2f" : "2px solid silver"}`};
  &:focus {
    border: ${(props) =>
      `${props.error ? "2px solid #d32f2f" : "2px solid silver"}`};
    box-shadow: none;
  }
`;
export const FileUploadContainer = styled.div`
  position: relative;
  &::before {
    content: ${(props) => `"${props.label}"`};
    background-color: #fff;
    padding: 5px;
    position: absolute;
    left: 16px;
    top: 0;
    font-size: 0.7rem;
    transform: translateY(-50%);
    color: ${(props) => `${props.error ? "#d32f2f" : "gray"}`};
    pointer-events: none; /* Ensures that the text doesn't block input interaction */
  }
`;
