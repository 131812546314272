import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import TextInput from "../../../components/InputComponents/TextInput";
import { allowOnlyNumbers } from "../../../utils/input";
import {
  useSendOtpToVerify,
  useVerifyOtp,
} from "../../../services/landingService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 550,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};

const OTPVerificationModal = ({
  email: defaultEmail,
  mobile: defaultMobile,
  isOtpModalOpen,
  setIsOtpModalOpen,
}) => {
  const { sendOtp } = useSendOtpToVerify();
  const { verifyOTP } = useVerifyOtp();
  return (
    <Modal
      open={isOtpModalOpen}
      onClose={() => {
        setIsOtpModalOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          OTP Verification
        </Typography>
        <Formik
          initialValues={{
            email: defaultEmail,
            mobile: defaultMobile,
            otp: "",
          }}
          validationSchema={yup.object().shape({
            otp: yup.string().required("OTP is required"),
          })}
          onSubmit={(data) => {
            verifyOTP({ ...data });
          }}
        >
          {({ handleSubmit }) => (
            <Grid container rowSpacing={3} mt={1}>
              <Grid item xs={12} md={12}>
                <TextInput
                  label="Email"
                  name="email"
                  inputProps={{ readOnly: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextInput
                  label="Mobile"
                  name="mobile"
                  inputProps={{
                    inputMode: "numeric",
                    maxLength: 10,
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextInput
                  label="Enter OTP"
                  name="otp"
                  onInput={allowOnlyNumbers}
                  inputProps={{
                    inputMode: "numeric",
                    maxLength: 6,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  sx={{
                    width: "45%",
                    height: "50px",
                    borderRadius: "0px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                  }}
                  fullWidth
                >
                  Verify
                </Button>
                <Button
                  sx={{
                    width: "45%",
                    height: "50px",
                    borderRadius: "0px",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                  }}
                  fullWidth
                  onClick={() => {
                    sendOtp();
                  }}
                >
                  Resend OTP
                </Button>
              </Grid>
            </Grid>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default OTPVerificationModal;
