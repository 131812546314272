/* eslint-disable eqeqeq */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function TrCover({ name, data }) {
  // const { know_more_data } = useSelector((state) => state.quote);
  // const { data } = know_more_data;
  return (
    <Stack sx={{ pb: 15 }} spacing={1}>
      <Grid justifyContent="center" container spacing={2}>
        <Grid item xs={6}>
          {/* <Stack
            alignItems="center"
            sx={{ mt: -3, mb: 2 }}
            direction={"row"}
            spacing={1}
          >
            <img src={imgForTitle[name]} width="25px" height="25px" alt="" />
            <Typography
              sx={{ mt: -4 }}
              variant="h6"
              color="var(--primary-color)"
              fontWeight="600"
            >
              {name}
            </Typography>
          </Stack> */}
        </Grid>
      </Grid>
      <Box
        sx={{
          overflow: "auto",
          maxHeight: "500px",
          mb: 10,
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
        }}
      >
        {data.map((item) => {
          return (
            <Box>
              <Accordion
                sx={{
                  borderBottom: "1px solid rgba(165, 176, 250, 0.5)",
                  minHeight: "50px",
                  boxShadow: "none",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "black" }} />}
                >
                  <Stack>
                    <Typography variant="h6" fontSize="14px" fontWeight="400">
                      {item.cover}
                    </Typography>

                    <Stack alignItems="center" direction="row" spacing={1}>
                      {/* {ic_data[0].id == 1 && currency == 2 ? (
                      <Typography variant="h6" fontSize="16px" fontWeight="500">
                        £ {item.sum_insured}
                      </Typography>
                    ) : (
                      <Typography variant="h6" fontSize="16px" fontWeight="500">
                        {item.feature_key_title}
                      </Typography>
                    )} */}
                      <Typography variant="h6" fontSize="16px" fontWeight="500">
                        {item.feature_key_title}
                      </Typography>
                      <span
                        style={{
                          fontSize: "16px",
                          // marginTop: 0.5,
                          fontWeight: "500",
                        }}
                      >
                        {item.feature_key_value && (
                          <> - {item.feature_key_value}</>
                        )}
                      </span>
                    </Stack>
                  </Stack>
                </AccordionSummary>

                <AccordionDetails
                  sx={{
                    pt: 0,
                  }}
                >
                  <Typography fontSize="14px" color="#000000">
                    {item.feature_key_description}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        })}
      </Box>
    </Stack>
  );
}
