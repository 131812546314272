import { Grid, Typography } from "@mui/material";

const SummaryData = ({ label, value, fullWidth }) => {
  if (!value) return null;
  return (
    <>
      <Grid item xs={12} md={fullWidth ? 12 : 4}>
        <Typography
          fontSize="15px"
          fontWeight="400"
          color="#494949"
          gutterBottom
        >
          {label}
        </Typography>
        <Typography fontWeight={"600"} fontSize={"15px"}>
          {value}
        </Typography>
      </Grid>
    </>
  );
};

export default SummaryData;
