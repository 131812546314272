import { createSlice } from "@reduxjs/toolkit";

const landingSlice = createSlice({
  name: "landing",
  initialState: {
    activeStep: 0,
    leadData: {},
    currency: 1,
    filters: {
      sum_insured: "50000",
      insurers: [],
      sortBy: "Low To High",
      planType: "Single Trip",
    },
    traceId: "",
    uuid: "",
    verificationStatus: false,
  },

  reducers: {
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setVerificationStatus: (state, action) => {
      state.verificationStatus = action.payload;
    },
    setUuid: (state, action) => {
      state.uuid = action.payload;
    },
    setLeadData: (state, action) => {
      state.leadData = {
        ...state.leadData,
        ...action.payload,
      };
    },
    setSumInsurer: (state, action) => {
      state.filters.sum_insured = action.payload;
    },
    setSortBy: (state, action) => {
      state.filters.sortBy = action.payload;
    },
    setInsurers: (state, action) => {
      state.filters.insurers = action.payload;
    },
    setPlanType: (state, action) => {
      state.filters.planType = action.payload;
    },
    setTraceId: (state, action) => {
      state.traceId = action.payload;
    },
    temp_data: (state, { payload = [] }) => {
      if (payload.length) {
        //currency
        state.currency = payload[0].currency;
      }
    },
  },
});

export const {
  setPlanType,
  setActiveStep,
  setLeadData,
  setSumInsurer,
  setSortBy,
  temp_data,
  setTraceId,
  setInsurers,
  setIsOtpJourney,
  setUuid,
} = landingSlice.actions;

export const activeStepSelector = (state) => state.landing.activeStep;

export default landingSlice.reducer;
