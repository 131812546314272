import { combineReducers } from "@reduxjs/toolkit";
import landingSlice from "../modules/landing.slice";
import proposalSlice from "../modules/proposal.slice";
import quoteSlice from "../modules/quote.slice";
const rootReducer = combineReducers({
  landing: landingSlice,
  quote: quoteSlice,
  proposal: proposalSlice,
});
export default rootReducer;
