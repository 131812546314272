/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import {
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  Button,
  Typography,
  Stack,
  IconButton,
  Alert,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "../../components/InputComponents/Checkbox";
import LeftCard from "./LeftCard";
import AddressDetails from "./proposal-forms/AddressDetails";
import MedicalDetails from "./proposal-forms/MedicalDetails";
import NomineeDetails from "./proposal-forms/NomineeDetails";
import ProposerDetails from "./proposal-forms/ProposerDetails";
import StudentDetails from "./proposal-forms/StudentDetails";
import TravelerDetails from "./proposal-forms/TravelerDetails";
import plane from "./../../../src/assets/images/airplane.png";
import MobileFooter from "./component/MobileFooter";
import MobileCard from "./MobileCard";
import { useGetUserDetails } from "../../services/quoteService";
import { useSubmitProposalMutation } from "../../services/proposalService";
import QuoteBack from "../QuotesPage/components/QuoteBack";
import { useNavigate } from "react-router-dom";
import useUrlQuery from "../../hooks/useUrlQuery";
import { CancelOutlined, CancelRounded } from "@mui/icons-material";
import LandingBg from "../../assets/images/LandingBg.png";
import CkycBajaj from "./proposal-forms/CkycBajaj.jsx";
import CkycTata from "./proposal-forms/CkycTata.jsx";
import CkycCare from "./proposal-forms/CkycCare.jsx";
import CkycDigit from "./proposal-forms/CkycDigit.jsx";

import { setActive } from "../../modules/proposal.slice";
import ButtonLoader from "../../components/ButtonLoader/ButtonLoader.jsx";
import CkycStar from "./proposal-forms/CkycStar.jsx";
import { careMedicalQuestions } from "./constants/care.constant.js";
import { starMedicalQuestions } from "./constants/star.constant.js";
export default function ProposalPage() {
  const navigate = useNavigate();
  const [ckycError, setCkycError] = useState(false);
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const [show, setShow] = useState("none");
  const [showForm, setShowForm] = useState("block");
  const theme = useTheme();
  const isMobileAndTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { userDetails } = useGetUserDetails();
  const { submitProposal, isProposalSubmitLoading } =
    useSubmitProposalMutation();
  const p_agree = useSelector((state) => state.proposal.p_agree);
  const active = useSelector((state) => state.proposal.active);
  const status = useSelector((state) => state.proposal.meta_data?.status);
  const message = useSelector((state) => state.proposal.meta_data?.message);
  const formRef = useRef(null);
  const selected_plan = userDetails?.selected_plan;
  const isStudent = userDetails.travel_purpose_id == "4";
  const dispatch = useDispatch();
  function loadRazorPayScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  async function displayRazorParPaymentSDK() {
    const res = await loadRazorPayScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("RazorPay SDK failed to load. please check are you online?");
      return;
    }
  }
  useEffect(() => {
    displayRazorParPaymentSDK();
  }, []);

  useEffect(() => {
    if (status == false) {
      setCkycError(true);
      dispatch(setActive(0));
    }
  }, [status]);

  useEffect(() => {
    if (active - 2)
      formRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [active]);

  const medicalIndex = isStudent ? 6 : 5;
  const ckycIndex =
    selected_plan?.alias === "tata_aig" ||
    selected_plan?.alias === "bajaj_allianz" ||
    selected_plan?.alias === "care_health" ||
    selected_plan?.alias === "star" ||
    selected_plan?.alias === "go_digit";
  return (
    <Box
      px={isMobileAndTablet ? 0 : 4}
      sx={{
        backgroundColor: "#fafbfe",
        minHeight: "100vh",
        backgroundImage: { xs: "none", sm: `url(${LandingBg})` },
        backgroundPosition: "bottom",
        backgroundRepeat: "repeat-x",
      }}
    >
      <Grid
        container
        spacing={0}
        sx={{ display: isMobileAndTablet ? showForm : "" }}
      >
        {isMobileAndTablet ? (
          <IconButton
            onClick={() => {
              navigate("/quote?enquiry_id=" + enquiry_id);
            }}
            sx={{ position: "absolute", top: isMobile ? 9 : 13, right: 0 }}
          >
            <CancelRounded color="primary" />
          </IconButton>
        ) : (
          <Grid sx={{ ml: 2 }} item md={12}>
            <Grid container>
              <Grid item md={3.5}>
                <QuoteBack
                  onClick={() => {
                    navigate(`/quote?enquiry_id=${enquiry_id}`);
                  }}
                >
                  Back
                </QuoteBack>
              </Grid>
              <Grid item md={8.5}>
                <Stack alignItems="center" sx={{ mt: 0 }} direction="row">
                  <Typography
                    sx={{ ml: 0 }}
                    fontWeight="bold"
                    fontSize="22px"
                    variant="h6"
                  >
                    Almost Done! Help me with few more details.
                  </Typography>
                  <img src={plane} alt="plane" width="80px" height="80px" />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        )}
        {isMobileAndTablet ? (
          <Grid item xs={12}>
            <Stack alignItems="center" sx={{ p: 0 }} direction="row">
              <Typography
                sx={{ ml: 2 }}
                fontWeight="bold"
                fontSize="11px"
                variant="h6"
              >
                Almost Done! Help me with few more details.
              </Typography>
              <img src={plane} alt="plane" width="40px" />
            </Stack>
          </Grid>
        ) : (
          <Grid sx={{ mt: isMobileAndTablet ? 0 : -4 }} item md={3.5}>
            <LeftCard />
          </Grid>
        )}
        {isMobileAndTablet ? (
          <Grid sx={{ mt: 0 }} item xs={12}>
            <MobileFooter setShowForm={setShowForm} setShow={setShow} />
          </Grid>
        ) : null}
        <Grid
          item
          sx={{
            mt: isMobileAndTablet ? -0 : -3,
            ml: isMobileAndTablet ? 0 : -2,
          }}
          xs={12}
          md={8.5}
        >
          <ProposerDetails index={0} ckycIndex={ckycIndex} />
          {selected_plan?.alias === "bajaj_allianz" && <CkycBajaj index={1} />}
          {selected_plan?.alias === "tata_aig" && <CkycTata index={1} />}
          {selected_plan?.alias === "care_health" && <CkycCare index={1} />}
          {selected_plan?.alias === "star" && <CkycStar index={1} />}
          {selected_plan?.alias === "go_digit" && <CkycDigit index={1} />}
          <div ref={active === 1 ? formRef : null}>
            <TravelerDetails index={ckycIndex ? 2 : 1} />
          </div>
          <div ref={active === 2 ? formRef : null}>
            <NomineeDetails index={ckycIndex ? 3 : 2} />
          </div>
          <div ref={active === 3 ? formRef : null}>
            <AddressDetails index={ckycIndex ? 4 : 3} />
          </div>
          {isStudent && (
            <div ref={active === 4 ? formRef : null}>
              <StudentDetails index={ckycIndex ? 5 : 4} />
            </div>
          )}
          <div ref={active === medicalIndex ? formRef : null}>
            <MedicalDetails
              index={medicalIndex}
              medicalQuestions={
                selected_plan?.alias === "care_health"
                  ? careMedicalQuestions
                  : selected_plan?.alias === "star"
                  ? starMedicalQuestions
                  : careMedicalQuestions
              }
            />
          </div>
          <Formik initialValues={{ agree: true }}>
            <Grid container justifyContent="center" spacing={2}>
              <Checkbox
                sx={{ ml: 3, mt: 2 }}
                checked={p_agree === "N" ? false : true}
                name="agree"
                label={
                  <Typography
                    color="#a1a1b6"
                    fontSize={isMobileAndTablet ? "10px" : "16px"}
                  >
                    I confirm all the details shared are correct and accurate as
                    per my knowledge and I agree with all the Terms and
                    Conditions. I also declare that the information provided
                    above is true and accept that if it is found to view more...
                  </Typography>
                }
              />
              <Grid justifyContent="center" item xs={12} md={2.5}>
                <Button
                  disabled={
                    p_agree === "N" && active <= medicalIndex ? true : false
                  }
                  color="secondary"
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    fontSize: ".8rem",
                    letterSpacing: ".4px",
                    borderRadius: isMobileAndTablet ? "0px" : 1,
                    mb: isMobileAndTablet ? "0px" : 4,
                  }}
                  fullWidth={isMobileAndTablet ? true : false}
                  onClick={async () =>
                    !isProposalSubmitLoading && submitProposal()
                  }
                >
                  {isProposalSubmitLoading ? (
                    <ButtonLoader />
                  ) : (
                    "Review & Submit"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Formik>
        </Grid>
      </Grid>
      {isMobileAndTablet && (
        <Grid sx={{ display: show }} container justifyContent="center">
          <MobileCard setShow={setShow} setShowForm={setShowForm} />
        </Grid>
      )}
      {ckycError && (
        <Alert
          severity="error"
          action={
            <>
              <button
                style={{
                  marginLeft: "78%",
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                  borderColor: "var(--primary-color)",
                  borderRadius: "4px",
                  padding: "10px",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                }}
                onClick={() => setCkycError(false)}
              >
                <text>OK</text>
              </button>
            </>
          }
        >
          <CancelOutlined
            sx={{ color: "#f27474", fontSize: "89px", marginBottom: "30px" }}
          />
          <h3> Error </h3>
          <h5>{message}</h5>
        </Alert>
      )}
    </Box>
  );
}
