/* eslint-disable eqeqeq */
import * as yup from "yup";
import {
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../components/InputComponents/Select";
import TextInput from "../../../components/InputComponents/TextInput";
import { setProposalData } from "../../../modules/proposal.slice";
import { useStoreProposalMutation } from "../../../services/proposalService";
import { upperCase } from "../../../utils/input";
import Title from "../component/FormTitle";
import Summary from "../component/Summary";
import CkycVerified from "../component/CkycVerified";
import { documentOptions } from "../constants/tata.constant";

const CkycTata = ({ index }) => {
  const {
    proposalData,
    cKycFailCount,
    cKycStatus,
    primary_proposer_id,
    active,
  } = useSelector((state) => state.proposal);

  const pan_no = proposalData?.proposar?.pan_no;
  const initialData = { docOptions: "aadhaar", pan_no: pan_no };

  const yupValidate = yup.object().shape({
    pan_no: yup
      .string()
      .required("Pan no. is required")
      .matches(
        /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
        "PAN number invalid"
      ),
    docOptions: yup.string(),
    aadhaar_no: yup.string().when("docOptions", {
      is: "aadhaar",
      then: yup
        .string()
        .nullable()
        .required("Aadhar No. is required")
        .matches(
          /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
          "Invalid Aadhaar Number"
        ),
    }),
    voter_no: yup.string().when("docOptions", {
      is: "vid",
      then: yup
        .string()
        .nullable()
        .required("Voter ID. is required")
        .matches(/^([a-zA-Z]){3}([0-9]){7}?$/, "Invalid Voter ID Number"),
    }),
    driving_license: yup.string().when("docOptions", {
      is: "license",
      then: yup.string().nullable().required("Driving License required"),
    }),
    ckyc_no: yup
      .string()
      .nullable()
      .when("docOptions", {
        is: "ckyc_number",
        then: yup
          .string()
          .required("CKYC No. is required")
          .matches(/^[5-9][0-9]{9}$/, "Invalid CKYC Number"),
      }),
    passport_no: yup
      .string()
      .nullable()
      .when("docOptions", {
        is: "passport",
        then: yup
          .string()
          .required("Passport No. is required")
          .matches(/^[A-Z]{1}[0-9]{7}$/, "Invalid Passport Number"),
      }),
  });
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { storeProposal } = useStoreProposalMutation({ index });

  if (index < active) {
    return (
      <Summary title="CKYC Details" index={index}>
        <CkycVerified
          innerText={`${
            proposalData?.proposar?.first_name +
            " " +
            proposalData?.proposar?.last_name
          }, your KYC verification is completed, please continue with further steps.`}
        />
      </Summary>
    );
  }
  if (index > active) {
    return (
      <Paper sx={{ m: 2, backgroundColor: "#Fff" }}>
        <Typography fontSize={"22px"} p={2}>
          CKYC Details
        </Typography>
      </Paper>
    );
  }
  return (
    <>
      <Paper sx={{ m: 2, backgroundColor: "#F0F4F5" }}>
        <Formik
          initialValues={initialData}
          onSubmit={(values) => {
            dispatch(
              setProposalData({
                ckyc_data: {
                  ...values,
                  docType: values.docOptions,
                  docNumber:
                    values.passport_no ||
                    values.aadhaar_no ||
                    values.ckyc_no ||
                    values.voter_no ||
                    values.driving_license,
                },
              })
            );
            storeProposal({
              step: 10,
              additional_data: {
                ...proposalData,
                ckyc_data: {
                  ...values,
                  docType: values.docOptions,
                  docNumber:
                    values.passport_no ||
                    values.aadhaar_no ||
                    values.ckyc_no ||
                    values.voter_no ||
                    values.driving_license,
                },
              },
              ckyc_data: { ckyc: cKycStatus },
              primary_proposer_id,
              is_proposer: 1,
            });
          }}
          validationSchema={yupValidate}
        >
          {({ values }) => {
            return (
              <div
                style={{
                  backgroundColor: "#f0f4f5",
                  borderRadius: "4px",
                  boxShadow:
                    " 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                  transition:
                    " box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                }}
              >
                <Form>
                  <Card>
                    <Title>CKYC Details</Title>
                    <div className="main" style={{ padding: "10px" }}>
                      {cKycFailCount == 0 && (
                        <div className="pan">
                          <Grid item sx={{ display: "flex" }}>
                            <Grid item xs={12} md={4} sx={{ padding: "16px" }}>
                              <TextInput
                                name={`pan_no`}
                                values={pan_no}
                                label="PAN No"
                                inputProps={{ readOnly: true }}
                                onInput={upperCase}
                              />
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ padding: "16px" }}>
                              <Select
                                name={"docOptions"}
                                label="Select Document"
                                options={documentOptions}
                              />
                            </Grid>
                            {values.docOptions === "ckyc_number" && (
                              <Grid
                                item
                                xs={12}
                                md={4}
                                sx={{ padding: "16px" }}
                              >
                                <TextInput
                                  name={`ckyc_no`}
                                  label="CKYC No"
                                  inputProps={{ maxLength: 12 }}
                                  onInput={upperCase}
                                />
                              </Grid>
                            )}
                            {values.docOptions === "aadhaar" && (
                              <Grid
                                item
                                xs={12}
                                md={4}
                                sx={{ padding: "16px" }}
                              >
                                <TextInput
                                  name={`aadhaar_no`}
                                  label="Aadhaar No"
                                  inputProps={{ minLength: 12, maxLength: 12 }}
                                />
                              </Grid>
                            )}
                            {values.docOptions === "vid" && (
                              <Grid
                                item
                                xs={12}
                                md={4}
                                sx={{ padding: "16px" }}
                              >
                                <TextInput
                                  name={`voter_no`}
                                  label="Voter ID No"
                                  inputProps={{ maxLength: 12 }}
                                  onInput={upperCase}
                                />
                              </Grid>
                            )}
                            {values.docOptions === "license" && (
                              <Grid
                                item
                                xs={12}
                                md={4}
                                sx={{ padding: "16px" }}
                              >
                                <TextInput
                                  name={`driving_license`}
                                  label="Driving License No"
                                  inputProps={{ maxLength: 12 }}
                                  onInput={upperCase}
                                />
                              </Grid>
                            )}
                            {values.docOptions === "passport" && (
                              <Grid
                                item
                                xs={12}
                                md={4}
                                sx={{ padding: "16px" }}
                              >
                                <TextInput
                                  name={`passport_no`}
                                  label="Passport Number"
                                  inputProps={{ maxLength: 8 }}
                                  onInput={upperCase}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </div>
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          p: 2,
                        }}
                      >
                        <Button
                          fullWidth={isMobile ? true : false}
                          sx={{
                            textTransform: "none",
                            fontSize: isMobile ? "13px" : "16px",
                            p: 2,
                            pl: 4,
                            pr: 4,
                            borderRadius: 1,
                          }}
                          variant="contained"
                          color="secondary"
                          type="submit"
                        >
                          Proceed to Traveler
                        </Button>
                      </Box>
                    </div>
                  </Card>
                </Form>
              </div>
            );
          }}
        </Formik>
      </Paper>
    </>
  );
};
export default CkycTata;
