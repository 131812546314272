import { Autocomplete, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";

const MobileSelect = ({ options = [], name, ...props }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const config = {
    ...field,
    ...props,
    options,
    onChange: (e, value) => {
      setFieldValue(name, value.code);
    },
    value: field.value
      ? options.find((option) => option.code === field.value)
      : null,
    getOptionLabel: (option) => option.display_name,
    isOptionEqualToValue: (option, value) => {
      return option.code === value.code;
    },
  };
  if (meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }
  return (
    <Autocomplete
      {...config}
      disableClearable
      renderInput={(params) => (
        <>
          <TextField
            sx={{
              height: "40px",
              color: "var(--primary-color)",
              input: {
                border: "none",
                fontSize: "16px",
                color: "var(--primary-color)",
                "&::placeholder": {
                  color: "text.secondary",
                  opacity: "1",
                },
              },
              fieldset: { border: "none" },
              "& .MuiInputBase-root": {
                color: "var(--primary-color)",
              },
            }}
            placeholder={props.placeholder}
            {...params}
          />
        </>
      )}
    />
  );
};
export default MobileSelect;
