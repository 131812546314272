import {
  Card,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useGetUserDetails } from "../../../services/quoteService";

export default function LeftC({ setShow, setShowForm }) {
  const { userDetails } = useGetUserDetails();
  const selected_plan = userDetails?.selected_plan;
  return (
    <Card
      sx={{
        p: 2,
        width: "100%",
        boxShadow: 0,
        borderRadius: 0,
        backgroundColor: "#f3f6ff",
      }}
    >
      <Grid alignItems="center" container>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          item
          xs={2}
        >
          <img
            src={
              userDetails.company_details &&
              userDetails?.company_details[userDetails?.ic_detail?.ic_alias]
                ?.logo
            }
            alt="logo"
            style={{ width: "45px", height: "auto" }}
          />
        </Grid>
        <Grid spacing={5} item xs={10}>
          <Stack>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "600" }}
              variant="h4"
            >
              {userDetails?.ic_detail?.ic_name}
            </Typography>
            <Typography sx={{ fontSize: "11px", fontWeight: "500" }}>
              {selected_plan?.plan_name}
            </Typography>
            <Stack direction="row">
              <Typography
                sx={{
                  fullWidth: true,
                  fontSize: "11px",
                  fontWeight: "500",
                  color: "rgba(0, 0, 0, 0.7)",
                  width: "73%",
                }}
                variant="body1"
              >
                Sum insured per member
              </Typography>
              <Typography
                sx={{
                  color: "#1f1f67",
                  fontSize: "11px",
                  fontWeight: "700",
                  width: "27%",
                }}
                variant="body1"
              >
                : $ {selected_plan?.sum_insured?.toLocaleString("en-IN")}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        {/* <Grid sx={{ mt: 1 }} item xs={2}> */}
        <IconButton
          aria-label="close"
          onClick={() => {
            setShow("none");
            setShowForm("block");
          }}
          sx={{
            position: "absolute",
            right: 2,
            top: 2,
            borderRadius: 0,
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon sx={{ height: "20px", width: "20px" }} />
        </IconButton>
        {/* </Grid> */}
      </Grid>

      <Divider flexItem />

      <Grid sx={{ mt: 1 }} alignItems="right" container>
        <Grid item xs={12}>
          <Typography
            sx={{
              fullWidth: true,
              fontSize: "12px",
              fontWeight: "600",
              marginBottom: 1,
              color: "#000000",
            }}
            variant="body1"
          >
            Premium Breakup :
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={8}>
            <Grid item xs={8}>
              <Typography
                sx={{
                  fullWidth: true,
                  fontSize: "10px",
                  fontWeight: "600",
                  color: "rgba(0, 0, 0, 0.7)",
                }}
                variant="body1"
              >
                Base Plan Premium
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{
                  color: "#1f1f67",
                  fontSize: "10px",
                  fontWeight: "800",
                  marginBottom: 1,
                }}
                variant="body1"
              >
                <span style={{ fontFamily: "Inter" }}>₹ </span>
                {selected_plan?.base_premium?.toLocaleString("en-IN")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid justifyContent="center" container spacing={8}>
            <Grid item xs={8}>
              <Typography
                sx={{
                  fullWidth: true,
                  fontSize: "10px",
                  fontWeight: "600",
                  color: "rgba(0, 0, 0, 0.7)",
                }}
                variant="body1"
              >
                GST
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{
                  color: "#1f1f67",
                  fontSize: "10px",
                  fontWeight: "800",
                }}
                variant="body1"
              >
                <span style={{ fontFamily: "Inter" }}>₹ </span>
                {selected_plan?.tax?.toLocaleString("en-IN")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
