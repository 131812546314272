import {
  Checkbox as Check,
  FormControlLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useField, useFormikContext } from "formik";

const Checkbox = ({ name, handleChange, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const config = {
    ...field,
    ...props,
    onChange: (e) => {
      setFieldValue(name, e.target.checked);
      handleChange && handleChange(e.target.checked);
    },
    checked: "checked" in props ? props.checked : field.value,
  };
  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  return (
    <FormControlLabel
      sx={(theme) => {
        return {
          "& .MuiFormControlLabel-label": {
            fontSize: isMobile ? "9px" : "14px",
            color: "var(--primary-color)",
            fontWeight: "500",
          },
          my: 1,
        };
      }}
      {...config}
      control={
        <Check
          sx={{
            color: "var(--primary-color)",
            "&.Mui-checked": {
              color: "var(--primary-color)",
            },
          }}
          inputProps={{ readOnly: props.readOnly }}
        />
      }
    />
  );
};

export default Checkbox;
