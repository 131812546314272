export const documentOptions = [
  { code: "aadhaar", display_name: "Aadhar Card" },
  { code: "ckyc_number", display_name: "CKYC Number" },
  { code: "vid", display_name: "VOTER ID" },
  { code: "license", display_name: "Driving License" },
  { code: "passport", display_name: "Passport Number" },
];

export const tataMedicalQuestions = [
  {
    code: 0,
    question: "Does any person(s) to be insured has any Pre-existing diseases?",
    description: "select",
    // description: "select"||"text"||"none",
    description_question:
      "If Pre-existing diseases, please mention the details",
  },
  {
    code: 1,
    question:
      "Has anyone been diagnosed / hospitalized or under any treatment for any illness / injury during the last 48 months?",
    description: "none",
    description_question: "",
  },
  {
    code: 2,
    question: "Have you ever claimed under any travel policy?",
    description: "none",
    description_question: "",
  },
];
