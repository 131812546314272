import { createSlice } from "@reduxjs/toolkit";

const quoteSlice = createSlice({
  name: "quote",
  initialState: {
    show_more_quotes: {},
    know_more_data: {},
    show_insurer: false,
    show_sum_insurer: false,
    show_sort_by: false,
    show_edit_quote: false,
    edit_mode: "default",
    plan_to_change: "",
    user_data: {},
    compareQuote: [],
    isShareQuotesModalOpen: false,
    shareSelectedQuotes: false,
    quotesToShare: [],
    shortListedQuotes: [],
    showDifference: false,
    showAddCompareModal: false,
    showCompareModal: false,
  },
  reducers: {
    showMoreQuotes: (state, action) => {
      state.show_more_quotes[action.payload] = true;
    },
    setEditMode: (state, action) => {
      state.edit_mode = action.payload;
    },
    hideMoreQuotes: (state, action) => {
      state.show_more_quotes[action.payload] = false;
    },
    setKnowMoreData: (state, action) => {
      state.know_more_data = action.payload;
    },
    setInsurerOpen: (state, action) => {
      state.show_insurer = action.payload;
    },
    setSumOpen: (state, action) => {
      state.show_sum_insurer = action.payload;
    },
    setSortOpen: (state, action) => {
      state.show_sort_by = action.payload;
    },
    setEditOpen: (state, action) => {
      state.show_edit_quote = action.payload;
    },
    setUserData: (state, action) => {
      state.user_data.country = action.payload.country;
      state.user_data.members = action.payload.members;
      state.user_data.userAge = action.payload.userAge;
      state.user_data.memberAge = action.payload.memberAge;
      state.user_data.purpose = action.payload.purpose;
      state.user_data.planType = action.payload.planType;
    },
    addQuote: (state, action) => {
      state.compareQuote.push(action.payload);
    },
    removeQuote: (state, action) => {
      state.compareQuote.splice(action.payload, 1);
    },
    setCompareQuote: (state, action) => {
      state.compareQuote = action.payload;
    },
    removeQuoteFromCompare: (state, action) => {
      state.compareQuote = state.compareQuote.filter(
        (quote) =>
          quote.data[0]?.plan_name !== action.payload.data[0]?.plan_name
      );
    },
    clearCompareQuote: (state) => {
      state.compareQuote = [];
    },
    showShareQuotesModal: (state) => {
      state.isShareQuotesModalOpen = true;
    },
    closeShareQuotesModal: (state) => {
      state.isShareQuotesModalOpen = false;
    },
    setShareSelectedQuotes: (state, action) => {
      state.shareSelectedQuotes = action.payload;
    },
    setQuotesToShare: (state, action) => {
      state.quotesToShare = action.payload;
    },
    setPlanToChange: (state, action) => {
      state.plan_to_change = action.payload;
    },
    addQuoteToShortlist: (state, action) => {
      if (
        state.shortListedQuotes.some(
          (item) =>
            item.data[0]?.plan_name === action.payload.data[0]?.plan_name
        )
      ) {
        state.shortListedQuotes = state.shortListedQuotes.filter(
          (item) =>
            item.data[0]?.plan_name !== action.payload.data[0]?.plan_name
        );
        return;
      } else {
        state.shortListedQuotes.push(action.payload);
      }
    },
    setShowDifference: (state, action) => {
      state.showDifference = action.payload;
    },
    setShowAddCompareModal: (state, action) => {
      state.showAddCompareModal = action.payload;
    },
    setShowCompareModal: (state, action) => {
      state.showCompareModal = action.payload;
    },
  },
});

export default quoteSlice.reducer;

export const {
  addQuoteToShortlist,
  removeQuote,
  showMoreQuotes,
  addQuote,
  setCompareQuote,
  hideMoreQuotes,
  setKnowMoreData,
  setInsurerOpen,
  setSumOpen,
  setShareSelectedQuotes,
  setSortOpen,
  setUserData,
  setEditOpen,
  clearCompareQuote,
  showShareQuotesModal,
  closeShareQuotesModal,
  setQuotesToShare,
  setPlanToChange,
  setEditMode,
  setShowDifference,
  removeQuoteFromCompare,
  setShowAddCompareModal,
  setShowCompareModal,
} = quoteSlice.actions;
