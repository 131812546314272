import { Card, Grid, Paper,  } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import { setActive } from "../../../modules/proposal.slice";
import Title from "./FormTitle";

const Summary = ({ title, children, index }) => {
  const dispatch = useDispatch();
  return (
    <>
      <Paper sx={{ m: 2, backgroundColor: "#F0F4F5" }}>
        <Card>
          <Title
            edit
            onEdit={() => {
              dispatch(setActive(index));
            }}
          >
            {title}
          </Title>
          <Box px={3} py={2}>
            <Grid container spacing={2}>
              {children}
            </Grid>
          </Box>
        </Card>
      </Paper>
    </>
  );
};

export default Summary;
