import {
  Box,
  Checkbox,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector } from "react-redux";
import { useState } from "react";
const FeatureText = ({ children, center }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Typography
      sx={{
        color: "#000",
        fontSize: isMobile ? "10px" : "16px",
        fontWeight: "600",
        display: center ? "block" : "inline-block",
        textAlign: center ? "center" : "left",
      }}
    >
      {children}
    </Typography>
  );
};
const FeatureRow = ({
  feature_text,
  description,
  parent,
  dataValues,
  referenceValue,
  onAddToSelected,
  showOnlySelected,
  setShowOnlySelected,
}) => {
  const { showDifference } = useSelector((state) => state.quote);
  const areAllValuesEqualToReferenceValue = dataValues.every(
    (value) => value === referenceValue
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [checked, setChecked] = useState();
  if (showOnlySelected && !checked) return <></>;
  if (showDifference && areAllValuesEqualToReferenceValue) return <></>;
  return (
    <>
      <Grid
        item
        xs={isMobile ? 4 : 3}
        mb={2}
        sx={{
          color: "#000",
          display: "flex",

          position: "relative",
        }}
      >
        <Checkbox
          sx={{
            p: 0,
            ml: isMobile ? 1 : 2,
            mr: 1,
            alignItems: "self-start",
          }}
          disableRipple
          checked={checked}
          onChange={() => {
            onAddToSelected();
            setChecked(!checked);
          }}
        />
        <Box>
          <Box display="flex">
            <FeatureText>{feature_text}</FeatureText>
            <Tooltip title={description} arrow placement="top">
              <InfoOutlinedIcon
                sx={{
                  height: isMobile ? "15px" : "20px",
                  width: isMobile ? "15px" : "20px",
                  ml: isMobile ? 0 : 1,
                  mt: "-2px",
                }}
              />
            </Tooltip>
          </Box>
          {checked && (
            <Box
              display="inline-block"
              fontSize="8px"
              sx={{
                color: "primary.main",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowOnlySelected(!showOnlySelected);
              }}
            >
              {showOnlySelected ? "Show All" : "Show Only Selected"}
            </Box>
          )}
        </Box>
      </Grid>
      {isMobile
        ? [0, 1].map((item, idx) => (
            <>
              <Grid item xs={isMobile ? 4 : 3}>
                {dataValues[item] ? (
                  <FeatureText center>{dataValues[item]}</FeatureText>
                ) : (
                  <FeatureText center>NA</FeatureText>
                )}
              </Grid>
            </>
          ))
        : [0, 1, 2].map((item, idx) => (
            <>
              <Grid item xs={isMobile ? 4 : 3}>
                {dataValues[item] ? (
                  <FeatureText center>{dataValues[item]}</FeatureText>
                ) : (
                  <FeatureText center>NA</FeatureText>
                )}
              </Grid>
            </>
          ))}
    </>
  );
};

export default FeatureRow;
