import { FormHelperText, Grid } from "@mui/material";
import { Formik, useFormikContext } from "formik";
import { useGetUserDetails } from "../../../services/quoteService";
import { days } from "../../../utils/options";
import { useSelector } from "react-redux";

const SelectDays = () => {
  const { handleChange, values, errors } = useFormikContext();
  const details = useGetUserDetails();
  const { leadData } = useSelector((state) => state.landing);
  return (
    <>
      <Formik
        initialValues={{
          max_annual_travel_days:
            +details?.userDetails?.max_annual_travel_days ||
            leadData?.max_annual_travel_days ||
            30,
        }}
      >
        <Grid container spacing={1}>
          {days.map((day) => (
            <Grid item xs={3} md={3} key={day.code}>
              <input
                className="custom-radio"
                type="radio"
                name="max_annual_travel_days"
                value={day.code}
                id={`days${day.code}`}
                checked={+values["max_annual_travel_days"] === +day.code}
                onChange={handleChange}
              />
              <label htmlFor={`days${day.code}`}>{day.display_name}</label>
            </Grid>
          ))}
          {errors["max_annual_travel_days"] && (
            <FormHelperText
              sx={{
                color: "red",
                marginLeft: "10px",
              }}
            >
              {errors["max_annual_travel_days"]}
            </FormHelperText>
          )}
        </Grid>
      </Formik>
    </>
  );
};
export default SelectDays;
