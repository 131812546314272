/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import QuoteFilter from "./components/QuoteFilter/QuoteFilter";
import QuoteCard from "./components/QuoteCard/QuoteCard";
import {
  useAddShortlisted,
  useGetPlans,
  useGetQuotes,
} from "../../services/quoteService";
import { useSelector } from "react-redux";
import ComparePopup from "./components/ComparePopup";
import ShareQuotesModal from "./components/ShareQuotesModal/ShareQuotesModal";
import CanvasQuotes from "./components/ShareQuotesModal/CanvasQuotes";
import ShortlistedCard from "./components/ShortlistedCard/ShortlistedCard";
import LandingBg from "../../assets/images/LandingBg.png";
import MobileFooter from "./components/MobileFooter";
import book from "../../../src/assets/images/book.png";
import QuoteContainer from "./components/QuoteCard/components/QuoteContainer";
import { useEffect } from "react";
import { toast } from "react-toastify";

const QuotesPage = () => {
  const { isPlanLoading } = useGetPlans();
  const { quotes, count, isAnyQuoteLoading, sortedKeys } = useGetQuotes();
  const { show_more_quotes, shortListedQuotes } = useSelector(
    (state) => state.quote
  );
  const {
    filters: { sortBy },
  } = useSelector((state) => state.landing);
  const enquiry_id = useSelector(
    (state) => state?.landing?.leadData?.enquiry_id
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const compareQuote = useSelector((state) => state.quote.compareQuote);
  const isHighToLow = sortBy === "High To Low";
  const { addShortlistedPlan } = useAddShortlisted();

  useEffect(() => {
    addShortlistedPlan({
      enquiry_id,
      products: shortListedQuotes.map((item) => ({
        company_alias: item.ic_data[0].company_alias,
        product_id: item.data[0].plan_id,
        ic_data: item.ic_data[0].id,
        plan_name: item.data[0]?.plan_name,
      })),
    });
  }, [shortListedQuotes]);

  return (
    <>
      <Box
        sx={{
          p: isMobile ? 0 : 4,
          pl: isMobile ? 0 : 5,
          pr: isMobile ? 0 : 5,
          mb: compareQuote[0] ? 18 : 6,
          minHeight: "100vh",
          backgroundImage: {
            xs: "none",
            sm: `url(${LandingBg})`,
          },
          backgroundPosition: "bottom",
          backgroundRepeat: "repeat-x",
          pb: isMobile ? 3 : 0,
        }}
      >
        <Grid justifyContent={"center"} container>
          <Grid item xs={12} md={9.3}>
            <Stack sx={{ mr: isMobile ? 0 : 0 }} spacing={2}>
              <Box sx={{ ml: isMobile ? -0.5 : 3, pr: isMobile ? 1 : 3 }}>
                <QuoteFilter />
              </Box>
              <Stack
                sx={{
                  pl: isMobile ? 2 : 4,
                  pr: isMobile ? 2 : 2,
                }}
                justifyContent="space-between"
                spacing={2}
                direction="row"
              >
                {isMobile ? (
                  <Typography variant="h6" fontSize={"1rem"} fontWeight={600}>
                    CRN / Trace ID :
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(enquiry_id);
                        toast.success(
                          <div style={{ fontSize: ".8rem", fontWeight: "600" }}>
                            Copied to clipboard
                          </div>
                        );
                      }}
                      sx={{ m: 0, p: 0 }}
                      variant="text"
                      disableRipple
                    >
                      <Typography
                        color={"primary"}
                        variant="h6"
                        fontSize={"1rem"}
                        fontWeight={600}
                      >
                        {enquiry_id}
                      </Typography>
                    </Button>
                  </Typography>
                ) : (
                  <Typography
                    variant="h3"
                    fontSize={isTablet ? "16px" : isMobile ? "10px" : "12px"}
                    fontWeight={500}
                    fontStyle="italic"
                  >
                    Showing {count} plans
                  </Typography>
                )}
                <Stack>
                  <Typography
                    variant="h3"
                    fontSize={isTablet ? "16px" : isMobile ? "10px" : "12px"}
                    fontWeight={600}
                    fontStyle="italic"
                    sx={{ mb: isMobile ? "6px" : 0, pr: 1 }}
                  >
                    *Prices Inclusive Of GST
                  </Typography>
                  {isMobile && (
                    <Typography
                      variant="h3"
                      fontSize={isTablet ? "16px" : isMobile ? "10px" : "12px"}
                      fontWeight={500}
                      fontStyle="italic"
                    >
                      Showing {count} plans
                    </Typography>
                  )}
                </Stack>
              </Stack>
              <Stack spacing={-1}>
                {!count && !isPlanLoading && !isAnyQuoteLoading ? (
                  <div style={{ marginLeft: 10, paddingLeft: 10 }}>
                    No Plans Found
                  </div>
                ) : (
                  <Stack
                    sx={{
                      ml: isMobile ? 0 : 4,
                      pl: isMobile ? 2 : 1.5,
                      pr: isMobile ? 2 : 1.5,
                    }}
                  >
                    {sortedKeys.map((key, index) => (
                      <>
                        <QuoteContainer
                          isLoading={isAnyQuoteLoading}
                          recommended={index === 0}
                          quoteData={quotes[key][0]?.quoteData}
                        >
                          {quotes[key]
                            .sort((a, b) =>
                              isHighToLow
                                ? b.quoteData.data[0]?.total_premium_amount -
                                  a.quoteData.data[0]?.total_premium_amount
                                : a.quoteData.data[0]?.total_premium_amount -
                                  b.quoteData.data[0]?.total_premium_amount
                            )
                            .map((quote, idx) => (
                              <>
                                <QuoteCard
                                  index={idx}
                                  count={count}
                                  quoteData={quote.quoteData}
                                  isLoading={quote.isQuoteLoading}
                                  showMorePlans={
                                    quotes[key].length > 1 && idx === 0
                                  }
                                  isVisible={
                                    show_more_quotes[
                                      quote?.quoteData?.ic_data[0]?.id
                                    ] || idx === 0
                                  }
                                  recommended={idx === 0 && index === 0}
                                  quoteLength={quotes[key].length}
                                  key={quote.quoteData.data[0]?.plan_name}
                                />
                              </>
                            ))}
                        </QuoteContainer>
                      </>
                    ))}
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Grid>
          {isMobile ? null : (
            <Grid item md={2.7}>
              <Stack
                sx={{ mr: 4 }}
                justifyContent="center"
                alignItems="center"
                spacing={4}
              >
                <Box
                  sx={{
                    fontSize: "12px",
                    border: "1.5px solid rgba(165, 176, 250, 0.5)",
                    borderRadius: "10px",
                    p: 1.5,
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="text"
                    color={"#000"}
                    fontWeight={600}
                    sx={{ mx: 1 }}
                  >
                    CRN / Trace ID :
                  </Typography>
                  <Typography
                    variant="text"
                    color={"var(--primary-color)"}
                    fontWeight={600}
                  >
                    {enquiry_id}
                  </Typography>
                  <i
                    onClick={() => {
                      navigator.clipboard.writeText(enquiry_id);
                      toast.success(
                        <div style={{ fontSize: ".8rem", fontWeight: "600" }}>
                          Copied to clipboard
                        </div>
                      );
                    }}
                    variant="text"
                    disableRipple
                    style={{
                      color: "#000",
                      display: "inline",
                      marginLeft: "8px",
                      cursor: "pointer",
                    }}
                    className="far fa-copy"
                  />
                </Box>
                <ShortlistedCard />
                <Card
                  sx={{
                    border: "1.5px solid rgba(165, 176, 250, 0.5)",
                    borderRadius: "10px",
                    boxShadow: 0,
                    p: 1,
                    pl: 2,
                    pb: 2,
                  }}
                >
                  <Stack
                    alignItems={"center"}
                    direction={"row"}
                    justifyContent="space-between"
                  >
                    <Typography
                      sx={{ mt: -0.3 }}
                      variant="h6"
                      fontSize="14px"
                      color={"var(--primary-color)"}
                      mt={1}
                      fontWeight={600}
                    >
                      What is Travel Insurance?{" "}
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      <img
                        src={book}
                        width={"21px"}
                        height={"19px"}
                        alt="book"
                      />
                    </Box>
                  </Stack>
                  <Typography
                    color="#6d6d95"
                    variant="body1"
                    fontSize="12px"
                    mt={1}
                    fontWeight={500}
                  >
                    Travel insurance is coverage designed to protect against
                    risks and financial losses that could happen while
                    traveling. The risks range from minor inconveniences such as
                    missed airline connections and delayed luggage all the way
                    to more serious issues including injuries or major illness.{" "}
                  </Typography>
                </Card>
              </Stack>
            </Grid>
          )}
          {isMobile && (
            <Box
              sx={{
                position: "fixed",
                bottom: 0,
                right: 0,
                left: 0,
              }}
            >
              <MobileFooter />
            </Box>
          )}
        </Grid>
      </Box>
      {compareQuote[0]?.ic_data && <ComparePopup />}
      <ShareQuotesModal />
      <CanvasQuotes />
    </>
  );
};

export default QuotesPage;
