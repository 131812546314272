import { Box } from "@mui/material";
import { Verified } from "@mui/icons-material";
import styled, { keyframes } from "styled-components";
const CkycVerified = ({ innerText }) => {
  return (
    <Box
      sx={{
        margin: "2rem 1rem 0rem",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <ZoomAnimationStyled sx={{ fontSize: "60px", color: "#22a404" }} />
      <h6 style={{ fontSize: "1.2rem", paddingTop: "1rem" }}>{innerText}</h6>
    </Box>
  );
};

export default CkycVerified;

const scaleUp = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.25);
  }
`;

const ZoomAnimationStyled = styled(Verified)`
  animation: ${scaleUp} 1s infinite alternate;
`;
