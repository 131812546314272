import {
  AppBar,
  Box,
  Button,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import Modal from "../Modal";
import TalkToExpert from "../Popups/TalkToExpert";
import { Route, Routes, useNavigate } from "react-router-dom";
import SendQuoteB from "../../pages/QuotesPage/components/header/SendQuoteB";
import HeaderData from "../../pages/QuotesPage/components/header/HeaderData";
import { useDispatch, useSelector } from "react-redux";
import { setEditMode, setEditOpen } from "../../modules/quote.slice";
import EditDetails from "../../components/TravelEdit/EditDetails";
import { Headphones } from "@mui/icons-material";
import SendCompareB from "../../pages/ComparePage/components/SendCompareB";
import { useGetFrontendBoot } from "../../services/landingService";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";
import { setActiveStep } from "../../modules/landing.slice";
const logoPath = require(`../../assets/images/${process.env.REACT_APP_TENANT}Logo.png`);

const Header = () => {
  const { settings } = useGetFrontendBoot();
  const enquiryId = useSelector(
    (state) => state?.landing?.leadData?.enquiry_id
  );
  const [openTte, setOpenTte] = useState(false);
  const editShow = useSelector((state) => state.quote.show_edit_quote);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const openEdit = () => dispatch(setEditOpen(true));
  const closeEdit = () => dispatch(setEditOpen(false));
  const handleClickOpenTte = () => setOpenTte(true);
  const handleCloseTte = () => setOpenTte(false);
  const closeEditModal = () => {
    dispatch(setEditOpen(false));
    dispatch(setEditMode("default"));
  };
  return (
    <>
      <AppBar
        sx={{
          boxShadow: "none",
          borderBottom: isMobile ? "none" : "2px solid var(--primary-light)",
          paddingRight: "0",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box>
            <img
              src={logoPath || settings?.logo}
              style={{
                cursor: "pointer",
                paddingLeft: isMobile ? "0px" : "30px",
              }}
              onClick={() => {
                navigate("/");
                dispatch(setActiveStep(0));
              }}
              width={isMobile ? "124px" : "200px"}
              alt="Logo"
            />
          </Box>
          <Box sx={{ paddingRight: isMobile ? "0px" : "22px" }}>
            <Stack direction="row" spacing={-4}>
              <Routes>
                <Route
                  exact
                  path="/quote"
                  element={
                    <Toolbar>
                      <HeaderData onClick={openEdit} />
                    </Toolbar>
                  }
                />
              </Routes>
              {!isMobile && (
                <Routes>
                  <Route
                    exact
                    path="/compare"
                    element={
                      <Toolbar>
                        <SendCompareB />
                      </Toolbar>
                    }
                  />
                </Routes>
              )}

              {/* Send Quote */}
              {isMobile ? null : (
                <Routes>
                  <Route
                    exact
                    path="/quote"
                    element={
                      <>
                        <Toolbar>
                          <Button
                            sx={{ height: "40px", textTransform: "capitalize" }}
                            onClick={handleClickOpenTte}
                            variant="outlined"
                          >
                            <Stack
                              spacing={0.5}
                              direction={"row"}
                              alignItems={"center"}
                            >
                              <Headphones />
                              <span>Talk To Expert</span>
                            </Stack>
                          </Button>
                        </Toolbar>
                        <SendQuoteB />
                      </>
                    }
                  />
                  <Route
                    exact
                    path="/shortlisted-quotes"
                    element={<SendQuoteB />}
                  />
                  <Route
                    exact
                    path="/proposal"
                    element={
                      <Toolbar>
                        <Button
                          sx={{ height: "40px", textTransform: "capitalize" }}
                          onClick={() => {
                            navigator.clipboard.writeText(enquiryId);
                            toast.success(
                              <div
                                style={{ fontSize: ".8rem", fontWeight: "600" }}
                              >
                                Copied to clipboard
                              </div>
                            );
                          }}
                          variant="outlined"
                        >
                          <Stack
                            spacing={0.5}
                            direction={"row"}
                            alignItems={"center"}
                          >
                            <Typography
                              sx={{
                                fontSize: ".8rem",
                                marginRight: "10px",
                                color: "black",
                                display: "flex",
                              }}
                            >
                              CRN / Trace ID :{" "}
                              <Typography
                                sx={{
                                  fontSize: ".8rem",
                                  marginLeft: "10px",
                                  color: "var(--primary-color)",
                                  display: "flex",
                                }}
                              >
                                {enquiryId}
                              </Typography>
                            </Typography>
                            <ContentCopyIcon
                              sx={{
                                fontSize: "1rem",
                                marginLeft: "10px",
                                color: "grey",
                              }}
                            />
                          </Stack>
                        </Button>
                      </Toolbar>
                    }
                  />
                </Routes>
              )}
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>

      <Modal open={openTte} handleClose={handleCloseTte} maxWidth={"400px"}>
        <TalkToExpert />
      </Modal>
      <Modal maxWidth={"900px"} open={editShow} handleClose={closeEditModal}>
        <EditDetails handleClose={closeEdit} />
      </Modal>
    </>
  );
};

export default Header;
