/* eslint-disable eqeqeq */
import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useUrlQuery from "../../hooks/useUrlQuery";

export default function ViewDetailsC({ handleClose }) {
  const { know_more_data } = useSelector((state) => state.quote);
  const { data, ic_data } = know_more_data;
  const navigate = useNavigate();
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");

  return (
    <Box>
      <IconButton
        sx={{
          position: "absolute",
          top: 3,
          right: 3,
        }}
      >
        <CloseOutlined sx={{ width: "15px" }} onClick={handleClose} />
      </IconButton>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent="space-between"
        sx={{ p: 1 }}
      >
        <Box>
          <img src={ic_data[0].logo} alt="" width="50px" />
        </Box>
        <Box>
          <Typography fontSize={"12px"} fontWeight={700}>
            {data[0]?.plan_name}
          </Typography>
        </Box>
      </Stack>
      <Divider flexItem />
      <Stack spacing={1} sx={{ p: 1 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.7)" }}>
            Medical Expenses
          </Typography>
          <Typography
            sx={{ fontSize: "12px", fontWeight: "600", color: "#000000" }}
          >
            ${data && data[0]?.plan_feature[0]?.feature_values[8]?.sum_insured}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.7)" }}>
            Baggage Loss
          </Typography>
          <Typography
            sx={{ fontSize: "12px", fontWeight: "600", color: "#000000" }}
          >
            ${data && data[0]?.plan_feature[0]?.feature_values[7]?.sum_insured}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.7)" }}>
            Loss of Passport
          </Typography>
          <Typography
            sx={{ fontSize: "12px", fontWeight: "600", color: "#000000" }}
          >
            ${data && data[0]?.plan_feature[0]?.feature_values[1]?.sum_insured}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.7)" }}>
            PED Covered
          </Typography>
          <Typography
            sx={{ fontSize: "12px", fontWeight: "600", color: "#000000" }}
          >
            {ic_data && ic_data[0]?.pedCovered == 1 ? "Yes" : "No"}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        sx={{
          p: 1,
          border: "1px solid var(--primary-color)",
          borderRadius: "5px",
        }}
        direction="row"
        alignItems={"center"}
        justifyContent="space-between"
      >
        <Typography sx={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.7)" }}>
          Total Premium Payable
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#000000",
            whiteSpace: "nowrap",
          }}
        >
          <span style={{ fontFamily: "Inter" }}>₹</span>{" "}
          {data && data[0].total_premium_amount}
        </Typography>
      </Stack>
      <Button
        sx={{
          p: 1,
          borderRadius: "6px",
          mt: 3,
          mb: 2,
          textTransform: "none",
          fontSize: "14px",
        }}
        color="primary"
        variant="contained"
        onClick={() => navigate(`/proposal?enquiry_id=${enquiry_id}`)}
        fullWidth
      >
        Continue
      </Button>
    </Box>
  );
}
