import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

export function QuoteButton({ onClick, children }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const configButton = {
    onClick: onClick,
    variant: "contained",
    // color: "secondary",
    fullWidth: true,
    size: "md",
    style: {
      width: isMobile ? "100%" : "120px",
      textTransform: "none",
      fontWeight: "500",
      fontSize: "25px",
      padding: isTablet ? "12px" : isMobile ? "3px" : "7px",
      fontStyle: "normal",
      borderRadius: isMobile ? "5px" : "12px",
      pl: isMobile ? 0 : 2,
      pr: isMobile ? 0 : 2,
      pt: isMobile ? 2 : 0,
      pb: isMobile ? 2 : 0,
      boxShadow: "none",
      backgroundColor: "var(--primary-color)",
    },
  };

  return (
    <Box
      sx={{ mt: isMobile ? 1.8 : 2, boxShadow: "none" }}
      display="flex"
      justifyContent="center"
    >
      <Grid
        sx={{ paddingLeft: isMobile ? 3 : 2, boxShadow: "none" }}
        spacing={2}
        container
      >
        <Button {...configButton}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: isMobile ? "6px" : "11px",
                color: "#fff",
                display: isMobile ? "none" : "block",
                mb: 1,
              }}
              variant="body1"
            >
              Buy Now
            </Typography>
            <Typography
              sx={{
                mt: isMobile ? "4px" : "0px",
                fontSize: isTablet ? "16px" : isMobile ? "11px" : "17px",
                color: "#fff",
              }}
              variant="h1"
            >
              {children}
            </Typography>
          </Grid>
        </Button>
        <Grid item xs={12}></Grid>
      </Grid>
    </Box>
  );
}
