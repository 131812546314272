export const getAgeInRange = (min, max) => {
  const age = [];
  for (let i = min; i <= max; i++) {
    age.push({
      code: i,
      // display_name: `${i} years`,
      display_name: `${i === 1 ? `${i} year` : `${i} years`}`,
    });
  }
  return age;
};
