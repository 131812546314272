import moment from "moment";
import { relations } from "./options";

export const allowOnlyNumbers = (e) => {
  e.target.value = e.target.value.replace(/[^0-9]/g, "");
};
export const upperCase = (e) => {
  e.target.value = e.target.value.toUpperCase();
};
export const allowOnlyAlphabets = (e) => {
  e.target.value = e.target.value.replace(/[^a-zA-Z]/g, "");
};
export const capitalize = (e) => {
  e.target.value =
    e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
};
export const allowOnlyAlphabetsWithOneSpace = (e) => {
  e.target.value = e.target.value.replace(/[^a-zA-Z]+/g, " ");
};
export const getAgeByMember = (details, member) => {
  if (member === "self") {
    return details.proposer_age;
  }
  return details.member_age_details[member + "_age"];
};
export const allowFullName = (e) => {
  e.target.value = e.target.value
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  const consequentSpace = /(\s{2,})/g;
  e.target.value = e.target.value.replace(consequentSpace, " ");
  e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, "");
};
export const getMinDateByAge = (age) => {
  const minDate = moment().subtract(age + 1, "years");
  return minDate;
};
export const getMaxDateByAge = (age) => {
  const maxDate = moment().subtract(age, "years").subtract(1, "days");
  return maxDate;
};
export const getRelationById = (member, relationId) => {
  return relations?.[member]?.find((r) => r.code === relationId)?.display_name;
};
