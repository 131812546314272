import { Card, Grid, Container, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import thankYouLogo from "../../assets/images/thankyoulogo.png";
import TYCard from "./TYCard";

export default function ThankYouPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  if (isMobile) {
    return (
      <Card sx={{ backgroundColor: "#F0F4F5", maxHeight: "100%" }}>
        <Grid
          sx={{ mt: 2 }}
          justifyContent="center"
          alignItems="center"
          container
          spacing={1}
        >
          <Grid item md={6}>
            <TYCard />
          </Grid>
          <Grid item md={5.5}>
            <Container>
              <img
                width="252px"
                height="171px"
                style={{ paddingLeft: 10 }}
                src={thankYouLogo}
                alt="thankYouLogo"
              />
            </Container>
          </Grid>
        </Grid>
      </Card>
    );
  }

  return (
    <Card sx={{ backgroundColor: "#F0F4F5", height: "650px" }}>
      <Grid
        sx={{ mt: 7 }}
        justifyContent="center"
        alignItems="center"
        container
        spacing={1}
      >
        <Grid item md={5.5}>
          <Container sx={{ pt: 12, pb: 12.5, ml: 10 }}>
            <img width="524px" src={thankYouLogo} alt="thankYouLogo" />
          </Container>
        </Grid>
        <Grid item md={6}>
          <TYCard />
        </Grid>
      </Grid>
    </Card>
  );
}
