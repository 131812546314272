import React from "react";
import {
  Box,
  Container,
  Divider,
  InputLabel,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ButtonWrapper from "../InputComponents/ButtonWrapper";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setInsurers } from "../../modules/landing.slice";
import FilterCheck from "../../pages/QuotesPage/components/QuoteFilter/Filters/FilterCheck";
import { useGetQuotes, useGetUserDetails } from "../../services/quoteService";

export default function SelectInsurer({ handleClose }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { userDetails } = useGetUserDetails();
  const {
    filters: { insurers },
  } = useSelector((state) => state.landing);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const { quotes } = useGetQuotes();
  const quote_ids = Object.keys(quotes);
  return (
    <Box sx={{ width: isMobile ? "100%" : "484px" }}>
      <Title>Select Insurer</Title>
      <Formik
        initialValues={{
          insurers: insurers || [],
        }}
        onSubmit={(values) => {
          dispatch(setInsurers(values.insurers));
          // queryClient.invalidateQueries("plans");
          handleClose();
        }}
      >
        <Box>
          <Container sx={{ mt: 2 }}>
            {quote_ids?.map((item) =>
              quotes[item][0].quoteData?.ic_data[0]?.name ? (
                <>
                  <Stack
                    sx={{ width: "100%" }}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <Box
                      sx={{
                        width: "30%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={
                          userDetails.company_details[
                            quotes[item][0].quoteData?.ic_data[0]?.company_alias
                          ]?.logo
                        }
                        width="30px"
                        height="auto"
                        alt=""
                        style={{
                          maxWidth: "70px",
                        }}
                      />
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <InputLabel
                        sx={{
                          fontSize: isTablet
                            ? "16px"
                            : isMobile
                            ? "12px"
                            : "14px",
                          mb: 0,
                        }}
                      >
                        {quotes[item][0].quoteData?.ic_data[0]?.name}
                      </InputLabel>
                    </Box>
                    <Box sx={{ width: "20%" }}>
                      <FilterCheck
                        name="insurers"
                        value={quotes[item][0].quoteData?.ic_data[0]?.id}
                      />
                    </Box>
                    <Divider flexItem />
                  </Stack>
                </>
              ) : null
            )}
          </Container>
          <ButtonWrapper>Apply</ButtonWrapper>
        </Box>
      </Formik>
    </Box>
  );
}

const Title = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Stack flexDirection="row">
      <Box
        sx={{
          width: "2%",
          border: "2px",
          backgroundColor: "rgba(58, 116, 173, 0.22)",
        }}
      ></Box>
      <Box
        sx={{
          width: "98%",
          backgroundImage: "linear-gradient(98deg, #E8E8E8, white)",
          pl: 2,
          pt: 1,
        }}
      >
        <Typography
          sx={{
            fontSize: isMobile ? "16px" : "20px",
            fontWeight: "bold",
            color: "var(--primary-color)",
          }}
          variant="h6"
          gutterBottom
        >
          {children}
        </Typography>
      </Box>
    </Stack>
  );
};
