import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import styled from "styled-components";

const CanvasQuotes = () => {
  const { quotesToShare } = useSelector((state) => state.quote);

  const theme = useTheme();

  return (
    <Canvas>
      <div id="share-quotes" className="canvas">
        <ShareQuotesHeader color={theme.palette.primary.main}>
          <ShareQuotesHeaderItem>Insurer</ShareQuotesHeaderItem>
          <ShareQuotesHeaderItem>Premium</ShareQuotesHeaderItem>
          <ShareQuotesHeaderItem>Cover</ShareQuotesHeaderItem>
          <ShareQuotesHeaderItem>Proceed</ShareQuotesHeaderItem>
        </ShareQuotesHeader>
        {quotesToShare?.map((quote, index) => (
          <CanvasQuoteTemplate key={index} quote={quote} />
        ))}
      </div>
    </Canvas>
  );
};

const CanvasQuoteTemplate = ({ quote }) => {
  const theme = useTheme();
  const { data, ic_data } = quote;
  return (
    <ShareQuoteItemCanvas color={theme.palette.primary.main} shade={"#fff"}>
      <Plan>
        <img
          className="insurer"
          src={ic_data[0].logo}
          alt="quote"
          crossOrigin={true}
        />
        <div className="plan">{data[0]?.plan_name}</div>
      </Plan>
      <Premium color={theme.palette.primary.main}>
        ₹ {data[0].total_premium_amount?.toLocaleString("en-IN")}
      </Premium>
      <Premium>$ {data[0].sum_insured?.toLocaleString("en-IN")}</Premium>
      <Proceed color={theme.palette.primary.main}>Proceed</Proceed>
    </ShareQuoteItemCanvas>
  );
};

const Canvas = styled.div`
  width: 600px;
  position: absolute;
  left: -99999px;
  z-index: 9999999;
`;

const Proceed = styled.div`
  background: ${({ color }) => color};
  color: #fff;
  padding: 6px 5px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  text-align: center !important;
`;
const ShareQuotesHeader = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${(props) => props.cols || "3fr 1fr 1fr 1fr"};
  background: ${(props) => props.color || "#eee"};
  padding: 8px;
  color: ${({ color }) => (color ? "#fff" : "#000")};
  & > * {
    text-align: left;
  }
  @media (max-width: 768px) {
    gap: 10px;
    grid-template-columns: 2.7fr 1fr 1fr 1fr;
  }
`;

const ShareQuotesHeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  gap: 7px;
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

const ShareQuoteItem = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.cols || "3fr 1fr 1fr 1fr"};
  gap: ${(props) => props.gap || "0"};
  padding: 8px;
  border-bottom: 1px solid #dcdcdc;
  align-items: center;
  & > * {
    text-align: left;
  }
  & .plan {
    font-size: 12px;
    @media (max-width: 768px) {
      font-size: 11px;
    }
  }
  & .cover {
    font-size: 14px;
    color: ${(props) => props.color};
    font-weight: bold;
    @media (max-width: 768px) {
      font-size: 11px;
    }
  }
  & .insurer {
    height: 30px;
    max-width: 60px;
    margin-right: 5px;
  }
`;

const ShareQuoteItemCanvas = styled(ShareQuoteItem)`
  grid-template-columns: 3fr 1fr 1fr 1fr;
  gap: 10px;
  border: 1px solid ${({ color }) => color};
  background: ${({ shade }) => shade};
  align-items: center;
  margin: 2px 0;
  & .insurer {
    border: 1px solid ${({ color }) => color};
    padding: 2px;
    border-radius: 5px;
    background: #fff;
  }
`;

const Plan = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
`;

const Premium = styled.div`
  color: ${(props) => props.color};
  font-weight: bold;
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

export default CanvasQuotes;
