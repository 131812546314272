import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import ButtonWrapper from "../InputComponents/ButtonWrapper";
import CustomRadio from "../InputComponents/CustomRadio";
import { setSumInsurer } from "../../modules/landing.slice";
import { useTheme } from "@emotion/react";
import { usePatchQuote } from "../../services/landingService";
import useUrlQuery from "../../hooks/useUrlQuery";

export default function SumInsuredPop({ handleClose }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { patchQuote } = usePatchQuote();

  return (
    <Box sx={{ maxWidth: "450px" }}>
      <Title>Select Your Sum Insured</Title>
      <Formik
        initialValues={{
          sum_insured: "",
        }}
        onSubmit={(values) => {
          dispatch(setSumInsurer(values.sum_insured));
          patchQuote({
            sum_insured: values.sum_insured,
            enquiry_id,
          });
          handleClose();
        }}
      >
        <Box>
          <CustomRadio name="sum_insured" value="50000">
            <Typography
              sx={{
                fontSize: isTablet ? "16px" : isMobile ? "12px" : "15px",
                color: "#8282a1",
              }}
              variant="body1"
              gutterBottom
            >
              $ 50000
            </Typography>
          </CustomRadio>
          <CustomRadio name="sum_insured" value="100000">
            <Typography
              sx={{
                fontSize: isTablet ? "16px" : isMobile ? "12px" : "15px",
                color: "#8282a1",
              }}
              variant="body1"
              gutterBottom
            >
              $ 100000
            </Typography>
          </CustomRadio>
          <CustomRadio name="sum_insured" value="200000">
            <Typography
              sx={{
                fontSize: isTablet ? "16px" : isMobile ? "12px" : "15px",
                color: "#8282a1",
              }}
              variant="body1"
              gutterBottom
            >
              $ 200000
            </Typography>
          </CustomRadio>
          <CustomRadio name="sum_insured" value="250000">
            <Typography
              sx={{
                fontSize: isTablet ? "16px" : isMobile ? "12px" : "15px",
                color: "#8282a1",
              }}
              variant="body1"
              gutterBottom
            >
              $ 250000
            </Typography>
          </CustomRadio>
          <CustomRadio name="sum_insured" value="300000">
            <Typography
              sx={{ fontSize: isTablet ? "16px" : "12px", color: "#8282a1" }}
              variant="body1"
              gutterBottom
            >
              $ 300000
            </Typography>
          </CustomRadio>
          <CustomRadio name="sum_insured" value="500000">
            <Typography
              sx={{
                fontSize: isTablet ? "16px" : isMobile ? "12px" : "15px",
                color: "#8282a1",
              }}
              variant="body1"
              gutterBottom
            >
              $ 500000
            </Typography>
          </CustomRadio>
          <ButtonWrapper>Apply</ButtonWrapper>
        </Box>
      </Formik>
    </Box>
  );
}

const Title = ({ children }) => {
  return (
    <Stack flexDirection="row">
      <Box
        sx={{
          width: "2%",
          border: "2px",
          backgroundColor: "rgba(58, 116, 173, 0.22)",
        }}
      ></Box>
      <Box
        sx={{
          width: "98%",
          backgroundImage: "linear-gradient(98deg, #E8E8E8, white)",
          pl: 2,
          pt: 1,
        }}
      >
        <Typography variant="h6" gutterBottom>
          {children}
        </Typography>
      </Box>
    </Stack>
  );
};
