import { Box, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../components/Modal";
import SelectInsurer from "../../../../components/Popups/SelectInsurer";
import SortBt from "../../../../components/Popups/SortBt";
import SumInsuredPop from "../../../../components/Popups/SumInsuredPop";
import {
  setInsurerOpen,
  setSumOpen,
  setSortOpen,
} from "../../../../modules/quote.slice";
import { useGetQuotes } from "../../../../services/quoteService";
import Insurer from "./Filters/Insurer";
import PlanType from "./Filters/PlanType";
import SortBy from "./Filters/SortBy";
import SumInsured from "./Filters/SumInsured";
import { toast } from "react-toastify";
export default function QuoteFilter() {
  const insurerShow = useSelector((state) => state.quote.show_insurer);
  const sortShow = useSelector((state) => state.quote.show_sort_by);
  const sumShow = useSelector((state) => state.quote.show_sum_insurer);
  const dispatch = useDispatch();

  const sum = useSelector((state) => state.landing.filters.sum_insured);
  const sort = useSelector((state) => state.landing.filters.sortBy);
  const plan = useSelector((state) => state.landing.filters.planType);
  const { isAnyQuoteLoading } = useGetQuotes();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const handleClickOpenInsurer = () => {
    if (isAnyQuoteLoading) {
      toast.info(
        <div style={{ fontSize: ".8rem" }}>
          Few Quotes are loading. Please wait...
        </div>
      );
      return;
    }
    dispatch(setInsurerOpen(true));
  };
  const handleCloseInsurer = () => {
    dispatch(setInsurerOpen(false));
  };
  const handleClickOpenSum = () => {
    dispatch(setSumOpen(true));
  };
  const handleCloseSum = () => {
    dispatch(setSumOpen(false));
  };
  const handleClickOpenSort = () => {
    dispatch(setSortOpen(true));
  };
  const handleCloseSort = () => {
    dispatch(setSortOpen(false));
  };
  if (isMobile) {
    return (
      <Box
        sx={{
          marginLeft: isMobile ? "0px" : "32px",
          paddingRight: isMobile ? "12px" : "8px",
        }}
      >
        <Box sx={{ borderRadius: "0px 100px 100px 0px", p: 0.5 }}>
          <Grid
            container
            sx={{
              boxShadow: isMobile ? "1px 3px 5px 0px #00000036" : "",
              marginLeft: isMobile ? "13px" : "",
              marginTop: isMobile ? "5px" : "",
              borderRadius: isMobile ? "1px" : "",
              paddingTop: isMobile ? "6px" : "",
              paddingBottom: isMobile ? "6px" : "",
              width: isTablet ? "99%" : isMobile ? "98%" : "",
            }}
          >
            <Grid item xs={3.8}>
              <Insurer
                subtitle={"Select"}
                handleClick={handleClickOpenInsurer}
                divider
              />
            </Grid>
            <Grid item xs={3.8}>
              <SumInsured handleClick={handleClickOpenSum} subtitle={sum} />
            </Grid>
            <Grid item xs={4.4}>
              <PlanType subtitle={plan} handleClick={handleClickOpenSort} />
            </Grid>
          </Grid>
        </Box>
        <Modal
          maxWidth={isTablet ? "444px" : "484px"}
          open={insurerShow}
          handleClose={handleCloseInsurer}
        >
          <SelectInsurer handleClose={handleCloseInsurer} />
        </Modal>
        <Modal maxWidth={"484px"} open={sortShow} handleClose={handleCloseSort}>
          <SortBt handleClose={handleCloseSort} />
        </Modal>
        <Modal
          maxWidth={isTablet ? "444px" : "484px"}
          open={sumShow}
          handleClose={handleCloseSum}
        >
          <SumInsuredPop handleClose={handleCloseSum} />
        </Modal>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ pt: 0 }}>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{
            border: "1px solid #E4E4E4",
            marginLeft: "10px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Insurer
              label="Insurer"
              subtitle={"Select "}
              handleClick={handleClickOpenInsurer}
              divider
            />
            {/* <Divider flexItem orientation='vertical' /> */}
            <SumInsured subtitle={sum} divider />
            <PlanType subtitle={plan} />
            {/* <Divider flexItem orientation='vertical' /> */}
            <SortBy subtitle={sort} />
          </Box>
        </Stack>
      </Box>
      <Modal
        maxWidth={"484px"}
        open={insurerShow}
        handleClose={handleCloseInsurer}
      >
        <SelectInsurer handleClose={handleCloseInsurer} />
      </Modal>
    </Box>
  );
}
