export const days = [
  { code: "30", display_name: "30 days" },
  { code: "45", display_name: "45 days" },
  { code: "60", display_name: "60 days" },
  { code: "90", display_name: "90 days" },
];
export const relations = {
  self: [{ code: "1", display_name: "Self" }],
  spouse: [{ code: "2", display_name: "Spouse" }],
  child_1: [
    { code: "3", display_name: "Son" },
    { code: "4", display_name: "Daughter" },
  ],
  child_2: [
    { code: "3", display_name: "Son" },
    { code: "4", display_name: "Daughter" },
  ],
  parent_1: [
    { code: "5", display_name: "Father" },
    { code: "6", display_name: "Mother" },
  ],
  parent_2: [
    { code: "5", display_name: "Father" },
    { code: "6", display_name: "Mother" },
  ],
  student: [
    { code: "1", display_name: "Self" },
    { code: "2", display_name: "Spouse" },
    { code: "3", display_name: "Son" },
    { code: "4", display_name: "Daughter" },
    { code: "5", display_name: "Father" },
    { code: "6", display_name: "Mother" },
    { code: "7", display_name: "Brother" },
    { code: "8", display_name: "Sister" },
  ],
  group: [
    { code: "1", display_name: "Self" },
    { code: "2", display_name: "Spouse" },
    { code: "3", display_name: "Son" },
    { code: "4", display_name: "Daughter" },
    { code: "5", display_name: "Father" },
    { code: "6", display_name: "Mother" },
  ],
  minor: [
    { code: "2", display_name: "Spouse" },
    { code: "3", display_name: "Son" },
    { code: "4", display_name: "Daughter" },
    { code: "5", display_name: "Father" },
    { code: "6", display_name: "Mother" },
  ],
};
export const salutations = [
  { code: "1", display_name: "Mr" },
  { code: "2", display_name: "Ms" },
  { code: "3", display_name: "Mrs" },
];
export const genderWithFullCode = [
  { code: "Male", display_name: "Male" },
  { code: "Female", display_name: "Female" },
];
export const genderWithShortCode = [
  { code: "M", display_name: "Male" },
  { code: "F", display_name: "Female" },
];
export const booleanOptions = [
  { code: "Y", display_name: "Yes" },
  { code: "N", display_name: "No" },
];
