import { createTheme } from "@mui/material";
// Get the root element
const rootStyles = getComputedStyle(document.documentElement);

// Get the CSS variable values
const primaryColor = rootStyles.getPropertyValue("--primary-color").trim();
const primaryShade = rootStyles.getPropertyValue("--primary-shade").trim();
const secondaryColor = rootStyles.getPropertyValue("--secondary-color").trim();
const theme = createTheme({
  palette: {
    primary: { main: primaryColor },
    secondary: { main: primaryShade },
    text: { primary: primaryColor, secondary: secondaryColor },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 800,
    h1: {
      fontSize: "40px",
      fontWeight: 800,
      lineHeight: "1.24",
      color: "#0a0938",
    },
    body1: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "1.24",
      color: "#0a0938",
    },
    h3: {
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "1.24",
      color: "#0a0938",
    },
    h4: {
      fontFamily: "Poppins",
      fontSize: "18px",
      fontWeight: 700,
      color: "#000000",
    },
    h5: {
      fontFamily: "Poppins",
      fontSize: "15px",
      fontWeight: "bold",
      color: "#0a0938",
    },
    subtitle1: {
      fontSize: "12px",
      position: "absolute",
    },
    caption: {
      fontSize: "12px",
      color: "#a1a1b6",
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        position: "relative",
      },
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          boxShadow: "0 3px 10px 0 rgba(58, 116, 173, 0.22)",
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: "500",
          fontSize: "14px",
          paddingLeft: "3px",
          marginBottom: "8px",
        },
        outlined: {
          fontSize: "14px",
          marginBottom: "0",
          marginTop: "4px",
          paddingLeft: 0,
          color: "#a1a1b6",

          "&.MuiInputLabel-shrink": {
            fontSize: "14px !important",
            marginTop: "0 !important",
          },
        },
      },
    },

    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        contained: {
          padding: "14px",
          borderRadius: "18px",
          fontSize: "18px",
        },
        text: {
          fontSize: "18px",
          fontWeight: "400",
          color: "primary",
          textTransform: "none",
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          color: "#a1a1b6",
          height: "60px",
        },
        input: {
          boxSizing: "border-box",
          padding: "19.5px 14px 14.5px",
        },
      },
    },

    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          color: "#a1a1b6",
          height: "60px",
          backgroundColor: "#fff",
          borderRadius: "4px",
          border: "1px solid rgba(0, 0, 0, 0.23)",
          "&:after,&:before": {
            display: "none",
          },
        },
        input: {
          boxSizing: "border-box",
          padding: "19.5px 14px 14.5px",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          width: "50%",
        },
      },
    },
  },
});

export default theme;
