import { RadioGroup, FormControlLabel, Radio, InputLabel } from "@mui/material";
import { useField, useFormikContext } from "formik";

const RadioB = ({ name, options = [], ...props }) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();
  const config = {
    ...field,
    ...props,
    onChange: (e, value) => {
      setFieldValue(name, value);
    },
  };

  return (
    <>
      <RadioGroup
        {...config}
        sx={{
          flexDirection: "row",
          mt: props.mt,
        }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.display_name}
            value={option.code}
            control={
              <Radio
                sx={{
                  color: "var(--primary-color)",
                  "&.Mui-checked": {
                    color: props?.readOnly
                      ? "rgba(0,0,0,0.3)"
                      : "var(--primary-color)",
                  },
                }}
                disabled={props?.readOnly}
              />
            }
            label={option.display_name}
            sx={{
              mt: "-8px",
              ml: "-8px",
              "& .MuiFormControlLabel-label": {
                fontSize: "14px",
                mt: "2px",
                color: "var(--primary-color)",
              },
            }}
          />
        ))}
      </RadioGroup>
      <InputLabel>{props.label}</InputLabel>
    </>
  );
};
export default RadioB;
