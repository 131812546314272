/* eslint-disable eqeqeq */
import {
  Button,
  FormHelperText,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import SchoolIcon from "@mui/icons-material/School";
import NightlifeIcon from "@mui/icons-material/Nightlife";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStep, setLeadData } from "../../../modules/landing.slice";
import BackButton from "../../../components/BackButton/BackButton";
import * as yup from "yup";
import useUrlQuery from "../../../hooks/useUrlQuery";
import ChildAndParentSelector from "../components/ChildAndParentSelector";
import FormikSideEffect from "../../../hoc/FormikSideEffect";
import moment from "moment";
const TravelPurpose = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { leadData } = useSelector((state) => state.landing);
  const trip_start_date = leadData?.trip_start_date;
  const trip_end_date = leadData?.trip_end_date;
  const initialValues = {
    travel_purpose_id: String(+leadData?.travel_purpose_id || 1),
    travel_type: leadData?.travel_type || "2",
    plan_type:
      leadData?.plan_type === "Floater" || String(leadData?.plan_type) === "2"
        ? "2"
        : "1",
    insured_age: leadData?.insured_age || "",
    members_age_detail:
      leadData?.members_age_detail || leadData?.member_age_details || {},
  };
  const validationSchema = yup.object().shape({
    insured_age: yup.string().required("Insured age is required"),
    members_age_detail: yup
      .object()
      .nullable()
      .when("plan_type", {
        is: "2",
        then: yup
          .object()
          .test(
            "members_age_detail",
            "Please select at least two member",
            (value) => Object.keys(value || {}).length > 0
          ),
      }),
  });
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const payload = {
            ...values,
            step: 2,
            enquiry_id,
            no_of_travellers:
              Object.keys(values?.members_age_detail || {}).length + 1,
            travel_type_id: "2",
          };
          dispatch(setLeadData(payload));
          dispatch(setActiveStep(2));
        }}
      >
        {({ values, handleSubmit, handleChange, setFieldValue, errors }) => (
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid sx={{ mt: -5 }} item xs={0.5} md={2}>
              <BackButton
                onClick={() => {
                  dispatch(setActiveStep(0));
                }}
              />
            </Grid>
            <Grid item xs={11.5} md={11}>
              <Typography
                variant="h3"
                fontSize={{ xs: "14px", md: "20px" }}
                component="p"
                m={2}
              >
                Select Your Purpose Of Travel To Suggest The Best Policy
              </Typography>
            </Grid>
            <Grid
              sx={{ ml: 0 }}
              justifyContent="center"
              alignItems="center"
              container
              px={2}
              spacing={2}
            >
              <FormikSideEffect
                effect={() => {
                  if (
                    trip_start_date &&
                    trip_end_date &&
                    moment(trip_end_date, "YYYY-MM-DD").diff(
                      moment(trip_start_date, "YYYY-MM-DD"),
                      "day"
                    ) > 179
                  ) {
                    setFieldValue("travel_purpose_id", "4");
                  }
                }}
                dependencies={[trip_start_date, trip_end_date]}
              >
                <Grid height={{ xs: "60px", sm: "72px" }} item xs={6} md={6}>
                  <input
                    className="custom-radio"
                    type="radio"
                    name="travel_purpose_id"
                    value={"1"}
                    id={`leisure`}
                    checked={values.travel_purpose_id === "1"}
                    onChange={handleChange}
                  />
                  <label className="purpose" htmlFor={`leisure`}>
                    <span
                      style={{ fontSize: isMobile ? "12px" : "16px" }}
                      className="mt-1"
                    >
                      Leisure
                    </span>
                    <span className="radio-icon">
                      <NightlifeIcon />
                    </span>
                  </label>
                </Grid>
                <Grid height={{ xs: "60px", sm: "72px" }} xs={6} item md={6}>
                  <input
                    className="custom-radio"
                    type="radio"
                    name="travel_purpose_id"
                    value={"4"}
                    id={`study`}
                    checked={values.travel_purpose_id === "4"}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value === "4") {
                        setFieldValue("plan_type", "1");
                      }
                    }}
                  />
                  <label className="purpose" htmlFor={`study`}>
                    <span
                      style={{ fontSize: isMobile ? "12px" : "16px" }}
                      className="mt-1"
                    >
                      Study
                    </span>
                    <span className="radio-icon">
                      <SchoolIcon />
                    </span>
                  </label>
                </Grid>
              </FormikSideEffect>
            </Grid>

            <Grid sx={{ ml: 0 }} item md={12}>
              <Typography
                fontSize={{ xs: "14px", md: "20px" }}
                variant="h3"
                component="p"
                m={2}
              >
                Ready to go?
              </Typography>
            </Grid>
            <Grid sx={{ ml: 0 }} container px={2} spacing={2}>
              <FormikSideEffect
                effect={() => {
                  if (
                    trip_start_date &&
                    trip_end_date &&
                    moment(trip_end_date, "YYYY-MM-DD").diff(
                      moment(trip_start_date, "YYYY-MM-DD"),
                      "day"
                    ) > 179
                  ) {
                    setFieldValue("plan_type", "1");
                  }
                }}
                dependencies={[trip_start_date, trip_end_date]}
              >
                <Grid height={{ xs: "60px", sm: "72px" }} item xs={6} md={6}>
                  <input
                    className="custom-radio"
                    type="radio"
                    name="plan_type"
                    value={"1"}
                    id={`individual`}
                    checked={values.plan_type === "1"}
                    onChange={(e) => handleChange(e)}
                  />
                  <label className="purpose" htmlFor={`individual`}>
                    <span
                      style={{ fontSize: isMobile ? "12px" : "16px" }}
                      className="mt-1"
                    >
                      Self
                    </span>
                    <span className="radio-icon">
                      <PersonIcon />
                    </span>
                  </label>
                </Grid>
                <Grid height={{ xs: "60px", sm: "72px" }} xs={6} item md={6}>
                  <input
                    className="custom-radio"
                    disabled={values.travel_purpose_id === "4" ? true : false}
                    type="radio"
                    name="plan_type"
                    value={"2"}
                    id={`family-plan`}
                    checked={values.plan_type === "2"}
                    onChange={handleChange}
                  />
                  <label
                    className="purpose"
                    style={
                      values.travel_purpose_id === "4"
                        ? { border: "2px solid #D3D3D3", ".hover": "none" }
                        : {}
                    }
                    htmlFor={`family-plan`}
                  >
                    <span
                      style={{
                        color: values?.travel_purpose_id === 4 ? "gray" : "",
                        fontSize: isMobile ? "12px" : "16px",
                      }}
                      className="mt-1"
                    >
                      Family
                    </span>
                    <span
                      style={{
                        color: values?.travel_purpose_id === 4 ? "gray" : "",
                        marginLeft: "5px",
                      }}
                      className="radio-icon"
                    >
                      <FamilyRestroomIcon />
                    </span>
                  </label>
                </Grid>
              </FormikSideEffect>
            </Grid>

            {values.plan_type && (
              <Grid sx={{ ml: 2 }} item px={2} xs={12} md={12}>
                <ChildAndParentSelector onlySelf={values.plan_type == "1"} />
                <FormHelperText sx={{ color: "error.main", mt: 2 }}>
                  {errors.members_age_detail}
                </FormHelperText>
              </Grid>
            )}

            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleSubmit}
                sx={{
                  borderRadius: "6px",
                  boxShadow: "none",
                  backgroundColor: "var(--primary-color)",
                }}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  );
};

export default TravelPurpose;
