/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
// To be used inside Formik.
const FormikSideEffect = ({
  children,
  effect = () => {},
  dependencies = [],
}) => {
  useEffect(() => {
    effect(...dependencies);
  }, [...dependencies]);
  return <>{children}</>;
};

export default FormikSideEffect;
