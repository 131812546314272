import { Button, Card, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import down from "../../../assets/images/arrow-ios-back.png";
import { useGetUserDetails } from "../../../services/quoteService";

export default function MobileFooter({ setShow, setShowForm }) {
  const { userDetails } = useGetUserDetails();
  const selected_plan = userDetails?.selected_plan;

  return (
    <Card sx={{ p: 2, pb: 1, boxShadow: 5 }}>
      <Grid justifyContent="center" container spacing={3}>
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <img
                src={
                  userDetails.company_details &&
                  userDetails?.company_details[userDetails?.ic_detail?.ic_alias]
                    ?.logo
                }
                alt="logo"
                width="35px"
                height="35px"
              />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h6" fontWeight="bold" fontSize={"11px"}>
                {userDetails?.ic_detail?.ic_name}
              </Typography>
              <Stack alignItems="center" direction="row">
                <Typography variant="body2" fontSize={"9px"}>
                  {selected_plan?.plan_name}
                </Typography>
                <Button
                  onClick={() => {
                    setShow("block");
                    setShowForm("none");
                  }}
                  sx={{
                    width: "53px",
                    p: 0.3,
                    fontSize: "7px",
                    backgroundColor: "var(--primary-light)",
                    textTransform: "none",
                    boxShadow: 0,
                    ml: 1,
                  }}
                  variant="contained"
                >
                  <Stack alignItems="center" direction="row">
                    <Typography
                      sx={{ color: "var(--primary-color)" }}
                      variant="body2"
                      fontSize={"7px"}
                    >
                      view more
                    </Typography>

                    <img
                      style={{ marginLeft: 5 }}
                      src={down}
                      width="3px"
                      alt="down"
                    />
                  </Stack>
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" fontWeight="600" fontSize={"7px"}>
            Total Premium Payable
          </Typography>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ ml: 3, whiteSpace: "nowrap" }}
            fontSize={"13px"}
          >
            <span style={{ fontFamily: "Inter" }}>₹ </span>{" "}
            {selected_plan?.total_premium?.toLocaleString("en-IN")}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
