import { Box, Grid } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import airPlaneText from "./../../../assets/images/airplanetext.svg";
import LandingBg from "./../../../assets/images/LandingBg.png";

const MainWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundImage: {
          xs: "none",
          sm: `url(${LandingBg})`,
        },
        backgroundPosition: "bottom",
        backgroundRepeat: "repeat-x",
      }}
    >
      <Container>
        <Box component="main" sx={{ minHeight: "calc(100vh - 70px)" }}>
          <Grid container columnSpacing={4}>
            <Grid item xs={12} md={7} mt={{ xs: 0, sm: "2%", md: "10%" }}>
              <Grid item md={12}>
                <Box
                  sx={{
                    "&>img": {
                      width: { xs: "290px", sm: "auto", sx: "auto" },
                      marginTop: { xs: "0px" },
                      float: { xs: "left", sm: "left" },
                      mt: 5,
                    },
                  }}
                >
                  <img src={airPlaneText} alt="airplane" />
                </Box>
              </Grid>
            </Grid>
            <Grid item md={5} xs={12}>
              {children}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};
export default MainWrapper;
