/* eslint-disable eqeqeq */
import React from "react";
import {
  Button,
  Card,
  Grid,
  useTheme,
  Typography,
  useMediaQuery,
  Box,
  Paper,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../../components/InputComponents/TextInput";
import { setProposalData } from "../../../modules/proposal.slice";
import {
  useCkycSubmitMutation,
  useStoreProposalMutation,
} from "../../../services/proposalService";
import { upperCase } from "../../../utils/input";
import Title from "../component/FormTitle";
import Summary from "../component/Summary";
import Select from "../../../components/InputComponents/Select";
import {
  FileUploadContainer,
  FileUploadStyled,
  InputErrorMessage,
} from "../styles/ckyc.styles";
import CkycVerified from "../component/CkycVerified";
import * as yup from "yup";
import FormikSideEffect from "../../../hoc/FormikSideEffect";
import {
  addressProofOptions,
  identityProofOptions,
} from "../constants/care.constant";
// --------------------------------doc upload base64 function starts-----------------------------//
const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
// --------------------------------doc upload base64 function ends-----------------------------//
const CkycCare = ({ index }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    proposalData,
    cKycFailCount,
    cKycStatus,
    active,
    cKycResponse,
    cKycUploadStatus,
    cKycUploadLoader,
  } = useSelector((state) => state.proposal);
  const pan_no = proposalData?.proposar?.pan_no;
  const initialData = {
    pan_no: pan_no,
    identity_proof: "",
  };
  const dispatch = useDispatch();
  const { storeProposal } = useStoreProposalMutation({ index });
  const { cKycValidate } = useCkycSubmitMutation();
  const validateSchema = yup.object().shape({
    ...(!cKycStatus &&
      cKycFailCount == 0 && {
        pan_no: yup
          .string()
          .required("Pan no. is required")
          .matches(
            /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
            "PAN number invalid"
          ),
      }),
    ...(!cKycStatus &&
      cKycFailCount == 1 &&
      !Object.values(cKycUploadStatus)?.every((item) => item === true) && {
        identity_proof_type: yup
          .string()
          .required("Identity Proof Type is required."),
        identity_proof: yup
          .mixed()
          .test("identity_proof", "please select file", (value) => !!value)
          .test(
            "identity_proof",
            "File size is too large, Must Be less than 2MB",
            (value) => value && +value?.size / 1024 / 1024 <= 2
          )
          .test(
            "identity_proof",
            "Unsupported file format",
            (value) =>
              value &&
              [
                "image/jpeg",
                "image/png",
                "image/gif",
                "image/webp",
                "image/bmp",
                "image/svg+xml",
                "application/pdf",
              ].includes(value?.type)
          ),
        address_proof_type: yup
          .string()
          .required("Identity Proof Type is required."),
        address_proof: yup
          .mixed()
          .test("address_proof", "please select file", (value) => !!value)
          .test(
            "address_proof",
            "File size is too large, Must Be less than 2MB",
            (value) => value && +value?.size / 1024 / 1024 <= 2
          )
          .test(
            "address_proof",
            "Unsupported file format",
            (value) =>
              value &&
              [
                "image/jpeg",
                "image/png",
                "image/gif",
                "image/webp",
                "image/bmp",
                "image/svg+xml",
                "application/pdf",
              ].includes(value?.type)
          ),
      }),
  });
  if (index < active) {
    return (
      <Summary title="CKYC Details" index={index}>
        <CkycVerified innerText={"Your KYC is complete."} />
      </Summary>
    );
  }
  if (index > active) {
    return (
      <Paper sx={{ m: 2, backgroundColor: "#Fff" }}>
        <Typography fontSize={"22px"} p={2}>
          CKYC Details
        </Typography>
      </Paper>
    );
  }

  return (
    <>
      <Paper sx={{ m: 2, backgroundColor: "#F0F4F5" }}>
        <Formik
          initialValues={initialData}
          validationSchema={validateSchema}
          onSubmit={(values) => {
            const payload = {
              ...values,
              ...(cKycStatus && {
                ckyc: true,
                ckyc_id: cKycResponse?.ckyc_id,
                ckyc_reference_id: cKycResponse?.ckyc_reference_id,
              }),
              ...(cKycStatus === "true" && {
                ekyc: true,
                ekyc_id: cKycResponse?.ekyc_id,
              }),
              ...(!cKycStatus && !cKycResponse?.ckyc_id && { ovdkyc: true }),
            };
            storeProposal({
              step: 10,
              additional_data: { ...proposalData, ckyc_data: { ...payload } },
              ckyc_data: { ...payload },
              is_proposer: 1,
            });
            dispatch(setProposalData({ ckyc_data: payload }));
          }}
          validateOnChange={true}
        >
          {({ errors, setFieldValue, values, validateField }) => {
            return (
              <div
                style={{
                  backgroundColor: "#f0f4f5",
                  borderRadius: "4px",
                  boxShadow:
                    " 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                  transition:
                    " box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                }}
              >
                <Form>
                  <Card>
                    <Title>CKYC Details</Title>
                    <div className="main" style={{ padding: "10px" }}>
                      {!cKycStatus && cKycFailCount == 0 && (
                        <div className="pan" sx={{ display: "flex" }}>
                          <Grid
                            container
                            sx={{ display: isMobile ? "block" : "flex" }}
                          >
                            <Grid item xs={12} md={4} sx={{ padding: "16px" }}>
                              <TextInput
                                name={`pan_no`}
                                label="PAN No."
                                values={pan_no}
                                inputProps={{ readOnly: true }}
                                onInput={upperCase}
                                error={errors?.pan_no}
                                helperText={errors?.pan_no}
                              />
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ padding: "16px" }}>
                              <Button
                                fullWidth={isMobile ? true : false}
                                sx={{
                                  textTransform: "none",
                                  fontSize: isMobile ? "13px" : "16px",
                                  p: 2,
                                  pl: 4,
                                  pr: 4,
                                  borderRadius: "6px",
                                }}
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  if (!!values?.pan_no && !errors?.pan_no) {
                                    cKycValidate({
                                      pan_no: values?.pan_no,
                                      mode: "pan_number_with_dob",
                                      docType: "pan_card",
                                    });
                                  }
                                }}
                              >
                                {cKycUploadLoader?.pan_card
                                  ? "Loading..."
                                  : "Validate PAN"}
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      )}
                      {!cKycStatus &&
                        cKycFailCount == 1 &&
                        !Object.values(cKycUploadStatus)?.every(
                          (item) => item === true
                        ) && (
                          <>
                            <div style={{ marginTop: "20px", padding: "16px" }}>
                              <h6 className="mobsubhead text-danger">
                                Please upload JPEG, PDF, PNG, TIFF file types
                                only. Size (upto 2MB)
                              </h6>
                            </div>
                            <Grid container className="docUpload">
                              <Grid
                                item
                                xs={12}
                                md={4}
                                sx={{ padding: "16px" }}
                              >
                                <Select
                                  fixedLabel={false}
                                  name="identity_proof_type"
                                  label="Select Identity Proof"
                                  options={identityProofOptions}
                                  selectOnly={true}
                                  error={errors?.identity_proof_type}
                                  helperText={errors?.identity_proof_type}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={5}
                                sx={{ padding: "16px" }}
                              >
                                <FormikSideEffect
                                  effect={() => {
                                    validateField("identity_proof");
                                  }}
                                  dependencies={[values.identity_proof]}
                                >
                                  <FileUploadContainer
                                    label="Identity Proof*"
                                    error={errors?.identity_proof}
                                  >
                                    <FileUploadStyled
                                      type="file"
                                      name="identity_proof"
                                      id="identity_proof"
                                      className="form-control"
                                      accept="image/*,.pdf"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "identity_proof",
                                          e?.target?.files[0]
                                        );
                                      }}
                                      error={errors?.identity_proof}
                                    />
                                  </FileUploadContainer>
                                </FormikSideEffect>
                                {errors.identity_proof && (
                                  <InputErrorMessage>
                                    {errors.identity_proof}
                                  </InputErrorMessage>
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={3}
                                sx={{ padding: "16px" }}
                              >
                                <Button
                                  fullWidth={true}
                                  sx={{
                                    textTransform: "none",
                                    fontSize: isMobile ? "13px" : "16px",
                                    p: 2,
                                    pl: 2,
                                    borderRadius: 1,
                                  }}
                                  variant="contained"
                                  color="secondary"
                                  onClick={async () => {
                                    if (
                                      values.identity_proof &&
                                      values?.identity_proof_type &&
                                      !errors?.identity_proof
                                    ) {
                                      const file = await convertToBase64(
                                        values?.identity_proof
                                      );
                                      cKycValidate({
                                        documentData: file?.split(",")?.[1],
                                        docType: "identity_proof",
                                        mode: "document",
                                        file_name: values?.identity_proof?.name,
                                      });
                                    }
                                    validateField("identity_proof");
                                    validateField("identity_proof_type");
                                  }}
                                >
                                  {cKycUploadLoader?.identity_proof
                                    ? "Loading..."
                                    : cKycUploadStatus?.identity_proof
                                    ? "Uploaded"
                                    : "Upload"}
                                </Button>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                sx={{ padding: "16px" }}
                              >
                                <Select
                                  fixedLabel={false}
                                  name="address_proof_type"
                                  label="Select Address Proof"
                                  options={addressProofOptions}
                                  selectOnly={true}
                                  error={errors?.address_proof_type}
                                  helperText={errors?.address_proof_type}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={5}
                                sx={{ padding: "16px" }}
                              >
                                <FileUploadContainer
                                  label="Address Proof*"
                                  error={errors?.address_proof}
                                >
                                  <FileUploadStyled
                                    type="file"
                                    name="address_proof"
                                    id="address_proof"
                                    className="form-control"
                                    accept="image/*,.pdf"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "address_proof",
                                        e?.target?.files[0]
                                      );
                                    }}
                                    error={errors?.address_proof}
                                  />
                                </FileUploadContainer>
                                {errors.address_proof && (
                                  <InputErrorMessage>
                                    {errors.address_proof}
                                  </InputErrorMessage>
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={3}
                                sx={{ padding: "16px" }}
                              >
                                <Button
                                  fullWidth={true}
                                  sx={{
                                    textTransform: "none",
                                    fontSize: isMobile ? "13px" : "16px",
                                    p: 2,
                                    pl: 2,
                                    borderRadius: 1,
                                  }}
                                  variant="contained"
                                  color="secondary"
                                  onClick={async () => {
                                    if (
                                      values.address_proof &&
                                      values?.address_proof_type &&
                                      !errors?.address_proof
                                    ) {
                                      const file = await convertToBase64(
                                        values?.address_proof
                                      );
                                      cKycValidate({
                                        documentData: file?.split(",")?.[1],
                                        docType: "address_proof",
                                        mode: "document",
                                        file_name: values?.address_proof?.name,
                                      });
                                    }
                                    validateField("address_proof");
                                    validateField("address_proof_type");
                                  }}
                                >
                                  {cKycUploadLoader?.address_proof
                                    ? "Loading..."
                                    : cKycUploadStatus?.address_proof
                                    ? "Uploaded"
                                    : "Upload"}
                                </Button>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      {(cKycStatus ||
                        Object.values(cKycUploadStatus)?.every(
                          (item) => item === true
                        )) && (
                        <CkycVerified
                          innerText={"Your KYC is complete, you can proceed."}
                        />
                      )}
                      {(cKycStatus ||
                        Object.values(cKycUploadStatus)?.every(
                          (item) => item === true
                        )) && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            p: 2,
                          }}
                        >
                          <Button
                            fullWidth={isMobile ? true : false}
                            sx={{
                              textTransform: "none",
                              fontSize: isMobile ? "13px" : "16px",
                              p: 2,
                              pl: 4,
                              pr: 4,
                              borderRadius: 1,
                            }}
                            variant="contained"
                            color="secondary"
                            type="submit"
                          >
                            Proceed to Traveler
                          </Button>
                        </Box>
                      )}
                    </div>
                  </Card>
                </Form>
              </div>
            );
          }}
        </Formik>
      </Paper>
    </>
  );
};
export default CkycCare;
