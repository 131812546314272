import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";

const FeatureCard = ({ title, children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Box
        width="100%"
        sx={{
          border: " 1px solid rgba(0, 0, 0, 0.3)",
          borderRadius: "10px",
        }}
        mt={2}
      >
        <Typography
          variant="h6"
          sx={{
            background: "rgba(165, 176, 250, 0.5)",
            borderRadius: "9px 0px",
            display: "inline-block",
            padding: "6px 12px",
            fontSize: isMobile ? "12px" : "18px",
            fontWeight: "600",
            color: "var(--primary-color)",
            mb: 2,
          }}
        >
          {title}
        </Typography>
        <Grid container spacing={1}>
          {children}
        </Grid>
      </Box>
    </>
  );
};

export default FeatureCard;
