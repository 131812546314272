import { Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import RecommendCard from "../../RecommendCard";
import MobileRecommend from "./MobileRecommend";

const QuoteContainer = ({ children, recommended, isLoading, quoteData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  if (!quoteData?.status && !isLoading) return null;
  if (isMobile) {
    return (
      <>
        {recommended && !isLoading ? <MobileRecommend /> : null}
        <Paper
          sx={{
            mb: "16px !important",
            padding: 1,
            paddingLeft: 1,
            pr: 0,
            position: "relative",
            border:
              recommended && !isLoading
                ? "2px solid var(--primary-color)"
                : "1px solid rgba(165, 176, 250, 0.5)",
            borderRadius:
              recommended && !isLoading ? "0px 0px 10px 10px" : "10px",
            boxShadow: "none",
          }}
        >
          {children}
        </Paper>
      </>
    );
  }

  return (
    <>
      <Paper
        sx={{
          // boxShadow: "4px 4px 0px rgba(103, 128, 254, 0.1)",
          position: "relative",
          border:
            recommended && !isLoading
              ? "1px solid var(--primary-color)"
              : "1px solid #E4E4E4",
          // ":hover": {
          //   border: "1px solid var(--primary-color)",
          //   boxShadow: !isLoading && "4px 4px 0px #80aaff",
          // },
          mb: 2,
          py: 1,
        }}
      >
        {recommended && !isLoading ? (
          <Box
            textAlign="center"
            position={"absolute"}
            sx={{
              top: "-18px",
              left: "49%",
              transform: "translateX(-50%)",
            }}
          >
            <RecommendCard />{" "}
          </Box>
        ) : null}
        {children}
      </Paper>
    </>
  );
};

export default QuoteContainer;
