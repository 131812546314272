import { ArrowDropDownSharp, ArrowDropUpSharp } from "@mui/icons-material";
import {
  Box,
  Card,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useUrlQuery from "../../../../../hooks/useUrlQuery";
import { setSumInsurer } from "../../../../../modules/landing.slice";
import {
  useGetFrontendBoot,
  usePatchQuote,
} from "../../../../../services/landingService";

const SumInsured = ({ subtitle, handleClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClicked = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { sum_insured } = useGetFrontendBoot();
  // sum insure sorted in ascending order
  sum_insured?.sort((a, b) => a.value - b.value);
  const handleClose = (e) => {
    setAnchorEl(null);
  };
  const { getQuery } = useUrlQuery();
  const { leadData } = useSelector((state) => state.landing);
  const currency = leadData?.currency;
  const enquiry_id = getQuery("enquiry_id");
  const { patchQuote } = usePatchQuote();
  useEffect(() => {}, [subtitle]);
  if (isMobile) {
    return (
      <>
        <Box
          id="basic-card"
          onClick={handleClick}
          sx={{
            maxWidth: "224px",
            maxHeight: "48px",
            pl: 0.5,
            pr: 0.5,
            boxShadow: "none",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: isMobile ? "" : "rgba(211, 217, 254, 0.1)",
            height: isMobile ? "auto" : "56px",
            width: isMobile ? "100%" : "100%",
          }}
          disableRipple
          variant="flat"
        >
          <Stack
            alignItems="center"
            direction={isMobile ? "column" : "row"}
            justifyContent={isMobile ? "center" : "space-between"}
            px={1}
            height="100%"
          >
            <Typography
              sx={{
                fontSize: isTablet ? "18px" : "10px",
                fontWeight: "500",
              }}
              color="#333333"
              variant="h6"
            >
              Sum Insured :
            </Typography>
            <Stack direction="row" alignItems="center">
              <Typography
                color={"var(--primary-color)"}
                sx={{ fontSize: isTablet ? "18px" : "12px", fontWeight: "500" }}
              >
                ${subtitle}{" "}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </>
    );
  }
  return (
    <>
      <Card
        id="basic-card"
        onClick={handleClicked}
        sx={{
          width: "28%",
          borderRight: "1px solid  #E4E4E4",
          borderRadius: "0px",
        }}
        disableRipple
        variant="flat"
      >
        <Stack
          alignItems={isMobile ? "flex-start" : "center"}
          direction={isMobile ? "column" : "row"}
          px={1}
          justifyContent="center"
          height={"100%"}
        >
          <Typography
            sx={{
              fontSize: isMobile ? "9px" : "12px",
              fontWeight: "400",
            }}
            color="#333333"
            variant="h6"
          >
            Sum Insured :
          </Typography>
          <Stack direction="row" alignItems="center">
            {currency === 2 ? (
              <Typography
                sx={{
                  fontSize: isMobile ? "10px" : "12px",
                  fontWeight: "600",
                  mt: 0.4,
                }}
              >
                £ {Number(subtitle).toLocaleString("en-US")}{" "}
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontSize: isMobile ? "10px" : "12px",
                  fontWeight: "600",
                  mt: 0.4,
                }}
              >
                $ {Number(subtitle).toLocaleString("en-US")}{" "}
              </Typography>
            )}

            {anchorEl ? (
              <ArrowDropUpSharp
                sx={{
                  width: isMobile ? "20px" : "40px",
                  height: "40px",
                  color: "black",
                }}
              />
            ) : (
              <ArrowDropDownSharp
                sx={{
                  width: isMobile ? "20px" : "40px",
                  height: "40px",
                  color: "black",
                }}
              />
            )}
          </Stack>
        </Stack>
      </Card>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-card",
        }}
        sx={{
          "& .MuiPaper-root": {
            // border: "1px solid var(--primary-color)",
            borderTop: "none",
            borderRadius: 0,
            borderBottomRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            boxShadow: "4px 4px 0px rgba(103, 128, 254, 0.1)",
            // maxWidth: "1000px !important",
            minwidth: "168px",
            marginLeft: "37px",
            marginTop: "0px",
            // marginLeft: "16px",
            " @media screen and (min-width: 1220px) and (max-width: 1439px) ": {
              width: "170px",
              maxWidth: "200px",
            },
            " @media screen and (min-width: 1440px) and (max-width: 1500px) ": {
              // marginTop: "22px",
              marginLeft: "44px",
              width: "178px",
              maxWidth: "200px",
            },
          },
        }}
      >
        <Grid direction={"column"} container>
          {sum_insured?.map((item) => (
            <Grid item sx={4}>
              {" "}
              <FilterItem
                setAnchorEl={setAnchorEl}
                onClick={(e) => {
                  dispatch(setSumInsurer(item?.value));
                  setAnchorEl(null);
                  patchQuote({
                    sum_insured: item?.value,
                    enquiry_id,
                  });
                }}
              >
                <Typography
                  sx={{ fontSize: "15px", margin: "0 auto" }}
                  variant="body1"
                  gutterBottom
                >
                  $ {item?.value.toLocaleString("en-US")}
                </Typography>
              </FilterItem>
            </Grid>
          ))}
        </Grid>
      </Menu>
    </>
  );
};

const FilterItem = ({ children, onClick }) => {
  return (
    <MenuItem
      onClick={onClick}
      name="sum_insured"
      sx={{
        ":hover": {
          backgroundColor: "var(--primary-light)",
        },
      }}
    >
      {children}
    </MenuItem>
  );
};

export default SumInsured;
