import {
  Button,
  Card,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import arrow from "../../assets/images/arrow-ios-back.png";
import useUrlQuery from "../../hooks/useUrlQuery";

export default function TYCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");

  const navigate = useNavigate();
  const onClick = () => {
    navigate(`/quotes?enquiry_id=${enquiry_id}`);
  };

  return (
    <Card
      sx={{
        pt: 10,
        maxHeight: "538px",
        maxWidth: "583px",
        boxShadow: 5,
        p: 2,
        mb: 5,
      }}
    >
      <Container sx={{ mt: isMobile ? "23px" : 15 }}>
        <Stack justifyContent="center" alignItems="center" spacing={2}>
          <Typography
            sx={{ fontSize: isMobile ? "37px" : "78px" }}
            variant="h1"
          >
            Thank You!!
          </Typography>
          <Typography fontSize={isMobile ? "13px" : "23px"} variant="h6">
            I have also emailed your policy.
          </Typography>
          <Typography
            fontSize={isMobile ? "11px" : "23px"}
            sx={{ pb: isMobile ? 2 : 5 }}
            variant="h3"
          >
            <span style={{ color: " #a1a1b6" }}>Policy No :</span> 883944
          </Typography>
          <Button
            sx={{
              textTransform: "none",
              pr: 3,
              pl: 3,
              borderRadius: 3,
              fontSize: isMobile ? "13px" : "20px",
            }}
            variant="contained"
            color="secondary"
          >
            View Travel Policy
          </Button>
          <Button
            onClick={onClick}
            sx={{
              backgroundColor: "rgba(58, 116, 173, 0.16)",
              borderRadius: 3,
              p: 0.9,
            }}
            variant="outlined"
          >
            <Stack alignItems="center" spacing={1} direction="row">
              <Typography
                sx={{
                  mt: 0.2,
                  color: "#6780ff",
                  fontSize: "9px",
                  fontWeight: "600",
                }}
                variant="body1"
              >
                Back To Quote
              </Typography>
              <img src={arrow} width="5.5px" height="10.9px" alt="" />
            </Stack>
          </Button>
        </Stack>
      </Container>
    </Card>
  );
}
