import React from "react";
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Formik } from "formik";
import CustomRadio from "../InputComponents/CustomRadio";
import ButtonWrapper from "../InputComponents/ButtonWrapper";
import { useDispatch } from "react-redux";
import { setSortBy } from "../../modules/landing.slice";

export default function SortBy({ handleClose }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  return (
    <Box>
      <Title>Sort By</Title>
      <Formik
        initialValues={{
          plan: "",
        }}
        onSubmit={(values) => {
          dispatch(setSortBy(values.sort));
          handleClose();
        }}
      >
        <Box sx={{ mt: 2 }}>
          <CustomRadio name="sort" value="High To Low">
            <spam style={{ fontSize: isTablet ? "16px" : "14px" }}>
              High to low
            </spam>
          </CustomRadio>
          <CustomRadio name="sort" value="Low To High">
            <spam style={{ fontSize: isTablet ? "16px" : "14px" }}>
              Low to High
            </spam>
          </CustomRadio>
          <ButtonWrapper>Update</ButtonWrapper>
        </Box>
      </Formik>
    </Box>
  );
}

const Title = ({ children }) => {
  return (
    <Stack flexDirection="row">
      <Box
        sx={{
          width: "2%",
          border: "2px",
          backgroundColor: "rgba(58, 116, 173, 0.22)",
        }}
      ></Box>
      <Box
        sx={{
          width: "98%",
          backgroundImage: "linear-gradient(98deg, #E8E8E8, white)",
          pl: 2,
          pt: 1,
        }}
      >
        <Typography fontSize={"14px"} variant="h6" gutterBottom>
          {children}
        </Typography>
      </Box>
    </Stack>
  );
};
