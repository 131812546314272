/* eslint-disable eqeqeq */
import React, { useState } from "react";
import {
  Button,
  Card,
  Grid,
  useTheme,
  Typography,
  useMediaQuery,
  Box,
  Paper,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../../components/InputComponents/TextInput";
import { setProposalData } from "../../../modules/proposal.slice";
import {
  useCkycSubmitMutation,
  useStoreProposalMutation,
} from "../../../services/proposalService";
import {
  allowFullName,
  allowOnlyNumbers,
  upperCase,
} from "../../../utils/input";
import Title from "../component/FormTitle";
import Summary from "../component/Summary";
import {
  FileUploadContainer,
  FileUploadStyled,
  InputErrorMessage,
} from "../styles/ckyc.styles";
import CkycVerified from "../component/CkycVerified";
import * as yup from "yup";
import {
  documentOptions,
  documentTypeOptions,
  familyRelationOptions,
  identityProofOptions,
  sourceOfIncomeOptions,
} from "../constants/star.constant";
import Toggle from "../../../components/InputComponents/Toggle";
import FormikSideEffect from "../../../hoc/FormikSideEffect";
import Select from "../../../components/InputComponents/Select";
import { setCKycResponse } from "../../../modules/proposal.slice";
import ButtonLoader from "../../../components/ButtonLoader/ButtonLoader";
// --------------------------------doc upload base64 function starts-----------------------------//
const convertToBase64 = (file) => {
  if (typeof file === "string") return file;
  if (!file) return "";
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.onerror = (error) => reject(error);
  });
};
// --------------------------------doc upload base64 function ends-----------------------------//
const CkycStar = ({ index }) => {
  const theme = useTheme();
  const [ckycType, setCkycType] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { proposalData, cKycStatus, active, cKycResponse, cKycUploadLoader } =
    useSelector((state) => state.proposal);
  const isCkycSuccessFull = cKycResponse.verification_status;
  const pan_no = proposalData?.[`proposar`]?.pan_no;
  const ckycFormData = proposalData?.[`ckyc_data`];
  const initialData = {
    pan_no: pan_no,
    identity_proof: "",
    pep: false,
    is_ckyc_no: true,
    poi_document: "",
    form60_upload: "",
    poi_document_upload: "",
    poi_document_unique_id: "",
    kyc_upload_photo_id: "",
    family_relationship: "",
    family_member_name: "",
    occupation: "",
    source_of_income: "",
  };
  const dispatch = useDispatch();
  const { storeProposal, isLoading } = useStoreProposalMutation({ index });
  const { cKycValidate, isLoading: cKycValidateLoading } =
    useCkycSubmitMutation();

  const validateSchema = yup.object().shape({
    occupation: yup.string().required("Occupation is Required"),
    source_of_income: yup.string().required("Source of Income is Required"),
    pep: yup.string().required("This Field is Required"),
    remarks: yup.string().when("pep", {
      is: true,
      then: yup.string().required("This Field is Required"),
      otherwise: yup.string(),
    }),
    is_ckyc_no: yup.boolean().required("This Field is Required"),
    ckyc_number: yup.string().when("is_ckyc_no", {
      is: true,
      then: yup
        .string()
        .required("CKYC Number is required")
        // .matches(/^[5-9]{1}[0-9]{13}$/, "Must be only digits")
        .min(14, "CKYC No. should be 14 digits")
        .max(14, "CKYC No. should be 14 digits"),
      otherwise: yup.string(),
    }),
    document_type: yup.string().when("is_ckyc_no", {
      is: false,
      then: yup.string().required("Select Document is required"),
      otherwise: yup.string(),
    }),
    pan_no: yup.string().when(["is_ckyc_no", "document_type"], {
      is: (is_ckyc_no, document_type) =>
        is_ckyc_no === false && document_type === "pan_card",
      then: yup
        .string()
        .required("PAN Number is required")
        .matches(
          /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
          "PAN number invalid"
        ),
      otherwise: yup.string(),
    }),
    ...(ckycType == "form60" && {
      form60_upload: yup
        .mixed()
        .test("form60_upload", "Please Select the file", (value) => value)
        .test("form60_upload", "File is too large", (value) => {
          if (typeof value === "string") {
            const cleanedBase64 = value.replace(/=/g, "");
            const estimatedSize = (cleanedBase64.length * 3) / 4;
            return !estimatedSize / 1024 / 1024 <= 10;
          }
          return value?.size / 1024 / 1024 <= 10;
        })
        .required("Please choose File"),
    }),
    poi_document: yup.string().when(["is_ckyc_no", "document_type"], {
      is: (is_ckyc_no, document_type) =>
        is_ckyc_no === false && document_type === "form60",
      then: yup.string().required("Proof of Identity is Required"),
      otherwise: yup.string(),
    }),
    ...(ckycType == "form60" && {
      poi_document_upload: yup
        .mixed()
        .test("poi_document_upload", "Please Select the file", (value) => value)
        .test("poi_document_upload", "File is too large", (value) => {
          if (typeof value === "string") {
            const cleanedBase64 = value.replace(/=/g, "");
            const estimatedSize = (cleanedBase64.length * 3) / 4;
            return !estimatedSize / 1024 / 1024 <= 10;
          }
          return value?.size / 1024 / 1024 <= 10;
        })
        .required("Please choose File"),
    }),
    poi_document_unique_id: yup.string().when(["is_ckyc_no", "document_type"], {
      is: (is_ckyc_no, document_type) =>
        is_ckyc_no === false && document_type === "form60",
      then: yup
        .string()
        .when("poi_document", {
          is: () => "1",
          then: yup
            .string()
            .required("Identity Number is required")
            .matches(
              /^[A-Za-z][A-Za-z0-9]\d\s?\d{4}[0-9]$/,
              "Identity Number invalid"
            )
            .min(8, "Minimum 8 Characters Required")
            .max(8, "Maximum 8 Characters Required"),
        })
        .when("poi_document", {
          is: (val) => ["2", "3", "5", "6"].includes(val),
          then: yup
            .string()
            .required("Identity Number is required")
            .max(20, "Maximum 20 Characters Required"),
        })
        .when("poi_document", {
          is: (val) => val == "4",
          then: yup
            .string()
            .required("Identity Number is required ")
            .matches(
              /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
              "Invalid Identity Number."
            )
            .min(12, "Minimum 12 Characters Required")
            .max(12, "Maximum 12 Characters Required"),
        }),
      otherwise: yup.string(),
    }),
    ...(ckycType == "form60" && {
      kyc_upload_photo_id: yup
        .mixed()
        .test("kyc_upload_photo_id", "Please Select the file", (value) => value)
        .test("kyc_upload_photo_id", "File is too large", (value) => {
          if (typeof value === "string") {
            const cleanedBase64 = value.replace(/=/g, "");
            const estimatedSize = (cleanedBase64.length * 3) / 4;
            return !estimatedSize / 1024 / 1024 <= 10;
          }
          return value?.size / 1024 / 1024 <= 10;
        })
        .required("Please choose File"),
    }),
    family_relationship: yup.string().when(["is_ckyc_no", "document_type"], {
      is: (is_ckyc_no, document_type) =>
        is_ckyc_no === false && document_type === "form60",
      then: yup.string().required("Family Member is Required"),
      otherwise: yup.string(),
    }),
    family_member_name: yup.string().when(["is_ckyc_no", "document_type"], {
      is: (is_ckyc_no, document_type) =>
        is_ckyc_no === false && document_type === "form60",
      then: yup.string().required("Family Member Name is Required"),
      otherwise: yup.string(),
    }),
  });
  if (index < active) {
    return (
      <Summary title="CKYC Details" index={index}>
        <CkycVerified innerText={"Your KYC is complete."} />
      </Summary>
    );
  }
  if (index > active) {
    return (
      <Paper sx={{ m: 2, backgroundColor: "#Fff" }}>
        <Typography fontSize={"22px"} p={2}>
          CKYC Details
        </Typography>
      </Paper>
    );
  }
  return (
    <>
      <Paper sx={{ m: 2, backgroundColor: "#F0F4F5" }}>
        <Formik
          initialValues={ckycFormData ? ckycFormData : initialData}
          validationSchema={cKycStatus ? yup.object().shape() : validateSchema}
          onSubmit={async (values) => {
            try {
              const form60_upload = await convertToBase64(
                values?.form60_upload,
                ckycFormData?.ckyc_data?.form60_upload
              );
              const poi_document_upload = await convertToBase64(
                values?.poi_document_upload,
                ckycFormData?.ckyc_data?.form60_upload
              );
              const kyc_upload_photo_id = await convertToBase64(
                values?.kyc_upload_photo_id,
                ckycFormData?.ckyc_data?.form60_upload
              );
              const payload = {
                ...values,
                form60_upload,
                poi_document_upload,
                kyc_upload_photo_id,
                ...(cKycStatus && {
                  ckyc: true,
                  ckyc_id: cKycResponse?.ckyc_id,
                  ckyc_reference_id: cKycResponse?.ckyc_reference_id,
                }),
                ...(cKycStatus === "true" && {
                  ekyc: true,
                  ekyc_id: cKycResponse?.ekyc_id,
                }),
                ...(!cKycStatus && !cKycResponse?.ckyc_id && { ovdkyc: true }),
              };

              dispatch(setProposalData({ ckyc_data: payload }));
              storeProposal({
                step: 10,
                additional_data: { ...proposalData, ckyc_data: { ...payload } },
                ckyc_data: { ...payload },
                is_proposer: 1,
              });
            } catch (err) {
              console.error(err);
            }
          }}
        >
          {({
            errors,
            setFieldValue,
            values,
            validateField,
            touched,
            setFieldTouched,
          }) => {
            return (
              <Form noValidate>
                <Card>
                  <Title>CKYC Details</Title>
                  <div className="main" style={{ padding: "10px" }}>
                    {!cKycStatus && (
                      <div className="pan" sx={{ display: "flex" }}>
                        <Grid
                          container
                          sx={{ display: isMobile ? "block" : "flex" }}
                        >
                          <Grid item xs={12} md={6} sx={{ padding: "16px" }}>
                            <Select
                              name={`occupation`}
                              fixedLabel={false}
                              label="Select Occupation*"
                              options={documentTypeOptions}
                              error={touched?.occupation && errors?.occupation}
                              // helperText={errors?.occupation}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} sx={{ padding: "16px" }}>
                            <Select
                              name="source_of_income"
                              label="Source of Income*"
                              options={sourceOfIncomeOptions}
                              selectOnly={true}
                              error={
                                touched?.source_of_income &&
                                errors?.source_of_income
                              }
                              // helperText={errors?.source_of_income}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            sx={{ padding: "16px" }}
                            ml={{ md: 1, xs: 0.5 }}
                          >
                            <Toggle
                              label="Are you a Politically Exposed Person (PEP)?"
                              name={`pep`}
                              error={
                                touched?.source_of_income &&
                                errors?.source_of_income
                              }
                              // helperText={errors?.source_of_income}
                            />
                          </Grid>
                          {values?.pep && (
                            <>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                sx={{ padding: "16px" }}
                              >
                                <TextInput
                                  name={`remarks`}
                                  label="PEP Remarks"
                                  onInput={allowFullName}
                                  inputProps={{ minLength: 2, maxLength: 50 }}
                                  error={errors?.remarks}
                                />
                              </Grid>
                            </>
                          )}
                          <Grid
                            item
                            xs={12}
                            md={12}
                            sx={{ padding: "16px" }}
                            ml={{ md: 1, xs: 0.5 }}
                          >
                            <FormikSideEffect
                              effect={() => {
                                if (values?.is_ckyc_no) {
                                  setFieldValue("document_type", "");
                                } else {
                                  setFieldValue("ckyc_number", "");
                                }
                              }}
                              dependencies={[values?.is_ckyc_no]}
                            >
                              <Toggle
                                label="Do you have CKYC number?"
                                name={`is_ckyc_no`}
                              />
                            </FormikSideEffect>
                          </Grid>
                          {values?.is_ckyc_no && (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={8}
                                md={7}
                                sx={{ padding: "16px" }}
                              >
                                <FormikSideEffect
                                  effect={() => {
                                    console.log(values.is_ckyc_no);
                                    if (isCkycSuccessFull === false) {
                                      setFieldValue("is_ckyc_no", false);
                                      setFieldValue("document_type", "form60");
                                    }
                                    return dispatch(setCKycResponse({}));
                                  }}
                                  dependencies={[isCkycSuccessFull]}
                                >
                                  <TextInput
                                    name={`ckyc_number`}
                                    label="CKYC Number"
                                    onInput={(e) => allowOnlyNumbers(e)}
                                    inputProps={{ maxLength: 14 }}
                                    required
                                    error={
                                      touched?.ckyc_number &&
                                      errors?.ckyc_number
                                    }
                                    // helperText={errors?.ckyc_number}
                                  />
                                </FormikSideEffect>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                md={5}
                                sx={{ padding: "16px" }}
                              >
                                <Button
                                  fullWidth={isMobile ? true : false}
                                  sx={{
                                    textTransform: "none",
                                    fontSize: isMobile ? "13px" : "16px",
                                    p: 2,
                                    pl: 4,
                                    pr: 4,
                                    borderRadius: "6px",
                                  }}
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    setFieldTouched("occupation", true);
                                    validateField("occupation");
                                    validateField("pep");
                                    setFieldTouched("source_of_income", true);
                                    validateField("source_of_income");
                                    validateField("remarks");
                                    setFieldTouched("ckyc_number", true);
                                    validateField("ckyc_number");
                                    if (
                                      !!values?.ckyc_number &&
                                      !errors?.occupation &&
                                      !errors?.source_of_income &&
                                      !errors?.pep &&
                                      !errors?.remarks &&
                                      !errors?.ckyc_number
                                    ) {
                                      cKycValidate({
                                        additionalPayload: {
                                          occupation: values?.occupation,
                                          pep: values?.pep ? "1" : "0",
                                          source_of_income:
                                            values?.source_of_income,
                                          ...(values?.pep && {
                                            remarks: values?.remarks,
                                          }),
                                        },
                                        ic_id: 2,
                                        payload: values?.ckyc_number,
                                        mode: "ckyc_number",
                                        company_alias: "star",
                                      });
                                    }
                                  }}
                                >
                                  {cKycUploadLoader?.pan_card
                                    ? "Loading..."
                                    : "Validate CKYC"}
                                </Button>
                              </Grid>
                            </>
                          )}
                          {!values?.is_ckyc_no && (
                            <>
                              <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{ padding: "16px" }}
                              >
                                <FormikSideEffect
                                  effect={() => {
                                    if (values?.document_type) {
                                      setCkycType(values?.document_type);
                                    } else {
                                      setCkycType("");
                                    }
                                    if (isCkycSuccessFull === false) {
                                      setFieldValue("document_type", "form60");
                                    }
                                    return dispatch(setCKycResponse({}));
                                  }}
                                  dependencies={[
                                    values?.document_type,
                                    isCkycSuccessFull,
                                  ]}
                                >
                                  <Select
                                    fixedLabel={false}
                                    name="document_type"
                                    label="Select Document*"
                                    options={documentOptions}
                                    selectOnly={true}
                                  />
                                </FormikSideEffect>
                              </Grid>
                              {values?.document_type === "pan_card" && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={8}
                                    md={3}
                                    sx={{ padding: "16px" }}
                                  >
                                    <TextInput
                                      name={`pan_no`}
                                      label="PAN No."
                                      values={pan_no}
                                      onInput={upperCase}
                                      inputProps={{ readOnly: true }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    md={3}
                                    sx={{ padding: "16px" }}
                                  >
                                    <Button
                                      fullWidth={isMobile ? true : false}
                                      sx={{
                                        textTransform: "none",
                                        fontSize: isMobile ? "13px" : "16px",
                                        p: 2,
                                        pl: 4,
                                        pr: 4,
                                        borderRadius: "6px",
                                      }}
                                      variant="contained"
                                      color="secondary"
                                      onClick={() => {
                                        validateField("occupation");
                                        validateField("pep");
                                        validateField("source_of_income");
                                        validateField("remarks");
                                        validateField("pan_no");
                                        if (
                                          !!values?.pan_no &&
                                          !errors?.occupation &&
                                          !errors?.source_of_income &&
                                          !errors?.pep &&
                                          !errors?.remarks &&
                                          !errors?.pan_no
                                        ) {
                                          cKycValidate({
                                            additionalPayload: {
                                              occupation: values.occupation,
                                              pep: values?.pep ? "1" : "0",
                                              source_of_income:
                                                values?.source_of_income,
                                              ...(values?.pep && {
                                                remarks: values?.remarks,
                                              }),
                                            },
                                            ic_id: 2,
                                            payload: pan_no,
                                            mode: "pan_card",
                                            company_alias: "star",
                                          });
                                        }
                                      }}
                                    >
                                      {cKycValidateLoading ? (
                                        <ButtonLoader />
                                      ) : cKycUploadLoader?.pan_card ? (
                                        "Loading..."
                                      ) : (
                                        "Validate PAN"
                                      )}
                                    </Button>
                                  </Grid>
                                </>
                              )}
                              {values?.document_type === "form60" && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{ padding: "16px" }}
                                  >
                                    <FileUploadContainer
                                      label="Form 60*"
                                      error={
                                        touched?.form60_upload &&
                                        errors?.form60_upload
                                      }
                                    >
                                      <FileUploadStyled
                                        type="file"
                                        name="form60_upload"
                                        id="form60_upload"
                                        className="form-control"
                                        accept="image/*,.pdf"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "form60_upload",
                                            e?.target?.files[0]
                                          );
                                        }}
                                        error={
                                          touched?.form60_upload &&
                                          errors?.form60_upload
                                        }
                                      />
                                    </FileUploadContainer>
                                    {touched?.form60_upload &&
                                      errors?.form60_upload && (
                                        <InputErrorMessage>
                                          {errors?.form60_upload}
                                        </InputErrorMessage>
                                      )}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{ padding: "16px" }}
                                  >
                                    <Select
                                      fixedLabel={false}
                                      name="poi_document"
                                      label="Proof of Identity*"
                                      options={identityProofOptions}
                                      selectOnly={true}
                                      error={
                                        touched?.poi_document &&
                                        errors?.poi_document
                                      }
                                      // helperText={errors?.poi_document}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{ padding: "16px" }}
                                  >
                                    <FileUploadContainer
                                      label="Identity Proof*"
                                      error={
                                        touched?.poi_document_upload &&
                                        errors?.poi_document_upload
                                      }
                                    >
                                      <FileUploadStyled
                                        type="file"
                                        name="poi_document_upload"
                                        id="poi_document_upload"
                                        className="form-control"
                                        accept="image/*,.pdf"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "poi_document_upload",
                                            e?.target?.files[0]
                                          );
                                        }}
                                        error={
                                          touched?.poi_document_upload &&
                                          errors?.poi_document_upload
                                        }
                                      />
                                    </FileUploadContainer>
                                    {touched?.poi_document_upload &&
                                      errors.poi_document_upload && (
                                        <InputErrorMessage>
                                          {errors.poi_document_upload}
                                        </InputErrorMessage>
                                      )}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{ padding: "16px" }}
                                  >
                                    <TextInput
                                      name={`poi_document_unique_id`}
                                      label="Identity Number"
                                      onInput={upperCase}
                                      required
                                      error={
                                        touched?.poi_document_unique_id &&
                                        errors?.poi_document_unique_id
                                      }
                                      // helperText={
                                      //   errors?.poi_document_unique_id
                                      // }
                                      inputProps={{
                                        minLength:
                                          values?.poi_document === "1"
                                            ? 8
                                            : values?.poi_document === "4"
                                            ? 12
                                            : 0,
                                        maxLength:
                                          values?.poi_document === "1"
                                            ? 8
                                            : values?.poi_document === "4"
                                            ? 12
                                            : 20,
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{ padding: "16px" }}
                                  >
                                    <FileUploadContainer
                                      label="Photo ID*"
                                      error={
                                        touched?.kyc_upload_photo_id &&
                                        errors?.kyc_upload_photo_id
                                      }
                                    >
                                      <FileUploadStyled
                                        type="file"
                                        name="kyc_upload_photo_id"
                                        id="kyc_upload_photo_id"
                                        className="form-control"
                                        accept="image/*,.pdf"
                                        onChange={(e) => {
                                          setFieldValue(
                                            "kyc_upload_photo_id",
                                            e?.target?.files[0]
                                          );
                                        }}
                                        error={
                                          touched?.kyc_upload_photo_id &&
                                          errors?.kyc_upload_photo_id
                                        }
                                      />
                                    </FileUploadContainer>
                                    {touched?.kyc_upload_photo_id &&
                                      errors.kyc_upload_photo_id && (
                                        <InputErrorMessage>
                                          {errors.kyc_upload_photo_id}
                                        </InputErrorMessage>
                                      )}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{ padding: "16px" }}
                                  >
                                    <Select
                                      fixedLabel={false}
                                      name="family_relationship"
                                      label="Family Member*"
                                      options={familyRelationOptions}
                                      selectOnly={true}
                                      error={
                                        touched?.family_relationship &&
                                        errors?.family_relationship
                                      }
                                      // helperText={errors?.family_relationship}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{ padding: "16px" }}
                                  >
                                    <TextInput
                                      name={`family_member_name`}
                                      label="Family Member Name"
                                      required
                                      onInput={allowFullName}
                                      inputProps={{
                                        minLength: 2,
                                        maxLength: 50,
                                      }}
                                      error={
                                        touched?.family_member_name &&
                                        errors?.family_member_name
                                      }
                                      // helperText={errors?.family_member_name}
                                    />
                                  </Grid>
                                </>
                              )}
                            </>
                          )}
                        </Grid>
                      </div>
                    )}
                    {cKycStatus && (
                      <CkycVerified
                        innerText={"Your KYC is complete, you can proceed."}
                      />
                    )}
                    {(cKycStatus || values?.document_type === "form60") && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          p: 2,
                        }}
                      >
                        <Button
                          fullWidth={isMobile ? true : false}
                          sx={{
                            textTransform: "none",
                            fontSize: isMobile ? "13px" : "16px",
                            p: 2,
                            pl: 4,
                            pr: 4,
                            borderRadius: 1,
                          }}
                          variant="contained"
                          color="secondary"
                          type="submit"
                        >
                          {isLoading ? <ButtonLoader /> : "Proceed to Traveler"}
                        </Button>
                      </Box>
                    )}
                  </div>
                </Card>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </>
  );
};
export default CkycStar;
