import { useLocation, useNavigate } from "react-router-dom";

const useUrlQuery = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);

  const getQuery = (key) => query.get(key);
  const setQuery = (key, value) => {
    query.set(key, value);
    navigate(`${location.pathname}?${query.toString()}`, {
      replace: true,
    });
  };
  return {
    getQuery,
    setQuery,
  };
};

export default useUrlQuery;
