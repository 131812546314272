export const documentTypeOptions = [
  { display_name: "Professional", code: 1 },
  { display_name: "Self Employed", code: 2 },
  { display_name: "Retired", code: 3 },
  { display_name: "House-wife", code: 4 },
  { display_name: "Student", code: 5 },
];
export const sourceOfIncomeOptions = [
  { display_name: "Salary", code: 1 },
  { display_name: "Business Income", code: 2 },
  { display_name: "Sale of Assets", code: 3 },
  { display_name: "Inheritance", code: 4 },
];
export const documentOptions = [
  { display_name: "Form60", code: "form60" },
  { display_name: "PAN Card", code: "pan_card" },
];
export const familyRelationOptions = [
  { display_name: "Father", code: "1" },
  { display_name: "Spouse", code: "2" },
  { display_name: "Mother", code: "3" },
];
export const identityProofOptions = [
  { display_name: "Passport", code: "1" },
  { display_name: "Voter ID", code: "2" },
  { display_name: "Driving License", code: "3" },
  { display_name: "Proof of Possession of Aadhaar", code: "4" },
  { display_name: "NREGA Job Card", code: "5" },
  { display_name: "National Population Register Letter", code: "6" },
];

export const starMedicalQuestions = [
  {
    code: 1,
    question: "Does you have any existing diseases/health conditions?",
    description: "select",
    // description: "select"||"text"||"none",
    description_question: "Please provide details",
    description_question_options: [],
    other_selected_question:
      "Please provide other diseases/health condition details",
  },
  {
    code: 2,
    question: "Do you have a Family Physician?",
    description: "none",
    description_question: "Please Specify",
    description_question_options: [],
    other_selected_question: "",
  },
  {
    code: 3,
    question:
      "Have you ever had any hospitalization or health complications related to Diabetes/Hypertension?",
    description: "none",
    description_question: "",
    description_question_options: [],
  },
];
