/* eslint-disable eqeqeq */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container } from "@mui/system";
import { useSelector } from "react-redux";
import { useState } from "react";

export default function MobileKYP() {
  const { know_more_data } = useSelector((state) => state.quote);
  const { ic_data } = know_more_data;

  return (
    <>
      <Container sx={{ mt: 2, pb: 23 }}>
        <KYPAccordion
          label={"Important Features"}
          data={
            <>
              <AccCard
                label={"Medical Loss"}
                data={"10000"}
                deductible={"100"}
              />
              <AccCard
                label={"Baggage Loss"}
                data={"10000"}
                deductible={"100"}
              />
              <AccCard
                label={"Trip Cancelation"}
                data={"10000"}
                deductible={"100"}
              />
              <AccCard
                label={"Life Threatening Situations due to PED"}
                data={"10000"}
                deductible={"100"}
              />
              <AccCard
                label={"Loss Of passport"}
                data={"10000"}
                deductible={"100"}
              />
            </>
          }
        />
        <KYPAccordion
          label={"Trip Related Cover"}
          data={
            <>
              <AccCard
                label={"Trip Cancelation"}
                data={"10000"}
                deductible={"100"}
              />
              <AccCard label={"Trip Delay"} data={"10000"} deductible={"100"} />
              <AccCard
                label={"Missed Connection"}
                data={"10000"}
                deductible={"100"}
              />
              <AccCard
                label={"Hijacking Benefits"}
                data={"10000"}
                deductible={"100"}
              />
              <AccCard
                label={"Automatic Trip Extension"}
                data={"10000"}
                deductible={"100"}
              />
              <AccCard
                label={"Trip Curtailment"}
                data={"10000"}
                deductible={"100"}
              />
            </>
          }
        />
        <KYPAccordion
          label={"Baggage Related Covers"}
          data={
            <>
              {" "}
              <AccCard
                label={"Baggage Loss"}
                data={"10000"}
                deductible={"100"}
              />
              <AccCard
                label={"Loss Of Passport"}
                data={"10000"}
                deductible={"100"}
              />
              <AccCard
                label={"Missed Connection"}
                data={"10000"}
                deductible={"100"}
              />
              <AccCard
                label={"Personal Liability"}
                data={"10000"}
                deductible={"100"}
              />
              <AccCard
                label={"Loss of International Driving Lisence"}
                data={"10000"}
                deductible={"100"}
              />
            </>
          }
        />
        <KYPAccordion
          label={"Medical Covers"}
          data={
            <>
              <AccCard
                label={"Medical Expenses"}
                data={"10000"}
                deductible={"100"}
              />
              <AccCard
                label={"Dental Treatment"}
                data={"10000"}
                deductible={"100"}
              />
              <AccCard
                label={"Personal Accident"}
                data={"10000"}
                deductible={"100"}
              />
              <AccCard
                label={"Medical Evacuation"}
                data={"10000"}
                deductible={"100"}
              />
              <AccCard
                label={"Repatriation of Mortal Remains"}
                data={"10000"}
                deductible={"100"}
              />
              <AccCard
                label={"Daily Allowances"}
                data={"10000"}
                deductible={"100"}
              />
            </>
          }
        />
      </Container>
      <Grid
        container
        spacing={4}
        sx={{
          position: "fixed",
          backgroundColor: "#fff",
          bottom: 70,
          left: 0,
          right: 0,
          p: 2,
        }}
      >
        <Grid item xs={6}>
          <Button
            target={"_blank"}
            download="Brochure"
            href={ic_data[0].travel_broucher}
            sx={{
              width: "100%",
              fontSize: "12px",
              fontWeight: "600",
              border: "2px solid var(--primary-color)",
              color: "var(--primary-color)",
              pt: 1.5,
              pb: 1.5,
            }}
            variant="text"
          >
            Brochure
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            target={"_blank"}
            download="Policy Wording"
            href={ic_data[0].policy_wording}
            sx={{
              width: "100%",
              fontSize: "12px",
              fontWeight: "600",
              border: "2px solid var(--primary-color)",
              color: "var(--primary-color)",
              pt: 1.5,
              pb: 1.5,
            }}
            variant="text"
          >
            Policy Wording
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

const AccCard = ({ label, data, deductible }) => {
  const { know_more_data } = useSelector((state) => state.quote);
  const { ic_data } = know_more_data;
  const { leadData } = useSelector((state) => state.landing);
  const currency = leadData?.currency;
  return (
    <Card
      sx={{
        p: 1,
        backgroundColor: "var(--primary-light)",
        boxShadow: "none",
        border: "1px solid rgba(165, 176, 250, 0.5)",
        m: 1,
      }}
    >
      <Stack>
        <Typography
          variant="h6"
          fontSize="14px"
          fontWeight="500"
          color={"var(--primary-color)"}
        >
          {label}
        </Typography>
        <Stack alignItems="center" direction="row" spacing={1}>
          {ic_data[0].id == 1 && currency == 2 ? (
            <Typography variant="h6" fontSize="14px" fontWeight="700">
              £{data}
            </Typography>
          ) : (
            <Typography variant="h6" fontSize="14px" fontWeight="700">
              ${data}
            </Typography>
          )}
          {ic_data[0].id == 1 && currency == 2 ? (
            <span
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: "rgba(0, 0, 0, 0.7)",
              }}
            >
              Deductible-£{deductible}
            </span>
          ) : (
            <span
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: "rgba(0, 0, 0, 0.7)",
              }}
            >
              Deductible-${deductible}
            </span>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

const KYPAccordion = ({ label, data }) => {
  const [change, setChange] = useState(false);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  return (
    <Box sx={{ mb: 2 }}>
      <Accordion
        sx={{
          boxShadow: change ? "4px 4px 0px rgba(103, 128, 254, 0.1)" : "none",
          border: "1px solid var(--primary-color)",
          mb: 2,
          borderRadius: "5px",
        }}
      >
        <AccordionSummary
          onClick={() => setChange(!change)}
          expandIcon={<ExpandMoreIcon sx={{ color: "var(--primary-color)" }} />}
          sx={{ minHeight: isTablet ? "70px" : "" }}
        >
          <span
            style={{
              color: "var(--primary-color)",
              fontSize: isTablet ? "16px" : "14px",
            }}
          >
            {label}
          </span>
        </AccordionSummary>
        <AccordionDetails sx={{ mt: -2, mb: 2, color: "var(--primary-color)" }}>
          {data}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
