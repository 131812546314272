import {
  Button,
  Card,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearCompareQuote, removeQuote } from "../../../modules/quote.slice";
import cancel from "../../../assets/images/cancel.png";
import { useNavigate } from "react-router-dom";
import useUrlQuery from "../../../hooks/useUrlQuery";
import { useGetUserDetails } from "../../../services/quoteService";
import { Cancel } from "@mui/icons-material";

export default function ComparePopup() {
  const compareQuote = useSelector((state) => state.quote.compareQuote);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const { userDetails } = useGetUserDetails();

  return (
    <Card
      sx={{
        p: 2,
        boxShadow: 10,
        position: "fixed",
        bottom: 0,
        width: "100%",
      }}
    >
      {isMobile && (
        <IconButton
          onClick={() => dispatch(clearCompareQuote())}
          sx={{ position: "absolute", top: 3, right: 3 }}
        >
          <Cancel />
        </IconButton>
      )}
      <Grid justifyContent={"center"} alignItems="center" container>
        <Grid item xs={6} md={3}>
          <Card sx={{ p: 2, mr: 2, minHeight: isMobile ? 90 : 95 }}>
            {compareQuote[0]?.ic_data[0]?.id ? (
              <Grid container>
                <Grid item xs={11.5} md={11}>
                  <Stack alignItems={"center"} spacing={2} direction="row">
                    <img
                      style={{ padding: 1 }}
                      src={
                        userDetails.company_details[
                          compareQuote[0].ic_data[0].company_alias
                        ].logo
                      }
                      width={isTablet ? "60px" : isMobile ? "25px" : "50px"}
                      height={"100%"}
                      alt=""
                    />
                    <Stack spacing={1}>
                      <Typography
                        variant="h6"
                        fontSize={isTablet ? "16px" : isMobile ? "8px" : "14px"}
                        fontWeight={800}
                      >
                        {compareQuote[0].data[0]?.plan_name}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontSize={isTablet ? "16px" : isMobile ? "7px" : "14px"}
                        sx={{ color: "gray" }}
                        fontWeight={600}
                      >
                        Sum Insured : {compareQuote[0].data[0].sum_insured}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={0.5} md={1}>
                  <img
                    src={cancel}
                    style={{
                      width: isMobile ? "10px" : "15px",
                      height: isMobile ? "10px" : "15px",
                      padding: 1,
                      mt: 0,
                    }}
                    alt=""
                    onClick={(e) => {
                      dispatch(removeQuote(0));
                    }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Typography variant="h6" fontSize="16px" mt={1} fontWeight={800}>
                Add Quotes
              </Typography>
            )}
          </Card>
        </Grid>

        <Grid item xs={6} md={3}>
          <Card sx={{ p: 2, mr: 2, minHeight: isMobile ? 90 : 95 }}>
            {compareQuote[1]?.ic_data[0]?.id ? (
              <Grid container>
                <Grid item xs={11.5} md={11}>
                  <Stack alignItems={"center"} spacing={2} direction="row">
                    <img
                      style={{ padding: 1 }}
                      src={
                        userDetails.company_details[
                          compareQuote[1].ic_data[0].company_alias
                        ].logo
                      }
                      width={isTablet ? "60px" : isMobile ? "25px" : "50px"}
                      height={"100%"}
                      alt=""
                    />
                    <Stack spacing={1}>
                      <Typography
                        variant="h6"
                        fontSize={isMobile ? "8px" : "14px"}
                        fontWeight={800}
                      >
                        {compareQuote[1].data[0]?.plan_name}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontSize={isMobile ? "7px" : "14px"}
                        sx={{ color: "gray" }}
                        fontWeight={600}
                      >
                        Sum Insured : {compareQuote[1].data[0].sum_insured}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={0.5} md={1}>
                  <img
                    src={cancel}
                    style={{
                      width: isMobile ? "10px" : "15px",
                      height: isMobile ? "10px" : "15px",
                      padding: 1,
                    }}
                    onClick={(e) => {
                      dispatch(removeQuote(1));
                    }}
                    alt=""
                  />
                </Grid>
              </Grid>
            ) : (
              <Typography
                color="gray"
                variant="h6"
                fontSize="16px"
                mt={2}
                textAlign="center"
                fontWeight={600}
              >
                Select Plan To Compare
              </Typography>
            )}
          </Card>
        </Grid>
        {isMobile ? null : (
          <Grid item xs={3}>
            <Card sx={{ p: 2, mr: 2, minHeight: 95 }}>
              {compareQuote[2]?.ic_data[0]?.id ? (
                <Grid container>
                  <Grid item xs={11}>
                    <Stack alignItems={"center"} spacing={2} direction="row">
                      <img
                        style={{ padding: 1 }}
                        src={
                          userDetails.company_details[
                            compareQuote[2].ic_data[0].company_alias
                          ].logo
                        }
                        width="50px"
                        height={"100%"}
                        alt=""
                      />
                      <Stack spacing={1}>
                        <Typography
                          variant="h6"
                          fontSize="14px"
                          fontWeight={800}
                        >
                          {compareQuote[2].data[0]?.plan_name}
                        </Typography>
                        <Typography
                          variant="h6"
                          fontSize="14px"
                          sx={{ color: "gray" }}
                          fontWeight={600}
                        >
                          Sum Insured : {compareQuote[2].data[0].sum_insured}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={1}>
                    <img
                      src={cancel}
                      style={{
                        width: "15px",
                        height: "15px",
                        padding: 1,
                      }}
                      alt=""
                      onClick={(e) => {
                        dispatch(removeQuote(2));
                      }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Typography
                  color="gray"
                  variant="h6"
                  fontSize="16px"
                  mt={2}
                  textAlign="center"
                  fontWeight={600}
                >
                  Select Plan To Compare
                </Typography>
              )}
            </Card>
          </Grid>
        )}
        <Grid
          sx={{ p: isMobile ? 1 : 2, marginLeft: isTablet ? "25px" : "" }}
          item
          xs={isTablet ? 3 : 6}
          md={3}
        >
          <Button
            sx={{
              mr: 2,
              border: "none",
              textTransform: "none",
              fontSize: "0.875rem",
              padding: "5px 15px",
              borderRadius: "5px",
            }}
            variant="contained"
            color={"primary"}
            disabled={compareQuote.length <= 1}
            onClick={() => {
              navigate({
                pathname: "/compare",
                search: `?enquiry_id=${enquiry_id}`,
              });
            }}
          >
            Compare now
          </Button>
          {isMobile ? null : (
            <Button
              onClick={(e) => dispatch(clearCompareQuote())}
              sx={{
                border: "none",
                textTransform: "none",
                backgroundColor: "#ededed",
                color: "gray",
              }}
              variant="outlined"
            >
              Close
            </Button>
          )}
        </Grid>
      </Grid>
    </Card>
  );
}
