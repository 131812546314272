import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import customer from "./../../assets/images/customer-service (1).png";
import { Formik } from "formik";
import React from "react";
import ButtonWrapper from "../InputComponents/ButtonWrapper";
import TextInput from "../InputComponents/TextInput";
import { allowOnlyNumbers } from "../../utils/input";
import * as yup from "yup";
import { useGetFrontendBoot } from "../../services/landingService";
import { useSelector } from "react-redux";

export default function TalkToExpert() {
  const theme = useTheme();
  const { settings } = useGetFrontendBoot();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const { leadData } = useSelector((state) => state.landing);

  return (
    <Box sx={{ p: isTablet ? "40px" : 3, mt: 0, maxWidth: 420 }}>
      <Formik
        initialValues={{
          phone: leadData?.insured_mobile || leadData?.proposer_mobile_no,
        }}
        validationSchema={yup.object().shape({
          phone: yup
            .string()
            .min(10, "Enter valid Mobile Number")
            .max(10, "Enter valid Mobile Number")
            .matches(/^[6-9]\d{9}$/, "Please enter a valid mobile number")
            .required("Mobile Number is Required"),
        })}
        onSubmit={(value) => {}}
      >
        {() => {
          return (
            <Grid justifyContent="center" container spacing={2}>
              <Grid item xs={5.5}>
                <img
                  src={customer}
                  style={{
                    height: "133px",
                    width: "133px",
                  }}
                  alt="customer"
                />
              </Grid>
              <Grid item xs={11}>
                <Box sx={{ backgroundColor: "#fff" }}>
                  <Typography
                    fontWeight={"700"}
                    sx={{ textAlign: "center" }}
                    fontFamily={"Poppins"}
                    fontSize={isTablet ? "16px" : isMobile ? "12px" : "12px"}
                    variant="h4"
                    color={"#000000"}
                  >
                    Please confirm your mobile number and we will get back to
                    you shortly.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={11}>
                <Box sx={{ backgroundColor: "#fff" }}>
                  <TextInput
                    label="Mobile Number"
                    name="phone"
                    onInput={allowOnlyNumbers}
                    inputProps={{ inputMode: "numeric", maxLength: 10 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={11}>
                <ButtonWrapper radius={"10px"}>Get Call Back</ButtonWrapper>
              </Grid>
              <Grid item xs={8}>
                <Box sx={{ backgroundColor: "#fff" }}>
                  <Typography
                    sx={{
                      marginTop: "10px",
                      fontSize: isTablet ? "16px" : isMobile ? "10px" : "14px",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      textAlign: "center",
                      color: "#000000",
                    }}
                    variant="h5"
                  >
                    {" "}
                    In a hurry? Call Us On Our Toll Free No. {settings.mobile}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </Box>
  );
}
