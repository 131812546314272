import { useQuery, useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../api/api";
import { setActiveStep, setTraceId, setUuid } from "../modules/landing.slice";

const useGetCountries = () => {
  const { data, isLoading } = useQuery(["countries"], () =>
    api.get("/get/countries")
  );
  return {
    countries: data?.data?.data,
    isCountriesLoading: isLoading,
  };
};
const useCreateQuote = () => {
  const { leadData } = useSelector((state) => state.landing);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, isLoading, mutate } = useMutation(
    (req) => api.post("/create/quote", { ...leadData, ...req }),
    {
      onSuccess: (data) => {
        dispatch(setTraceId(data?.data?.trace_id));
        navigate({
          pathname: "/quote",
          search: `?enquiry_id=${data.data.enquiry_id}`,
        });
      },
    }
  );

  return {
    isLoading,
    data,
    createQuote: mutate,
  };
};
const usePatchQuote = () => {
  const { leadData } = useSelector((state) => state.landing);
  const queryClient = useQueryClient();
  const { plan_type, max_annual_travel_days, ...remaining } = leadData;
  const { data, isLoading, mutate } = useMutation(
    (req) => api.patch("/update/quote", { ...remaining, ...req }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["details"]);
        // queryClient.invalidateQueries("plans");
        queryClient.removeQueries("plans");
        queryClient.refetchQueries("plans");
      },
    }
  );

  return {
    isLoading,
    data,
    patchQuote: mutate,
  };
};
const useGetFrontendBoot = () => {
  const { data, isLoading } = useQuery(
    ["frontendBoot"],
    () => api.get("/travel/frontend-boot"),
    {
      onSuccess: (data) => {
        // update css variables
        const settings = data?.data?.travelsettings;
        if (settings) {
          // document.documentElement.style.setProperty(
          //   "--primary-color",
          //   "green"
          // );
        }
      },
    }
  );

  return {
    frontendBoot: data?.data,
    settings: data?.data?.travelsettings,
    sum_insured: data?.data?.travelsuminsured,
    isFrontendBootLoading: isLoading,
  };
};
// Send OTP
const useSendOtpToVerify = () => {
  const { leadData } = useSelector((state) => state.landing);
  const dispatch = useDispatch();
  const { data, isLoading, mutate } = useMutation(
    (req) =>
      api.post("/travel/fyntune/otp_verification", {
        ...req,
        mobile: leadData?.insured_mobile,
        email: leadData?.insured_email,
        section: "travel",
      }),
    {
      onSuccess: (data) => {
        dispatch(setUuid(data?.data?.uuid));
      },
    }
  );
  return {
    isLoading,
    data,
    sendOtp: mutate,
  };
};

// Verify OTP
const useVerifyOtp = () => {
  const { uuid } = useSelector((state) => state.landing);
  const dispatch = useDispatch();
  const { data, isLoading, mutate } = useMutation(
    (req) =>
      api.post("/travel/fyntune/verify_mobile", {
        ...req,
        uuid,
      }),
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          dispatch(setActiveStep(1));
        }
      },
    }
  );

  return {
    isLoading,
    data,
    verifyOTP: mutate,
  };
};

export {
  useGetFrontendBoot,
  useGetCountries,
  useCreateQuote,
  usePatchQuote,
  useVerifyOtp,
  useSendOtpToVerify,
};
