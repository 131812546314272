/* eslint-disable eqeqeq */
import React from "react";
import {
  Box,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
  Collapse,
  useMediaQuery,
  Checkbox,
  Button,
  IconButton,
} from "@mui/material";
import { QuoteButton } from "./components/QuoteButton";
import { useDispatch, useSelector } from "react-redux";
import KnowMoreB from "./components/KnowMoreB";
import {
  addQuote,
  addQuoteToShortlist,
  hideMoreQuotes,
  removeQuoteFromCompare,
  setKnowMoreData,
  setQuotesToShare,
  showMoreQuotes,
} from "../../../../modules/quote.slice";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import QuoteLoader from "./components/QuoteLoader";
import {
  useGetUserDetails,
  useStoreSelectedPlanMutation,
} from "../../../../services/quoteService";
import useUrlQuery from "../../../../hooks/useUrlQuery";
import { Bookmark, BookmarkBorderOutlined } from "@mui/icons-material";
import wrong from "../../../../assets/images/pwrong.png";
import right from "../../../../assets/images/pright.png";
import ButtonLoader from "../../../../components/ButtonLoader/ButtonLoader";

export default function QuoteCard({
  count,
  color,
  quoteData,
  isLoading,
  showMorePlans,
  isVisible,
  recommended,
  index,
  quoteLength,
  ...props
}) {
  const dispatch = useDispatch();
  const {
    show_more_quotes,
    shareSelectedQuotes,
    quotesToShare,
    shortListedQuotes,
  } = useSelector((state) => state.quote);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const navigate = useNavigate();
  const location = useLocation();
  const { userDetails } = useGetUserDetails();
  const compareQuote = useSelector((state) => state.quote.compareQuote);
  const {
    filters: { sum_insured },
  } = useSelector((state) => state.landing);
  const handleCompare = (event) => {
    if (event.target.checked && !isMobile && compareQuote.length < 3) {
      dispatch(addQuote(quoteData));
    } else if (event.target.checked && isMobile && compareQuote.length < 2) {
      dispatch(addQuote(quoteData));
    } else {
      dispatch(removeQuoteFromCompare(quoteData));
    }
  };
  const handleShare = (e) => {
    if (e.target.checked) {
      dispatch(setQuotesToShare([...quotesToShare, quoteData]));
    } else {
      dispatch(
        setQuotesToShare(
          quotesToShare.filter(
            (item) => item.data[0]?.plan_name !== data[0]?.plan_name
          )
        )
      );
    }
  };
  const data = quoteData?.data;
  const ic_data = quoteData?.ic_data;
  const { leadData } = useSelector((state) => state.landing);
  const currency = leadData?.currency;
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { storeSelectedPlan, isStoreSelectedPlanLoading } =
    useStoreSelectedPlanMutation();
  // baggage loss
  const baggage_loss = quoteData?.data[0]?.plan_feature[0]?.feature_values.find(
    (item) => item.cover === "Loss of checked-in baggage"
  )?.sum_insured;

  const baggage_loss_value =
    ic_data[0].id == 1 && currency == 2 && baggage_loss
      ? `£ ${Number(baggage_loss).toLocaleString("en-IN")}`
      : baggage_loss
      ? `$ ${Number(baggage_loss).toLocaleString("en-IN")}`
      : "Not Covered";
  // trip cancellation
  const trip_cancellation =
    quoteData?.data[0]?.plan_feature[0]?.feature_values.find(
      (item) => item.cover === "Trip Cancellation"
    )?.sum_insured;
  const trip_cancellation_value =
    ic_data[0].id == 1 && currency == 2 && trip_cancellation
      ? `£ ${Number(trip_cancellation).toLocaleString("en-IN")}`
      : trip_cancellation
      ? `$ ${Number(trip_cancellation).toLocaleString("en-IN")}`
      : "Not Covered";
  // loss of passport
  const loss_of_passport =
    quoteData?.data[0]?.plan_feature[0]?.feature_values.find(
      (item) => item.cover === "Loss of Passport"
    )?.sum_insured;
  const loss_of_passport_value =
    ic_data[0].id == 1 && currency == 2 && loss_of_passport
      ? `£ ${Number(loss_of_passport).toLocaleString("en-IN")}`
      : loss_of_passport
      ? `$ ${Number(loss_of_passport).toLocaleString("en-IN")}`
      : "Not Covered";
  const onBuyNow = () => {
    storeSelectedPlan({
      base_premium: data[0]?.base_premium_amount,
      enquiry_id: enquiry_id,
      ic_id: ic_data[0]?.id,
      plan_details: data,
      plan_id: data[0]?.plan_id,
      plan_code: String(data[0]?.plan_code),
      plan_name: data[0]?.plan_name,
      sum_insured: data[0]?.sum_insured,
      tax: data[0]?.tax_amount,
      alias: ic_data[0]?.company_alias,
      total_premium: data[0]?.total_premium_amount,
      travel_insurance_id: data[0]?.travel_insurance_id,
    });
  };

  const isQuoteShortlisted = shortListedQuotes.some(
    (item) => item.data[0]?.plan_name === quoteData?.data[0]?.plan_name
  );
  const isSelectedToShare = quotesToShare.some(
    (quote) => quote.data[0]?.plan_name === quoteData.data[0]?.plan_name
  );
  const isSelectedToCompare = compareQuote.some(
    (item) => item.data[0]?.plan_name === quoteData?.data[0]?.plan_name
  );

  if (isLoading) {
    return <QuoteLoader />;
  }
  if (!isLoading && !quoteData.status) return null;
  if (isLoading || !quoteData) return null;
  if (!data || !ic_data) return null;
  if (quoteData === null) return null;
  if (isMobile) {
    return (
      <>
        <Collapse in={isVisible}>
          <Box
            sx={{
              position: "relative",
              mt: isTablet ? "12px" : index === 0 ? 0 : 2,
              height: isTablet ? "180px" : "123px",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: "6px",
                right: "6px",
                width: "8px",
                height: "8px",
              }}
              color="primary"
              onClick={() => {
                dispatch(addQuoteToShortlist(quoteData));
              }}
            >
              {isQuoteShortlisted ? <Bookmark /> : <BookmarkBorderOutlined />}
            </IconButton>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Stack alignItems="center" spacing={1}>
                  <span
                    style={{
                      fontSize: isTablet ? "16px" : "8px",
                      fontWeight: "600",
                      color: "var(--primary-color)",
                    }}
                  >
                    {ic_data[0].pedCovered === 0
                      ? "PED Not Covered"
                      : "PED Covered"}
                  </span>
                  <Box
                    sx={{
                      width: isTablet ? "82px" : isMobile ? "60px" : "60px",
                      height: isTablet ? "61" : "40px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        userDetails.company_details[ic_data[0].company_alias]
                          .logo
                      }
                      alt="logo"
                      style={{
                        width: isTablet ? "89px" : "53px",
                        maxHeight: isTablet ? "78px" : "40px",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      position: "absolute",
                      bottom: isTablet ? "10px" : 6,
                      left: isTablet ? "50px" : 1,
                      p: 0,
                    }}
                  >
                    <KnowMoreB
                      recommended={recommended}
                      onClick={() => {
                        dispatch(setKnowMoreData(quoteData));
                        navigate({
                          pathname: "/know-more",
                          search: location.search,
                        });
                      }}
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack
                  sx={{ mb: 0.5 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      fontSize: isTablet ? "16px" : "10px",
                      color: "rgba(0, 0, 0, 0.7)",
                      marginBottom: "5px",
                    }}
                    variant="body1"
                  >
                    Trip Cancellation
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: isTablet ? "16px" : "11px",
                      fontWeight: "600",
                    }}
                    variant="h3"
                  >
                    {trip_cancellation_value}
                  </Typography>
                </Stack>
                <Stack
                  sx={{ mb: 0.5 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      fontSize: isTablet ? "16px" : "10px",
                      color: "rgba(0, 0, 0, 0.7)",
                      marginBottom: "5px",
                    }}
                    variant="body1"
                  >
                    Baggage Loss
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: isTablet ? "16px" : "11px",
                      fontWeight: "600",
                    }}
                    variant="body1"
                  >
                    {baggage_loss_value}
                  </Typography>
                </Stack>
                <Stack
                  sx={{ mb: 0 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    sx={{
                      fontSize: isTablet ? "16px" : "10px",
                      color: "rgba(0, 0, 0, 0.7)",
                      marginBottom: "5px",
                    }}
                    variant="body1"
                  >
                    Loss Of Passport
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: isTablet ? "16px" : "11px",
                      fontWeight: "600",
                    }}
                    variant="body1"
                  >
                    {loss_of_passport_value}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Box
                  sx={{
                    mt: isTablet ? 2 : 0,
                    p: 1,
                    width: isTablet ? "50%" : "",
                    marginLeft: isTablet ? "78px" : "10px ",
                  }}
                >
                  <QuoteButton onClick={onBuyNow}>
                    {isStoreSelectedPlanLoading ? (
                      <ButtonLoader />
                    ) : (
                      <>
                        <span
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "600",
                          }}
                        >
                          ₹{" "}
                        </span>
                        {data[0].total_premium_amount}
                      </>
                    )}
                  </QuoteButton>
                </Box>
                <Stack
                  sx={{
                    mt: isTablet ? "-22px" : -4,
                    ml: isTablet ? "0px" : -1.5,
                  }}
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                >
                  {shareSelectedQuotes ? (
                    <>
                      <Checkbox
                        style={{ transform: "scale(0.7)" }}
                        onClick={(event) => handleShare(event)}
                        checked={isSelectedToShare}
                        sx={{ color: "var(--primary-color)" }}
                      />
                      <Typography
                        fontFamily={"Poppins"}
                        fontSize={isTablet ? "16px" : "8px"}
                        variant="h6"
                        sx={{ color: "var(--primary-color)" }}
                      >
                        Share
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Checkbox
                        style={{
                          transform: "scale(0.7)",
                          marginLeft: isTablet ? "23px" : "",
                        }}
                        onClick={(event) => handleCompare(event)}
                        checked={isSelectedToCompare}
                        sx={{ color: "var(--primary-color)" }}
                      />
                      <Typography
                        variant="h6"
                        color="primary"
                        sx={{
                          ml: -1,
                          fontSize: isTablet ? "16px" : "9px",
                        }}
                      >
                        Compare
                      </Typography>
                    </>
                  )}
                </Stack>
                <Box sx={{ pl: 1 }}>
                  {showMorePlans ? (
                    <Button
                      fullWidth
                      sx={{
                        position: "absolute",
                        bottom: isTablet ? "6px" : 7,
                        right: isTablet ? "31px" : "10",
                        backgroundColor: recommended
                          ? "none"
                          : "var(--primary-light)",
                        borderRadius: isTablet ? "20px" : 3,
                        border: recommended
                          ? "1px solid var(--primary-color)"
                          : "none",
                        pt: "4px",
                        pb: "4px",
                        p: 1.2,
                        maxWidth: isTablet
                          ? "113px"
                          : isMobile
                          ? "86px"
                          : "50%",
                        maxHeight: isTablet
                          ? "30px"
                          : isMobile
                          ? "22px"
                          : "20px",
                        minWidth: isTablet ? "148px" : "",
                        textTransform: "none",
                        boxShadow: "none",
                      }}
                      disableTouchRipple
                      variant="outlined"
                      onClick={() => {
                        if (show_more_quotes[ic_data[0].id]) {
                          dispatch(hideMoreQuotes(ic_data[0].id));
                          return;
                        }
                        dispatch(showMoreQuotes(ic_data[0].id));
                      }}
                    >
                      <Typography
                        sx={{
                          mt: 0.2,
                          color: "var(--primary-color)",
                          fontSize: isTablet ? "12px" : "8px",
                          fontWeight: "600",
                        }}
                        variant="body1"
                      >
                        {show_more_quotes[ic_data[0].id]
                          ? "Hide plans"
                          : `+ ${quoteLength - 1} more plans`}
                      </Typography>
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      sx={{
                        position: "absolute",
                        bottom: isTablet ? "6px" : 7,
                        right: isTablet ? "31px" : 10,
                        backgroundColor: recommended
                          ? "none"
                          : "var(--primary-light)",
                        borderRadius: isTablet ? "20px" : 3,
                        border: recommended
                          ? "1px solid var(--primary-color)"
                          : "none",
                        p: 1,
                        maxWidth: isTablet
                          ? "113px"
                          : isMobile
                          ? "86px"
                          : "50%",
                        maxHeight: isMobile ? "24px" : "20px",
                        minWidth: isTablet ? "148px" : "",
                        textTransform: "none",
                        boxShadow: "none",
                        "&:disabled": {
                          border: "1px solid var(--primary-color)",
                        },
                      }}
                      disabled
                      variant="outlined"
                    >
                      <Typography
                        sx={{
                          mt: 0.2,
                          color: "var(--primary-color)",
                          fontSize: isTablet ? "12px" : "8px",
                          fontWeight: "600",
                        }}
                        variant="body1"
                      >
                        No More Plans
                      </Typography>
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          {show_more_quotes[ic_data[0].id] && (
            <Grid container>
              <Grid item xs={4}>
                <Divider flexItem sx={{ ml: 1, mr: 1, mt: 1 }} />
              </Grid>
              <Grid item xs={4}>
                <Divider flexItem sx={{ ml: 1, mr: 1, mt: 1 }} />
              </Grid>
              <Grid item xs={4}>
                <Divider flexItem sx={{ ml: 1, mr: 1, mt: 1 }} />
              </Grid>
            </Grid>
          )}
        </Collapse>
      </>
    );
  }

  // Desktop
  return (
    <>
      <Collapse in={isVisible}>
        <Box position="relative" width={"100%"} mt={index !== 0 && 2}>
          <IconButton
            sx={{
              position: "absolute",
              top: "14px",
              right: "6px",
              width: "20px",
              height: "20px",
            }}
            color="secondary"
            onClick={() => {
              dispatch(addQuoteToShortlist(quoteData));
            }}
          >
            {isQuoteShortlisted ? (
              <Bookmark />
            ) : (
              <BookmarkBorderOutlined sx={{ color: "black" }} />
            )}
          </IconButton>{" "}
          <Grid sx={{ pt: 1.2 }} container spacing={2}>
            <Grid sx={{ pt: 0 }} item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item md={4} xs={2}>
                  <Stack alignItems="center" spacing={1}>
                    <Stack
                      justifyContent={"center"}
                      sx={{ width: "85px", height: "80px" }}
                    >
                      <img
                        src={
                          userDetails.company_details[ic_data[0].company_alias]
                            .logo
                        }
                        alt="logo"
                        style={{
                          width: "80px",
                          minHeight: "auto",
                        }}
                      />
                    </Stack>
                    <Typography
                      fontSize={"13px"}
                      fontStyle={"normal"}
                      sx={{
                        overflow: "hidden",
                        textOverflow: " ellipsis",
                        color: "black",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {data[0]?.plan_name}
                    </Typography>
                  </Stack>
                </Grid>

                <Divider sx={{ mt: 2 }} orientation="vertical" flexItem />
                <Grid item md={7.5} xs={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Stack sx={{ pl: 2, pr: 6, mt: 1 }} spacing={2}>
                        <Stack justifyContent="space-between" direction="row">
                          <Typography
                            fontStyle={"normal"}
                            fontWeight={"500"}
                            sx={{
                              fontSize: "11px",
                              color: "rgba(0, 0, 0, 0.7)",
                              marginBottom: "5px",
                            }}
                            variant="body1"
                          >
                            Loss Of Passport
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              mt: -0.3,
                            }}
                            variant="h3"
                          >
                            {loss_of_passport_value}
                          </Typography>
                        </Stack>
                        <Stack justifyContent="space-between" direction="row">
                          <Typography
                            fontWeight={"500"}
                            sx={{
                              fontSize: "11px",
                              color: "rgba(0, 0, 0, 0.7)",
                              marginBottom: "5px",
                            }}
                            variant="body1"
                          >
                            Baggage Loss
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                              mt: -0.3,
                            }}
                            variant="h3"
                          >
                            {baggage_loss_value}
                          </Typography>
                        </Stack>
                        <Stack sx={{ mt: -1 }} direction="row">
                          <Typography
                            sx={{
                              fontSize: "11px",
                              color: "rgba(0, 0, 0, 0.7)",
                              marginBottom: "5px",
                              width: "85%",
                            }}
                            variant="body1"
                          >
                            PED-Covered
                          </Typography>
                          <Box sx={{ width: "15%", mt: -0.5 }}>
                            {ic_data[0].pedCovered === 0 ? (
                              <img
                                src={wrong}
                                width="18px"
                                height="18px"
                                alt="wrong"
                              />
                            ) : (
                              <img
                                src={right}
                                width="18px"
                                height="18px"
                                alt="right"
                              />
                            )}
                          </Box>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              sx={{
                mt: 1,
                width: 0,
                height: "calc(100%-11px)",
              }}
              orientation={"vertical"}
              flexItem
            />

            <Grid item xs={12} md={5.7}>
              <Grid
                spacing={1}
                justifyContent="center"
                alignItems="center"
                container
                display="flex"
                xs={12}
              >
                <Grid item md={7.5}>
                  <Stack spacing={2}>
                    <Stack
                      justifyContent="space-between"
                      direction={"row"}
                      alignItems="center"
                      spacing={2}
                      sx={{ pr: 3, mt: -0.3 }}
                    >
                      <Typography
                        sx={{
                          fullWidth: true,
                          fontSize: "11px",
                          fontWeight: "500",
                          color: "rgba(0, 0, 0, 0.7)",
                          width: "50%",
                        }}
                        variant="body1"
                      >
                        Sum insured
                      </Typography>
                      {ic_data[0].id == 1 && currency == 2 ? (
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            width: "50%",
                          }}
                          variant="h3"
                        >
                          £ {Number(sum_insured).toLocaleString("en-US")}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                            width: "50%",
                          }}
                          variant="h3"
                        >
                          $ {Number(sum_insured).toLocaleString("en-US")}
                        </Typography>
                      )}
                    </Stack>
                    <Stack sx={{ pr: 3 }} direction="row">
                      <Typography
                        sx={{
                          fontSize: "11px",
                          color: "rgba(0, 0, 0, 0.7)",
                          marginBottom: "5px",
                          width: "53%",
                        }}
                        variant="body1"
                      >
                        Trip Cancellation
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          width: "47%",
                          mt: -0.3,
                        }}
                        variant="h3"
                      >
                        {trip_cancellation_value}
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        pl: 8,
                        pr: 4,
                      }}
                    >
                      <KnowMoreB
                        onClick={() => {
                          dispatch(setKnowMoreData(quoteData));
                          navigate({
                            pathname: "/know-more",
                            search: location.search,
                          });
                        }}
                      />
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item md={4.5}>
                  <Grid alignItems="center" justifyContent="center" container>
                    <Grid sx={{ mt: 1, pl: 1 }} item xs={11}>
                      <QuoteButton
                        onClick={() => {
                          onBuyNow();
                          dispatch(setKnowMoreData(quoteData));
                        }}
                      >
                        {isStoreSelectedPlanLoading ? (
                          <ButtonLoader />
                        ) : (
                          <>
                            <span
                              style={{
                                fontFamily: "Inter",
                                fontWeight: "500",
                                fontSize: "18px",
                              }}
                            >
                              ₹{" "}
                            </span>
                            {data[0].total_premium_amount}
                          </>
                        )}
                      </QuoteButton>
                    </Grid>
                    <Grid sx={{ pl: 0 }} item xs={12}>
                      <Stack
                        sx={{ mt: -1.7 }}
                        alignItems="center"
                        direction="row"
                      >
                        {shareSelectedQuotes ? (
                          <>
                            <Checkbox
                              onClick={(event) => handleShare(event)}
                              checked={isSelectedToShare}
                              sx={{ color: "var(--primary-color)", ml: 3 }}
                            />
                            <Typography
                              fontFamily={"Poppins"}
                              fontSize="11px"
                              variant="h6"
                              sx={{ color: "var(--primary-color)" }}
                            >
                              Share
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Checkbox
                              onClick={(event) => handleCompare(event)}
                              checked={isSelectedToCompare}
                              sx={{ color: "var(--primary-color)", ml: -0.5 }}
                            />
                            <Typography
                              fontFamily={"Poppins"}
                              fontSize="11px"
                              variant="h6"
                              sx={{ color: "var(--primary-color)" }}
                            >
                              Add To Compare
                            </Typography>
                          </>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {show_more_quotes[ic_data[0].id] && quoteLength - 1 !== index && (
            <Divider flexItem sx={{ ml: 1, mr: 1, mt: 1 }} />
          )}
        </Box>
        {showMorePlans && !isLoading && (
          <Card
            sx={{
              borderRadius: "20px 20px 0px 0px",
              boxShadow: "none",
              backgroundColor: "var(--primary-light)",
              position: "absolute",
              bottom: "0px",
              cursor: "pointer",
              left: "50%",
              fontSize: "14px",
              pt: "4px",
              pb: "4px",
              textAlign: "center",
              width: "102px",
              transform: "translateX(-50%)",
              border: "0.5px solid var(--primary-light)",
            }}
            onClick={() => {
              if (show_more_quotes[ic_data[0].id]) {
                dispatch(hideMoreQuotes(ic_data[0].id));
                return;
              }
              dispatch(showMoreQuotes(ic_data[0].id));
            }}
          >
            <Typography
              sx={{
                fontSize: "10px",
                fontWeight: "500",
                color: "var(--primary-color)",
                cursor: "pointer",
              }}
            >
              {show_more_quotes[ic_data[0].id]
                ? "Hide plans"
                : `+${quoteLength - 1} more plans`}
            </Typography>
          </Card>
        )}
      </Collapse>
    </>
  );
}
