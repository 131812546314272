/* eslint-disable react-hooks/exhaustive-deps */
import { Download, KeyboardBackspace } from "@mui/icons-material";
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useUrlQuery from "../../hooks/useUrlQuery";
import { setShowDifference } from "../../modules/quote.slice";
import {
  useAddComparison,
  useGetComparePdf,
} from "../../services/quoteService";
import ShareCompareQuoteModal from "../QuotesPage/components/ShareQuotesModal/ShareCompareQuoteModal";
import AddPlanToCompare from "./components/AddPlanToCompare";
import FeatureCard from "./components/FeatureCard";
import FeatureRow from "./components/FeatureRow";
import ProductCard from "./components/ProductCard";
import SendCompareB from "./components/SendCompareB";

const MobileCompare = () => {
  const { compareQuote } = useSelector((state) => state.quote);
  const dispatch = useDispatch();
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [showOnlySelected, setShowOnlySelected] = useState(false);
  const { comparisonPlan } = useAddComparison();
  const { comparePdf, pdf, isComparePdfLoading } = useGetComparePdf();
  const navigate = useNavigate();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const compareData = compareQuote.map((item) => ({
    company_alias: item.ic_data[0]?.name,
    product_id: item.data[0]?.plan_id,
    ic_id: item.ic_data[0]?.id,
    plan_code: String(item.data[0]?.plan_code),
    ic_mapping_id: item.data[0]?.plan_broker_ic_mapping_id,
    plan_name: item.data[0]?.plan_name,
    total_premium: item.data[0]?.total_premium_amount,
  }));
  const addToSelectedFeature = (feature) => {
    if (selectedFeature.includes(feature)) {
      setSelectedFeature(selectedFeature.filter((f) => f !== feature));
    } else {
      setSelectedFeature([...selectedFeature, feature]);
    }
  };
  useEffect(() => {
    if (!selectedFeature.length) setShowOnlySelected(false);
  }, [selectedFeature]);
  const updateCompare = useCallback(() => {
    if (compareQuote.length) {
      comparisonPlan({
        enquiry_id,
        products: compareData,
      });
      comparePdf();
    }
  }, [compareQuote.length]);
  useEffect(() => {
    updateCompare();
  }, [updateCompare]);
  return (
    <>
      <Container
        id="compare-page"
        sx={{
          position: "relative",
          pb: 10,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={isTablet ? 0 : 2}>
            <Box>
              <IconButton
                onClick={() => {
                  navigate({
                    pathname: "/quote",
                    search: window.location.search,
                  });
                }}
                size="small"
                sx={{
                  backgroundColor: "#eaeef2",
                }}
              >
                <KeyboardBackspace fontSize="15px" />
              </IconButton>
            </Box>
          </Grid>
          <Grid sx={{ mt: 0.5 }} item xs={10}>
            <>
              <Typography fontWeight={"600"} fontSize="18px" height="40px">
                Product Comparison
              </Typography>
            </>
          </Grid>
          <Grid sx={{ mt: -2 }} item xs={12}>
            <FormControlLabel
              className="hide-on-download"
              onChange={(_, checked) => {
                dispatch(setShowDifference(checked));
              }}
              control={<Checkbox />}
              label="Show Difference"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "16px",
                  fontWeight: "500",
                },
              }}
            />
          </Grid>
          {[0, 1].map((idx) => (
            <Grid item xs={6}>
              <ProductCard product={compareQuote[idx]} />
            </Grid>
          ))}
        </Grid>
        {compareQuote[0] ? (
          compareQuote[0].data[0].plan_feature.map((item, feature_idx) => (
            <>
              <FeatureCard title={item.feature_key}>
                {item.feature_values?.map((values, cover_idx) => (
                  <FeatureRow
                    onAddToSelected={() =>
                      addToSelectedFeature(
                        `${item.feature_key}-${values.cover}`
                      )
                    }
                    key={cover_idx}
                    parent={item.feature_key}
                    feature_text={values.cover}
                    description={values.description}
                    dataValues={compareQuote.map((item) => {
                      return item.data[0]?.plan_feature[
                        feature_idx
                      ]?.feature_values?.find(
                        (item) => item.cover === values.cover
                      )?.sum_insured;
                    })}
                    referenceValue={values?.sum_insured}
                    showOnlySelected={showOnlySelected}
                    setShowOnlySelected={setShowOnlySelected}
                  />
                ))}
              </FeatureCard>
            </>
          ))
        ) : (
          <>
            <Box textAlign={"center"} mt={"120px"}>
              <CircularProgress />
            </Box>
          </>
        )}
        <AddPlanToCompare />
      </Container>
      <ShareCompareQuoteModal />
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Card>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid py={1} px={2} item xs={3}>
              <IconButton
                disabled={isComparePdfLoading}
                target={"_blank"}
                download="Compare PDf"
                onClick={() => comparePdf()}
                href={pdf?.data}
                sx={{ p: 0, mr: 2 }}
                color={"primary"}
              >
                {isComparePdfLoading ? (
                  <CircularProgress sx={{ height: "30px" }} />
                ) : (
                  <Stack spacing={1} alignItems={"center"}>
                    <Download sx={{ fontSize: isTablet ? "2rem" : "" }} />
                    <Typography
                      color={"primary"}
                      sx={{ fontSize: isTablet ? "18px" : "10px" }}
                    >
                      Download
                    </Typography>
                    {/* </> */}
                  </Stack>
                )}
              </IconButton>
            </Grid>
            <Grid item xs={isTablet ? 2 : 3}>
              <SendCompareB />
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default MobileCompare;
