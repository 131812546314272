import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormGroup,
  InputAdornment,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SmsIcon from "@mui/icons-material/Sms";
import { useState } from "react";
import {
  useGetUserDetails,
  useShareMutation,
} from "../../../../services/quoteService";
import { useDispatch, useSelector } from "react-redux";
import { setShowCompareModal } from "../../../../modules/quote.slice";
import html2canvas from "html2canvas";
const InputField = ({ onChange, onClick, value, name, Icon, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <FormGroup row>
        <TextField
          sx={{
            flexGrow: 1,
            "& fieldset": {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
            width: "60%",
            height: isMobile ? "30px" : "none",
          }}
          onChange={onChange}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment
                  position="start"
                  sx={{
                    color: "primary.main",
                  }}
                >
                  <Icon />
                </InputAdornment>
              </>
            ),
          }}
          name={name}
          variant="outlined"
          {...props}
        />
        <Button
          sx={{
            borderRadius: "5px",
            px: 4,
            ml: 2,
            boxShadow: "none",
            width: "30%",
            textTransform: "none",
            height: isMobile ? "35px" : "none",
          }}
          color="secondary"
          onClick={onClick}
        >
          Share
        </Button>
      </FormGroup>
    </>
  );
};
const printImageById = async (id) => {
  const input = document.getElementById(id);

  const canvas = await html2canvas(input, {
    useCORS: true,
    allowTaint: false,
    scrollX: 0,
    scrollY: -window.scrollY,
  });
  const imgData = canvas.toDataURL("image/jpeg");
  return imgData;

  //.split(",")[1]
};
const ShareCompareQuoteModal = () => {
  const { userDetails } = useGetUserDetails();
  const [formData, setFormData] = useState({
    email: userDetails?.proposer_email,
    sms: "",
    whatsapp: "",
  });

  const { showCompareModal } = useSelector((state) => state.quote);
  const onFieldChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const { share } = useShareMutation();
  const dispatch = useDispatch();

  const sendCompareByEmail = async () => {
    await printImageById("compare-page");
    share({
      purpose: "compare",
      via: "email",
      email: formData.email,
    });
  };
  return (
    <>
      <Dialog
        open={showCompareModal}
        onClose={() => {
          dispatch(setShowCompareModal(false));
        }}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle>Share Quotes</DialogTitle>
        <Divider />
        <DialogContent>
          <Stack py={2} spacing={2}>
            <InputField
              onChange={onFieldChange}
              placeholder="test@test.com"
              name="email"
              type="email"
              Icon={EmailIcon}
              onClick={() => {
                sendCompareByEmail();
              }}
            />
            <InputField
              onChange={onFieldChange}
              placeholder="9*********0"
              name="sms"
              type="tel"
              inputProps={{ maxLength: "10" }}
              Icon={SmsIcon}
            />
            <InputField
              onChange={onFieldChange}
              placeholder="9*********0"
              name="whatsapp"
              type="tel"
              Icon={WhatsAppIcon}
              inputProps={{ maxLength: "10" }}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShareCompareQuoteModal;
