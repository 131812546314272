/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormGroup,
  InputAdornment,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SmsIcon from "@mui/icons-material/Sms";
import { useState } from "react";
import {
  useGetUserDetails,
  useShareMutation,
} from "../../../../services/quoteService";
import { useDispatch, useSelector } from "react-redux";
import { closeShareQuotesModal } from "../../../../modules/quote.slice";
import html2canvas from "html2canvas";
import { useEffect } from "react";
const InputField = ({
  onChange,
  onClick,

  name,
  value,
  Icon,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <FormGroup row>
        <TextField
          sx={{
            maxWidth: isMobile ? "180px" : "none",
            flexGrow: 1,
            "& fieldset": {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
            width: "65%",
            height: isMobile ? "30px" : "none",
          }}
          value={value}
          onChange={onChange}
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment
                  position="start"
                  sx={{
                    color: "primary.main",
                  }}
                >
                  <Icon />
                </InputAdornment>
              </>
            ),
          }}
          name={name}
          variant="outlined"
          {...props}
        />
        <Button
          sx={{
            // borderTopLeftRadius: 0,
            // borderBottomLeftRadius: 0,
            maxWidth: isMobile ? "50px" : "none",
            borderRadius: "5px",
            px: 4,
            ml: 2,
            boxShadow: "none",
            fontSize: isMobile ? "12px" : "none",
            width: "32%",
            height: isMobile ? "35px" : "none",
          }}
          color="secondary"
          onClick={onClick}
        >
          Share
        </Button>
      </FormGroup>
    </>
  );
};
const printImageById = async (id) => {
  const input = document.getElementById(id);

  const canvas = await html2canvas(input, {
    useCORS: true,
    allowTaint: false,
    scrollX: 0,
    scrollY: -window.scrollY,
  });
  const imgData = canvas.toDataURL("image/jpeg");
  return imgData;

  //.split(",")[1]
};
const ShareQuotesModal = () => {
  const { userDetails } = useGetUserDetails();
  const [formData, setFormData] = useState({
    email: userDetails?.proposer_email,
    sms: "",
    whatsapp: "",
  });

  const { isShareQuotesModalOpen, quotesToShare } = useSelector(
    (state) => state.quote
  );
  const onFieldChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const dispatch = useDispatch();
  const { share } = useShareMutation();
  const [updateOnce, setUpdateOnce] = useState(false);
  useEffect(() => {
    if (userDetails.proposer_email && !updateOnce) {
      setFormData({
        email: userDetails?.proposer_email,
        sms: userDetails.proposer_mobile_no,
        whatsapp: userDetails.proposer_mobile_no,
      });
      setUpdateOnce(true);
    }
  }, [userDetails]);
  const sendQuotesByWhatsapp = async () => {
    const url = `https://wa.me/${formData.whatsapp}?text=${window.location.href}`;
    window.open(url, "_blank");
  };
  const sendQuotesByEmail = async () => {
    await printImageById("share-quotes");

    share({
      purpose: "quote",
      via: "email",
      email: formData.email,
      products: quotesToShare.map((item) => ({
        company_alias: item.ic_data[0].company_alias,
        product_id: item.data[0].plan_id,
        ic_id: item.ic_data[0].id,
        ped_covered: item.data[0].ped_covered || 0,
        loss_of_passport:
          item?.data[0]?.plan_feature[0]?.feature_values.find(
            (item) => item.cover === "Loss of Passport"
          )?.sum_insured || 0,
        trip_cancellation:
          item?.data[0]?.plan_feature[0]?.feature_values.find(
            (item) => item.cover === "Trip Cancellation"
          )?.sum_insured || 0,
        baggage_loss:
          item?.data[0]?.plan_feature[0]?.feature_values.find(
            (item) => item.cover === "Loss of checked-in baggage"
          )?.sum_insured || 0,
        sum_insured: item.data[0].sum_insured,
        total_premium: item.data[0].total_premium_amount,
        plan_name: item.data[0]?.plan_name,
      })),
    });
  };
  return (
    <>
      <Dialog
        open={isShareQuotesModalOpen}
        onClose={() => {
          dispatch(closeShareQuotesModal());
        }}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle>Share Quotes</DialogTitle>
        <Divider />
        <DialogContent>
          <Stack py={2} spacing={2}>
            <InputField
              onChange={onFieldChange}
              placeholder="test@test.com"
              value={formData.email}
              name="email"
              type="email"
              Icon={EmailIcon}
              onClick={() => {
                sendQuotesByEmail();
              }}
            />
            <InputField
              onChange={onFieldChange}
              placeholder="9*********0"
              value={formData.sms}
              name="sms"
              type="tel"
              inputProps={{ maxLength: "10" }}
              Icon={SmsIcon}
            />
            <InputField
              onChange={onFieldChange}
              placeholder="9*********0"
              name="whatsapp"
              type="tel"
              value={formData.whatsapp}
              Icon={WhatsAppIcon}
              inputProps={{ maxLength: "10" }}
              onClick={() => {
                sendQuotesByWhatsapp();
              }}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShareQuotesModal;
