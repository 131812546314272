import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  useMediaQuery,
  Skeleton,
  useTheme,
} from "@mui/material";
import React from "react";

export default function QuoteLoader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  if (isMobile) {
    return (
      <Paper
        sx={{
          mb: "16px !important",
          padding: 1,
          paddingLeft: 2,
          pr: 0,
          boxShadow: "none",
          position: "relative",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={3.5}>
            <Stack alignItems="center" spacing={1}>
              <Skeleton
                variant="rectangular"
                alt="skeleton"
                style={{
                  width: "55px",
                  height: "55px",
                }}
              />
              <Skeleton variant="text" width="60px" />
              <Skeleton variant="text" width="60px" />
            </Stack>
          </Grid>
          <Grid item xs={4.5}>
            <Stack sx={{ mb: 0.5 }} justifyContent="center" alignItems="center">
              <Skeleton variant="text" width="60px" />
            </Stack>
            <Stack sx={{ mb: 0.5 }} justifyContent="center" alignItems="center">
              <Skeleton variant="text" width="60px" />
            </Stack>
            <Stack sx={{ mb: 0.5 }} justifyContent="center" alignItems="center">
              <Skeleton variant="text" width="60px" />
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ mt: 3, p: 1 }}>
              <Skeleton variant="rectangular" width="80px" height="40px" />
            </Box>
            <Stack
              sx={{ mt: -4, ml: -1.5 }}
              justifyContent="center"
              alignItems="center"
              direction="row"
            >
              <Skeleton variant="text" width="60px" />
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    );
  }
  return (
    <>
      <Paper
        sx={{
          maxWidth: "956px",
          maxHeight: "133px",
          mb: "20px !important",
          padding: 1,
          paddingLeft: 1,

          pr: 0,
          boxShadow: "none",
          position: "relative",
        }}
      >
        <Grid sx={{ p: 0 }} container spacing={2}>
          <Grid sx={{ pt: 0 }} item xs={12} md={6.5}>
            <Grid container spacing={2}>
              <Grid item md={4} xs={2}>
                <Stack alignItems="center" mt={2} spacing={1}>
                  <Skeleton
                    variant="rectangular"
                    width={"70px"}
                    height={"40px"}
                  />

                  <Skeleton variant="text" width="60px" />
                </Stack>
              </Grid>

              <Divider sx={{ mt: 2 }} orientation="vertical" flexItem />
              <Grid item md={7.5} xs={9}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Stack sx={{ pl: 2, pr: 6, mt: 1 }} spacing={1}>
                      <Stack justifyContent="space-between" direction="row">
                        <Skeleton varaint="text" width="60px" />
                        <Skeleton varaint="text" width="60px" />
                      </Stack>
                      <Stack justifyContent="space-between" direction="row">
                        <Skeleton varaint="text" width="60px" />
                        <Skeleton varaint="text" width="60px" />
                      </Stack>
                      <Stack justifyContent="space-between" direction="row">
                        <Skeleton varaint="text" width="60px" />
                        <Skeleton varaint="text" width="60px" />
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider
            sx={{
              mt: 2,
              width: 0,
            }}
            orientation={"vertical"}
            flexItem
          />

          <Grid item xs={12} md={5.1}>
            <Grid
              spacing={1}
              justifyContent="center"
              alignItems="center"
              container
              display="flex"
              xs={12}
            >
              <Grid item md={7.5}>
                <Stack spacing={1}>
                  <Stack
                    justifyContent="space-between"
                    direction={"row"}
                    spacing={2}
                    sx={{ pr: 3 }}
                    mt={1}
                  >
                    <Skeleton varaint="text" width="60px" />
                    <Skeleton varaint="text" width="60px" />
                  </Stack>
                  <Stack
                    sx={{ pr: 3, mb: 1 }}
                    justifyContent="space-between"
                    direction="row"
                  >
                    <Skeleton varaint="text" width="60px" />
                    <Skeleton varaint="text" width="60px" />
                  </Stack>
                  <Stack sx={{ ml: 4 }} justifyContent="center">
                    <Skeleton varaint="text" width="60px" />
                  </Stack>
                </Stack>
              </Grid>

              <Grid item md={4.5}>
                <Grid alignItems="center" justifyContent="center" container>
                  <Grid sx={{ mt: 1, pl: 1 }} item xs={11}>
                    <Skeleton
                      varaint="rectangular"
                      width="80px"
                      height="50px"
                      sx={{
                        mb: 1.5,
                      }}
                    />
                  </Grid>
                  <Grid sx={{ pl: 1, ml: 1 }} item xs={12}>
                    <Stack
                      sx={{ mt: -1.5 }}
                      alignItems="center"
                      direction="row"
                    >
                      <>
                        <Skeleton varaint="text" width="60px" />
                      </>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
